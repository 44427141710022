import { Link as LinkIcon } from '@mui/icons-material';
import { Tooltip, TooltipProps } from '@mui/material';
import React from 'react';
import NavLinkWithQuery from './NavLinkWithQuery';

/**
 * Wrap a component with a clickable navigation link and a tooltip to indicate
 * its behaviour.
 *
 * @param {NavigationWrapperProps} props
 */
function NavigationWrapper(props: {
  children: React.ReactNode;
  location: string;
  tooltip?: boolean | string;
  tooltipPlacement?: TooltipProps['placement'];
}) {
  const { children, location, tooltip = true, tooltipPlacement } = props;

  let tooltipMessage: string;
  if (typeof tooltip === 'string') {
    tooltipMessage = tooltip;
  } else if (tooltip === true) {
    tooltipMessage = location
      .split(/[-_]/g)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  } else {
    tooltipMessage = '';
  }
  return (
    <Tooltip
      title={
        tooltipMessage !== '' ? (
          <>
            <LinkIcon /> {tooltipMessage}
          </>
        ) : (
          ''
        )
      }
      followCursor={tooltipPlacement === undefined}
      placement={tooltipPlacement}
      arrow={tooltipPlacement !== undefined}
    >
      <NavLinkWithQuery style={{ textDecoration: 'none' }} to={location}>
        {children}
      </NavLinkWithQuery>
    </Tooltip>
  );
}

export default NavigationWrapper;
