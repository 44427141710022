/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { Query, ResultSet } from '@cubejs-client/core';
import { Box } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import * as d3 from 'd3';
import {
  add,
  differenceInBusinessDays,
  endOfMonth,
  formatISO,
  isEqual,
  parseISO,
  startOfMonth,
  sub,
} from 'date-fns';
import { Point } from 'highcharts';
import React from 'react';
import {
  ColumnLabelMap,
  ComposedChart,
  DataTable,
  DATA_TABLE_KEY,
  LoadDataErrorCard,
  QueriesRenderer,
  RouteGridContainer,
} from '../../components';
import {
  useArMonthRange,
  useCubeFilter,
  useCubeQuery,
  useFacilityFilterOptionsQueryOpts,
  useGlobalFilters,
} from '../../hooks';
import { ARByAge } from '../../reports';
import variables from '../../styles/variables.module.scss';
import { sortPointByX } from '../../utils';
import { useARReportOutletContext } from './hooks';

const MONTHS_BACK = 6;

const tableColumnMap = {
  name: { label: 'Office', phi: true, defaultSortOrder: 'asc' },
  collectionDays: { label: 'Collection Days', showTotal: false },
  ninetyDay: { label: '90 Day Avg Collection', unit: '$' },
  thirtyDay: { label: '30 Day Avg Collection', unit: '$' },
} as const satisfies ColumnLabelMap;

function CollectionDaysDetails() {
  const { baseQuery } = useARReportOutletContext();
  const { data: facilities } = useQuery(useFacilityFilterOptionsQueryOpts());
  const { start, end } = useArMonthRange();

  const { facilities: filteredFacilities } = useGlobalFilters();

  const snapshotFilters = useCubeFilter('ClaimARSnapshot');
  const collectedRollingFilters = useCubeFilter('UserClaim', {
    useFiltersInsteadOfSegments: true,
  });

  const currentARL6MQuery: Query = {
    ...snapshotFilters,
    measures: ['ClaimARSnapshot.currentAmountExpectedSum'],
    timeDimensions: [
      {
        dimension: 'ClaimARSnapshot.date',
        dateRange: [
          formatISO(startOfMonth(sub(start, { months: 6 })), {
            representation: 'date',
          }),
          formatISO(endOfMonth(end), { representation: 'date' }),
        ],
        granularity: 'month',
      },
    ],
  };

  const collectedRollingL6MQuery: Query = {
    ...collectedRollingFilters,
    measures: [
      'UserClaim.ninetyDayCollectionSum',
      'UserClaim.thirtyDayCollectionSum',
    ],
    timeDimensions: [
      {
        dimension: 'UserClaim.adjustedPostedDate',
        compareDateRange: new Array(MONTHS_BACK + 1).fill(0).map((_, index) => {
          if (index === 0) {
            return formatISO(end, { representation: 'date' });
          }
          return formatISO(endOfMonth(sub(end, { months: index })), {
            representation: 'date',
          });
        }),
        granularity: 'day',
      },
    ],
  };

  const currentARByFacilityQuery: Query = {
    ...baseQuery,
    measures: ['ClaimARSnapshot.currentAmountExpectedSum'],
    dimensions: ['ClaimARSnapshot.facilityID'],
  };

  const collectedByFacilityQuery: Query = {
    ...collectedRollingFilters,
    measures: [
      'UserClaim.ninetyDayCollectionSum',
      'UserClaim.thirtyDayCollectionSum',
    ],
    dimensions: ['UserClaim.facilityID'],
    timeDimensions: [
      {
        dimension: 'UserClaim.adjustedPostedDate',
        dateRange: formatISO(end, { representation: 'date' }),
        granularity: 'day',
      },
    ],
  };

  const { resultSet: currentARL6MResult, error: currentARL6MError } =
    useCubeQuery(currentARL6MQuery);

  const {
    resultSet: collectedRollingL6MResult,
    error: collectedRollingL6MError,
  } = useCubeQuery(collectedRollingL6MQuery);

  const [
    collectionDaysL6MData,
    collectionPer90L6MData,
    collectionPer30L6MData,
  ] = React.useMemo(() => {
    const collectionDaysPerMonth: Partial<Point>[] = [];
    const collectionPer90Days: Partial<Point>[] = [];
    const collectionPer30Days: Partial<Point>[] = [];
    if (currentARL6MResult && collectedRollingL6MResult) {
      const arResults = currentARL6MResult.chartPivot();
      collectedRollingL6MResult.decompose().forEach((monthResult) => {
        const row = monthResult.rawData()[0];
        if (row === undefined) {
          return;
        }

        const date = startOfMonth(
          parseISO(row.compareDateRange.split(' - ')[0]),
        );

        const arResult = arResults.find((result) =>
          isEqual(date, parseISO(result.x)),
        );

        const collected90Days = row[collectedRollingL6MQuery.measures![0]];
        const workDays90 = differenceInBusinessDays(
          add(date, { days: 1 }),
          sub(date, { days: 89 }),
        );
        const collectedPerDay90 = collected90Days / workDays90;

        const collected30Days = row[collectedRollingL6MQuery.measures![1]];
        const workDays30 = differenceInBusinessDays(
          add(date, { days: 1 }),
          sub(date, { days: 29 }),
        );
        const collectedPerDay30 = collected30Days / workDays30;

        const collectionDays =
          (arResult?.['ClaimARSnapshot.currentAmountExpectedSum'] ?? 0) /
          collectedPerDay90;
        const beginningOfMonth = startOfMonth(date);
        collectionDaysPerMonth.push({
          x: beginningOfMonth.getTime(),
          y: collectionDays,
        });
        collectionPer90Days.push({
          x: beginningOfMonth.getTime(),
          y: collectedPerDay90,
        });
        collectionPer30Days.push({
          x: beginningOfMonth.getTime(),
          y: collectedPerDay30,
        });
      });
    }

    return [
      collectionDaysPerMonth.sort(sortPointByX),
      collectionPer90Days.sort(sortPointByX),
      collectionPer30Days.sort(sortPointByX),
    ];
  }, [
    collectedRollingL6MQuery.measures,
    collectedRollingL6MResult,
    currentARL6MResult,
  ]);

  const transformDataByFacilitiesResult = (
    resultSets: [ResultSet, ResultSet] | null,
  ) => {
    if (resultSets === null) return [];
    const [collectedPerFacilityResult, currentARResult] = resultSets;

    const displayFacilities = filteredFacilities.length
      ? filteredFacilities.map(({ facility }) => facility)
      : facilities ?? [];

    const currentARResultPivotByFacility = d3.index(
      currentARResult?.chartPivot({
        x: [currentARByFacilityQuery.dimensions![0]],
        y: ['measures'],
      }) ?? [],
      (row) => row.xValues[0],
    );
    const collectedPerFacilityResultPivotByFacility = d3.index(
      collectedPerFacilityResult?.chartPivot({
        x: [collectedByFacilityQuery.dimensions![0]],
        y: ['measures'],
      }) ?? [],
      (row) => row.xValues[0],
    );

    return displayFacilities.map((facility) => {
      const currentAR =
        currentARResultPivotByFacility.get(facility.id.toString())?.[
          currentARByFacilityQuery.measures![0]
        ] ?? 0;

      const ninetyDayCollection =
        collectedPerFacilityResultPivotByFacility.get(facility.id.toString())?.[
          collectedByFacilityQuery.measures![0]
        ] ?? 0;
      const workDaysLast90 = differenceInBusinessDays(
        add(end, { days: 1 }),
        sub(end, { days: 89 }),
      );
      const collectedPerDay90 = ninetyDayCollection / workDaysLast90;
      const collectionDays = currentAR / collectedPerDay90;

      const thirtyDayCollection =
        collectedPerFacilityResultPivotByFacility.get(facility.id.toString())?.[
          collectedByFacilityQuery.measures![1]
        ] ?? 0;
      const workDaysLast30 = differenceInBusinessDays(
        add(end, { days: 1 }),
        sub(end, { days: 29 }),
      );
      const collectedPerDay30 = thirtyDayCollection / workDaysLast30;

      return {
        [DATA_TABLE_KEY]: String(facility.id),
        name: facility.name,
        collectionDays,
        ninetyDay: collectedPerDay90,
        thirtyDay: collectedPerDay30,
      };
    });
  };

  return (
    <RouteGridContainer
      data-testid='collection-days-details'
      gridTemplateColumns='1fr 1fr'
    >
      {!currentARL6MError && !collectedRollingL6MError ? (
        <ComposedChart
          bars={[
            {
              data: collectionDaysL6MData,
              displayUnit: 'day',
              name: 'Collection Days',
              type: 'column',
            },
          ]}
          lines={[
            {
              data: collectionPer90L6MData,
              displayUnit: '$',
              name: '90 Day Collection Speed',
              color: variables.palettePercentDark,
              type: 'spline',
              yAxis: '1',
            },
            {
              data: collectionPer30L6MData,
              displayUnit: '$',
              name: '30 Day Collection Speed',
              color: variables.palettePercentLight,
              type: 'spline',
              yAxis: '1',
            },
          ]}
          timeGranularity='month'
          title='Claim AR Collection Days and Collection Speed'
          xAxis={{ type: 'datetime' }}
        />
      ) : (
        <LoadDataErrorCard />
      )}
      <Box gridRow='span 2'>
        <QueriesRenderer
          queries={
            [collectedByFacilityQuery, currentARByFacilityQuery] as const
          }
          transformResult={transformDataByFacilitiesResult}
          render={(data) => (
            <DataTable
              bordered
              columnLabelMap={tableColumnMap}
              data={data}
              defaultSortColumn='collectionDays'
            />
          )}
          fallback={<LoadDataErrorCard />}
        />
      </Box>
      <ARByAge detailed query={baseQuery} />
    </RouteGridContainer>
  );
}

export default CollectionDaysDetails;
