import {
  DeeplyReadonly,
  Query,
  QueryRecordType,
  ResultSet,
} from '@cubejs-client/core';
import { ReactNode } from 'react';
import { useLoader } from '../hooks';

interface QueryRendererProps<
  TQuery extends DeeplyReadonly<Query | Query[]>,
  TData,
> {
  query: TQuery;
  /** Directly receives the result of transformResult to render the data */
  render: (data: TData) => JSX.Element;
  /** Transforms the result set into a usable form to be rendered */
  transformResult: (result: ResultSet<QueryRecordType<TQuery>> | null) => TData;
  /** Component to render if query has an error */
  fallback: ReactNode;
  queryKey?: string[];
}
/**
 * Render the result of a CubeJS query
 *
 * @param {QueryRendererProps} props
 * @returns
 */
function QueryRenderer<
  TQuery extends DeeplyReadonly<Query | Query[]> = DeeplyReadonly<
    Query | Query[]
  >,
  TData = unknown,
>(props: QueryRendererProps<TQuery, TData>) {
  const { query, render, transformResult, fallback, queryKey } = props;

  const { data, error } = useLoader({
    loader: async ({ fetchCubeQuery }) => {
      const resultSet = await fetchCubeQuery(query);
      return transformResult(resultSet);
    },
    default: () => transformResult(null),
    loaderKey: query,
    loaderSoftKey: queryKey,
  });

  if (error) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{fallback}</>;
  }

  return render(data);
}

export default QueryRenderer;
