import variables from '@insidedesk/tuxedo/dist/styles/variables.module.scss';
import { Card, styled } from '@mui/material';

const DataCard = styled(Card, {
  shouldForwardProp: (propName) => propName !== 'color',
})(({ theme }) => ({
  border: variables.borderLight,
  boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.05)',
  transition: theme.transitions.create('all'),
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '&:hover': {
    boxShadow: `0px 0px 16px 0px rgba(38, 50, 56, 0.25)`,
    border: '1px solid #ffffff00',
  },
}));
export default DataCard;
