import { Query } from '@cubejs-client/core';
import { useUserProfile } from '@insidedesk/tuxedo';
import { endOfMonth, formatISO, startOfMonth } from 'date-fns';
import { useMemo } from 'react';
import { RouteGridContainer } from '../../components';
import { useArMonthRange } from '../../hooks';
import {
  ARAndRCMStatus,
  ARAndResponsibility,
  ARByAge,
  OfficeKPIRow,
  WorkflowTable,
} from '../../reports';
import variables from '../../styles/variables.module.scss';
import { useReportOutletContext } from '../hooks';

export default function OfficeReport() {
  const { baseQuery } = useReportOutletContext();
  const client = useUserProfile().client.name;
  const { start, end } = useArMonthRange();
  const [colorMap, labelMap] = useMemo(
    () => [
      {
        payers: variables.progressionDollar1,
        office: variables.progressionDollar2,
        client: variables.progressionDollar3,
      },
      {
        payers: 'Payers',
        office: 'Office',
        client: `${client}/Office`,
      },
    ],
    [client],
  );

  const query: Query = {
    ...baseQuery,
    measures: ['ClaimARSnapshot.currentAmountExpectedSum'],
    timeDimensions: [
      {
        dimension: 'ClaimARSnapshot.date',
        dateRange: [
          formatISO(startOfMonth(start), { representation: 'date' }),
          formatISO(endOfMonth(end), { representation: 'date' }),
        ],
        granularity: 'month',
      },
    ],
  };

  const rcmStatusQuery: Query = {
    ...query,
    dimensions: ['ClaimARSnapshot.rcmStatus'],
  };

  const responsibilityQuery: Query = {
    ...query,
    dimensions: ['WorkflowState.responsibility'],
  };

  return (
    <>
      <OfficeKPIRow />
      <RouteGridContainer
        gridTemplateColumns='repeat(3, 1fr)'
        height='min-content'
        overflow='unset'
      >
        <ARByAge query={query} />
        <ARAndRCMStatus rcmStatusQuery={rcmStatusQuery} />
        <ARAndResponsibility
          responsibilityQuery={responsibilityQuery}
          colorMap={colorMap}
          labelMap={labelMap}
        />
      </RouteGridContainer>
      <WorkflowTable responsibilityMap={labelMap} />
    </>
  );
}
