import { endOfMonth, startOfMonth, sub } from 'date-fns';

export default function makeArMonthOptions(): GlobalCubeFilterState['arMonth'][] {
  const now = new Date();
  return [
    {
      id: 'current',
      label: 'Current',
      range: { startDate: startOfMonth(now), endDate: now },
    },
    {
      id: 'previous',
      label: 'Previous',
      range: {
        startDate: startOfMonth(sub(now, { months: 1 })),
        endDate: endOfMonth(sub(now, { months: 1 })),
      },
    },
  ];
}
