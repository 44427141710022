import { propertyIn } from '@insidedesk/tuxedo';
import variables from '@insidedesk/tuxedo/dist/styles/variables.module.scss';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  alpha,
  styled,
  Table,
  TableBody,
  tableBodyClasses,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableFooter,
  tableFooterClasses,
  TableHead,
  tableHeadClasses,
  TableRow,
  TableSortLabel,
  tableSortLabelClasses,
} from '@mui/material';
import * as d3 from 'd3';
import { useMemo } from 'react';
import { DATA_TABLE_MIN_HEIGHT } from '../../constants';
import { useTrackLoading } from '../../hooks';
import CellValue from './CellValue';
import DataTableBodyRow from './DataTableRow';
import { useDeferredOrDefault, useTableSort } from './hooks';
import { DATA_TABLE_KEY, rowId } from './symbols';
import type {
  DataTableExpandRow,
  DataTableProps,
  DataTableRow,
  DataTableValue,
} from './types';
import { formatDescription, sortData } from './utils';

const EMPTY_DATA_TOTALS: [[], Record<string, never>] = [[], {}];

function hasExpandFilterValue<TColumn extends string>(
  row: DataTableRow<TColumn>,
): row is DataTableExpandRow<TColumn> {
  return propertyIn(row, rowId);
}
/* eslint-disable @typescript-eslint/naming-convention */
const StyledTableContainer = styled(TableContainer, {
  shouldForwardProp: (prop) => prop !== 'bordered',
})<{ bordered?: boolean }>(({ theme, bordered }) => ({
  minHeight: DATA_TABLE_MIN_HEIGHT,
  maxHeight: '100%',
  flex: `1 1 0`,
  [`& .${tableCellClasses.root}`]: {
    fontWeight: 500,
    lineHeight: 1.2,
    textTransform: 'capitalize',
  },
  [`& .${tableCellClasses.root}.DataTable-cell--expanded`]: {
    maxWidth: 0,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  [`& .${tableCellClasses.root}.DataTable-cell--expanded:first-of-type`]: {
    paddingLeft: theme.spacing(4),
  },
  [`& .${tableHeadClasses.root} .${tableCellClasses.root}`]: {
    fontWeight: 600,
    color: theme.palette.primary.main,
    background: 'white',
    paddingBottom: theme.spacing(1),
    borderBottom: `2px solid ${theme.palette.divider}`,
    verticalAlign: 'bottom',
  },
  [`& .${tableSortLabelClasses.root}`]: { color: theme.palette.grey[300] },
  [`& .${tableSortLabelClasses.active}`]: {
    color: `${theme.palette.primary.main} !important`,
  },
  [`& .${tableBodyClasses.root}`]: {
    '& tr:nth-of-type(even)': {
      background: alpha(theme.palette.primary.main, 0.05),
    },
    '& :first-of-type': { color: theme.palette.primary.main },
  },
  [`& .${tableFooterClasses.root}`]: {
    '& tr': { position: 'sticky', bottom: 0, background: 'white' },
  },
  [`& .${tableFooterClasses.root} .${tableCellClasses.root}`]: {
    fontSize: '1rem',
    fontWeight: 600,
    color: theme.palette.primary.main,
    borderTop: `2px solid ${theme.palette.divider}`,
  },
  [`& .DataTable-adornment`]: {
    float: 'right',
    fontSize: '14px',
    paddingLeft: theme.spacing(1),
  },
  [`& .DataTable-childRow :first-of-type`]: {
    paddingLeft: theme.spacing(4),
  },
  ...(bordered
    ? {
        border: variables.borderLight,
        borderRadius: `${theme.shape.borderRadius}px`,
      }
    : {}),
}));
/* eslint-enable @typescript-eslint/naming-convention */

/**
 * Renders an MUI Table with sorting and totals.
 *
 * The default active sort column is the first column in the columnLabelMap
 * unless a defaultSortColumn prop is provided. The default sort direction is
 * 'desc', which can be overridden on a per column basis by providing a
 * defaultSortOrder prop in the columnLabelMap[column] object.
 * I.e. { columnLabelMap: { facility: { label: 'Office', defaultSortOrder: 'asc' }}
 *
 * You can skip sorting on a column via the columnLabelMap[column].
 * I.e. { columnLabelMap: { facility: { label: 'Office', sortable: false }}
 *
 * The default text alignment for all cells is center, save for the body and
 * footer cells in the first column, which are left aligned. This can be
 * overridden on a per column basis for header, body, and footer cells by
 * providing an alignment object in the columnLabelMap[column].
 * I.e. { columnLabelMap: { facility: { label: 'Office', alignment: { header: 'left' }}}
 *
 * XXX: Totals will be skipped for the first entry in the columnLabelMap, as
 * the first column in the totals row is reserved for the 'Total' label.
 */
export default function DataTable<
  TColumn extends string,
  TDefaultSortColumn extends TColumn = never,
  RemapColumn extends TColumn = never,
>(props: DataTableProps<TColumn, TDefaultSortColumn, RemapColumn>) {
  const {
    columnLabelMap,
    data: eagerData,
    defaultSortColumn,
    getExpandedData,
    remapExpandedColumn,
    sx,
    totals: eagerTotals,
    ...rest
  } = props;
  const [data, totals] = useDeferredOrDefault(
    useMemo(() => [eagerData, eagerTotals], [eagerData, eagerTotals]),
    EMPTY_DATA_TOTALS,
  );
  useTrackLoading(eagerData !== data || eagerTotals !== totals);

  const { sortState, setActiveSort, changeSortDirection } = useTableSort(
    columnLabelMap,
    defaultSortColumn,
  );

  const sortedData = useMemo(
    () => sortData(data, sortState),
    [data, sortState],
  );

  const totalRow = useMemo(() => {
    const totalRow_: Record<string, DataTableValue> = {};
    Object.entries(columnLabelMap).forEach(([column, description], index) => {
      // XXX: First column in the totals row reserved for 'Total'
      if (index === 0) {
        totalRow_[column] = 'Totals';
        return;
      }

      const { unit, showTotal = true } = formatDescription(description);

      if (!showTotal) {
        totalRow_[column] = '';
        return;
      }

      const definedTotal = totals?.[column];

      let summedTotal: number | undefined;
      // Percentage totals should be calculated by the caller
      if (unit !== '%') {
        const summableData = data
          .map((row) => row[column])
          .map((value) => {
            if (typeof value === 'object' && value !== null) {
              return value.cellValue;
            }
            return value;
          })
          .map((value) => Number(value))
          .filter((value) => !Number.isNaN(value));
        if (summableData.length > 0) {
          summedTotal = d3.sum(summableData);
        }
      }

      totalRow_[column] = definedTotal ?? summedTotal ?? null;
    });

    return totalRow_;
  }, [columnLabelMap, data, totals]);

  return (
    <StyledTableContainer sx={sx} {...rest}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {Object.entries(columnLabelMap).map(([column, description]) => {
              const { active, direction } = sortState[column];

              const {
                alignment,
                label,
                sortable = true,
                minWidth,
              } = formatDescription(description);

              const align = alignment?.header ?? 'center';

              return (
                <TableCell
                  key={column}
                  sortDirection={sortable ? direction : undefined}
                  align={align}
                  sx={{ minWidth }}
                >
                  {label}
                  {/* Only show sorting when more than 1 row present */}
                  {sortable && data.length > 1 && (
                    <>
                      <br />
                      <TableSortLabel
                        active={active}
                        direction={direction}
                        IconComponent={
                          direction === 'asc'
                            ? () => <KeyboardArrowUpIcon />
                            : () => <KeyboardArrowDownIcon />
                        }
                        onClick={() =>
                          active
                            ? changeSortDirection(column)
                            : setActiveSort(column)
                        }
                      />
                    </>
                  )}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedData.map((row) => {
            if (getExpandedData && hasExpandFilterValue<TColumn>(row)) {
              return (
                <DataTableBodyRow
                  key={row[DATA_TABLE_KEY]}
                  columnLabelMap={columnLabelMap}
                  data={row}
                  getExpandedData={getExpandedData}
                  remapExpandedColumn={remapExpandedColumn}
                  sort={sortState}
                />
              );
            }
            return (
              <DataTableBodyRow
                key={row[DATA_TABLE_KEY]}
                columnLabelMap={columnLabelMap}
                data={row}
              />
            );
          })}
        </TableBody>
        {/* Only show totals when more than 1 row present */}
        {data.length > 1 && (
          <TableFooter>
            <TableRow>
              {Object.entries(columnLabelMap).map(
                ([column, description], index) => {
                  const { alignment } = formatDescription(description);
                  const align =
                    alignment?.footer ?? index === 0 ? 'left' : 'center';
                  const value = totalRow[column];
                  return (
                    <TableCell key={`total-${column}`} align={align}>
                      <CellValue value={value} description={description} />
                    </TableCell>
                  );
                },
              )}
            </TableRow>
          </TableFooter>
        )}
      </Table>
    </StyledTableContainer>
  );
}
