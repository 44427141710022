import { useAuth0 } from '@auth0/auth0-react';
import { Query, TimeDimension } from '@cubejs-client/core';
import {
  CenterInPage,
  ErrorBoundary,
  FlagLoadGuard,
  ListLayout,
  LoadingSpinner,
  MainNavbar,
  NavbarLayout,
  PanelCard,
  useUserProfile,
} from '@insidedesk/tuxedo';
import { Box, LinearProgress } from '@mui/material';
import { endOfMonth, formatISO, startOfMonth } from 'date-fns';
import { ReactNode, Suspense, useContext, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { Outlet, useLocation } from 'react-router-dom';
import invariant from 'tiny-invariant';
import {
  MainTitle,
  NavigateWithQuery,
  OfficeLogo,
  PermissionGuard,
  QuickFilters,
} from '../components';
import FlagOrInternal from '../components/FlagOrInternal';
import NavigationPanel from '../components/NavigationPanel';
import NewRelicErrorElement from '../components/NewRelicErrorElement';
import { NAVBAR_CONFIG } from '../constants';
import { LoadingContext } from '../context';
import { useArMonthRange, useCubeFilter, useMatches, useTitle } from '../hooks';
import { redirectWithQuery } from '../utils';
import ARReport, { childRoutes as arReportChildRoutes } from './ar';
import DenialReport, { childRoutes as denialReportChildRoutes } from './denial';
import OfficeReport, {
  OfficeFacilityDropdown,
  updateDefaultOfficeFacility,
  useDefaultOfficeFacility,
} from './office';
import ProductivityReport, {
  childRoutes as productivityChildRoutes,
} from './productivity';
import { RouteObject } from './types';

const childRoutes: RouteObject[] = [
  {
    path: 'ar',
    element: <ARReport />,
    children: arReportChildRoutes,
    handle: {
      activeCubeFilters: ['arMonth', 'facilities', 'ortho'],
      crumb: 'AR Insights',
    },
  },
  {
    path: 'office',
    element: <RedirectToDefaultFacility />,
  },
  {
    path: 'office/:officeFacilityId',
    loader: ({ params: { officeFacilityId } }) => {
      invariant(officeFacilityId !== undefined);
      updateDefaultOfficeFacility(officeFacilityId);
      return 200;
    },
    element: (
      <ClientGuard name='MB2' fallback={<NavigateWithQuery to='/' />}>
        <OfficeReport />
      </ClientGuard>
    ),
    handle: {
      activeCubeFilters: ['arMonth', 'ortho'],
      crumb: 'Office Insights',
      titleActions: <OfficeFacilityDropdown buttonProps={{ sx: { ml: 2 } }} />,
    },
  },
  {
    path: 'denial',
    children: denialReportChildRoutes,
    element: (
      <FlagLoadGuard fallback={<Loading />}>
        <FlagOrInternal
          flagName='iqDenialReports'
          fallback={<NavigateWithQuery to='/' />}
        >
          <DenialReport />
        </FlagOrInternal>
      </FlagLoadGuard>
    ),
    handle: {
      activeCubeFilters: [
        'dosMonths',
        'adjudicationReasonCategories',
        'arAges',
        'claimStatuses',
        'facilities',
      ],
      crumb: 'Denial Insights',
    },
  },
  {
    path: 'productivity',
    element: (
      <FlagLoadGuard fallback={<Loading />}>
        <FlagOrInternal
          flagName='iqProductivityReports'
          fallback={<NavigateWithQuery to='/' />}
        >
          <ProductivityReport />
        </FlagOrInternal>
      </FlagLoadGuard>
    ),
    children: productivityChildRoutes,
    handle: {
      activeCubeFilters: ['facilities', 'workedMonth', 'users'],
      crumb: 'Productivity Insights',
      skipBreadcrumbs: true,
    },
  },
  {
    path: '',
    loader: ({ request }) => {
      if (new URL(request.url).searchParams.get('code')) {
        // Pass through auth0 redirect
        return null;
      }
      return redirectWithQuery(request, '/ar');
    },
    element: null,
  },
  {
    // Backwards compatibility with old ar report url
    path: 'ar-report/*',
    loader: ({ request }) =>
      redirectWithQuery(
        request,
        new URL(request.url).pathname.replace(/^\/ar-report/, '/ar'),
      ),
  },
  {
    path: '*',
    // XXX throw during render so that this is caught by the error boundary
    // below the navbar (loader exceptions would be caught by this route's error
    // boundary instead)
    element: <Throw status={404} />,
  },
];

export const routes: RouteObject[] = [
  {
    path: '*',
    element: <App />,
    children: childRoutes,
    errorElement: (
      <Box display='flex' flexDirection='column' width='100%' height='100%'>
        <NewRelicErrorElement />
      </Box>
    ),
  },
];

export default function App() {
  const { logout } = useAuth0();
  const { start, end } = useArMonthRange();
  const { loading } = useContext(LoadingContext);
  const mainActions = useMatches().slice(-1)[0].handle?.mainActions;
  const title = useTitle();

  const printRef = useRef<HTMLDivElement | null>(null);
  const globalFilters = useCubeFilter('ClaimARSnapshot');

  const commonTimeDimensions: TimeDimension[] = [
    {
      dimension: 'ClaimARSnapshot.date',
      dateRange: [
        formatISO(startOfMonth(start), { representation: 'date' }),
        formatISO(endOfMonth(end), { representation: 'date' }),
      ],
      granularity: 'month',
    },
  ];
  const baseQuery: Query = {
    filters: globalFilters.filters,
    timeDimensions: commonTimeDimensions,
    segments: ['Facility.activeOnly'],
  };

  return (
    <NavbarLayout
      navbar={
        <MainNavbar
          active='iq'
          assist={NAVBAR_CONFIG.assistUrl}
          credentials={NAVBAR_CONFIG.credentialsUrl}
          users={NAVBAR_CONFIG.usersUrl}
          iq={NAVBAR_CONFIG.iqUrl}
          help={NAVBAR_CONFIG.helpUrl}
          logout={() => logout({ returnTo: window.location.origin })}
          showBlurToggle
        />
      }
    >
      <ErrorBoundary fallbackComponent={NewRelicErrorElement}>
        <PermissionGuard
          permission='read:reports'
          fallback={<Throw status={403} />}
        >
          <Helmet>
            <title>{title}</title>
          </Helmet>
          <ListLayout
            header={<Header />}
            sidebar={
              <PanelCard side='left' variant='outlined'>
                <NavigationPanel />
              </PanelCard>
            }
            actions={mainActions}
          >
            <PanelCard side='right' variant='outlined'>
              <Box sx={{ p: 2, pb: 1 }} className='flex-scroll-sibling'>
                <QuickFilters />
              </Box>
              <LinearProgress
                className='flex-scroll-sibling'
                sx={{ visibility: loading ? 'visible' : 'hidden' }}
              />
              <Suspense fallback={<Loading />}>
                <Box
                  ref={printRef}
                  className='print flex-scroll-parent'
                  sx={{ overflowY: 'auto', container: 'content / inline-size' }}
                >
                  <Outlet context={{ baseQuery }} />
                </Box>
              </Suspense>
            </PanelCard>
          </ListLayout>
        </PermissionGuard>
      </ErrorBoundary>
    </NavbarLayout>
  );
}

function Header() {
  const { pathname } = useLocation();
  return (
    <Box display='flex' justifyContent='space-between' pr={2}>
      <MainTitle />
      {pathname.startsWith('/office') && <OfficeLogo />}
    </Box>
  );
}

function Throw({ status }: { status: number }): null {
  throw new Response(null, { status });
}

function ClientGuard(props: {
  name: string;
  children: ReactNode;
  fallback: ReactNode;
}) {
  const { name, children, fallback } = props;
  const { client } = useUserProfile();
  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (client.name === name) return <>{children}</>;
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{fallback}</>;
}

function Loading() {
  return (
    <CenterInPage>
      <LoadingSpinner />
    </CenterInPage>
  );
}

function RedirectToDefaultFacility() {
  const facility = useDefaultOfficeFacility();
  return <NavigateWithQuery to={String(facility.id)} replace />;
}
