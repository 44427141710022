import { PHI } from '@insidedesk/tuxedo';
import { useGlobalFilters } from '../hooks';

export default function SelectedUserName() {
  const { users } = useGlobalFilters();

  let name: string;
  if (users.length === 1) {
    ({ label: name } = users[0]);
  } else if (users.length > 1) {
    name = 'Multiple Users';
  } else {
    name = 'All Users';
  }

  return <PHI>{name}</PHI>;
}
