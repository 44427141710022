import { MultiSelect, ResetButton, SingleSelect } from '@insidedesk/tuxedo';

import { Stack, Typography } from '@mui/material';
import {
  ADJUDICATION_REASON_CATEGORY_OPTIONS,
  AR_AGE_BUCKET_OPTIONS,
  CLAIM_STATUS_OPTIONS,
  DATE_OF_SERVICE_FILTER_MONTHS,
  ORTHO_OPTIONS,
  WORK_DATE_MONTHS,
} from '../constants';
import {
  useActiveCubeFilters,
  useFilterOptions,
  useGlobalFilters,
} from '../hooks';
import {
  makeArMonthOptions,
  makeDosMonthOptions,
  makeWorkedMonthOptions,
} from '../utils';

const arMonthOptions = makeArMonthOptions();
const dosMonthsOptions = makeDosMonthOptions(DATE_OF_SERVICE_FILTER_MONTHS + 1);
const workedMonthOptions = makeWorkedMonthOptions(WORK_DATE_MONTHS + 1);

export default function QuickFilters() {
  const activeCubeFilters = useActiveCubeFilters();
  const {
    dosMonths,
    adjudicationReasonCategories,
    arAges,
    arMonth,
    claimStatuses,
    facilities,
    ortho,
    workedMonth,
    users,
    updateCubeFilters,
    resetCubeFilters,
  } = useGlobalFilters();

  const { userOptions, facilityOptions } = useFilterOptions();

  return (
    <Stack direction='row' width='100%' spacing={1} alignItems='center'>
      <Typography fontSize='0.875rem' fontWeight={500}>
        Filters:
      </Typography>
      {activeCubeFilters.includes('facilities') && (
        <MultiSelect
          fullWidth
          tags
          limitTags='Multiple Offices'
          size='small'
          label='Office'
          values={facilities}
          options={facilityOptions ?? []}
          onChange={(_, value) =>
            updateCubeFilters({ type: 'facilities', value })
          }
          phi
        />
      )}
      {activeCubeFilters.includes('users') && (
        <MultiSelect
          fullWidth
          tags
          limitTags='Multiple Users'
          size='small'
          label='User'
          values={users}
          options={userOptions ?? []}
          onChange={(_, value) => updateCubeFilters({ type: 'users', value })}
          phi
        />
      )}
      {activeCubeFilters.includes('dosMonths') && (
        <MultiSelect
          label='DoS Month'
          values={dosMonths}
          options={dosMonthsOptions ?? []}
          onChange={(_, value) => {
            updateCubeFilters({ type: 'dosMonths', value });
          }}
          fullWidth
          tags
          limitTags='Multiple DoS Months'
          size='small'
        />
      )}
      {activeCubeFilters.includes('workedMonth') && (
        <SingleSelect
          label='Month Worked'
          value={workedMonth}
          options={workedMonthOptions}
          onChange={(_, value) => {
            updateCubeFilters({ type: 'workedMonth', value });
          }}
          disableClearable
          fullWidth
          size='small'
        />
      )}
      {activeCubeFilters.includes('arMonth') && (
        <SingleSelect
          label='Month'
          options={arMonthOptions}
          value={arMonth}
          onChange={(_, value) => updateCubeFilters({ type: 'arMonth', value })}
          disableClearable
          fullWidth
          size='small'
        />
      )}
      {activeCubeFilters.includes('ortho') && (
        <SingleSelect
          label='Ortho Claims'
          options={ORTHO_OPTIONS}
          value={ortho}
          onChange={(_, value) => updateCubeFilters({ type: 'ortho', value })}
          disableClearable
          fullWidth
          size='small'
        />
      )}
      {activeCubeFilters.includes('claimStatuses') && (
        <MultiSelect
          label='Claim Status'
          options={CLAIM_STATUS_OPTIONS}
          values={claimStatuses}
          onChange={(_, value) =>
            updateCubeFilters({ type: 'claimStatuses', value })
          }
          fullWidth
          tags
          limitTags='Multiple Claim Statuses'
          size='small'
        />
      )}
      {activeCubeFilters.includes('adjudicationReasonCategories') && (
        <MultiSelect
          label='ANSI Denial Reasons'
          options={ADJUDICATION_REASON_CATEGORY_OPTIONS}
          values={adjudicationReasonCategories}
          onChange={(_, value) =>
            updateCubeFilters({ type: 'adjudicationReasonCategories', value })
          }
          fullWidth
          tags
          limitTags='Multiple ANSI Denial Reasons'
          size='small'
        />
      )}
      {activeCubeFilters.includes('arAges') && (
        <MultiSelect
          label='Age Bucket'
          values={arAges}
          onChange={(_, value) => updateCubeFilters({ type: 'arAges', value })}
          options={AR_AGE_BUCKET_OPTIONS}
          fullWidth
          tags
          limitTags='Multiple Age Buckets'
          size='small'
        />
      )}
      <ResetButton onClick={resetCubeFilters}>Clear</ResetButton>
    </Stack>
  );
}
