/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Query, ResultSet } from '@cubejs-client/core';
import { Box } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import * as d3 from 'd3';
import { endOfMonth, formatISO, parseISO, startOfMonth, sub } from 'date-fns';
import { Point, SeriesColumnOptions } from 'highcharts';
import React from 'react';
import {
  ComposedChart,
  ComposedChartProps,
  DataTable,
  DATA_TABLE_KEY,
  LoadDataErrorCard,
  QueriesRenderer,
  QueryRenderer,
  RouteGridContainer,
} from '../../components';
import { arAgeGroupDollarColorMap, arAgeGroupLabelMap } from '../../constants';
import {
  useArMonthRange,
  useCubeFilter,
  useCubeQuery,
  useFacilityFilterOptionsQueryOpts,
  useGlobalFilters,
} from '../../hooks';
import variables from '../../styles/variables.module.scss';
import { sortByAgeGroup, sortPointByX } from '../../utils';

const tableColumnMap = {
  name: { label: 'Office', phi: true, defaultSortOrder: 'asc' },
  ninetyPlusPercent: {
    label: '90+ Claim AR',
    unit: '%',
    showTotal: false,
  },
  ninetyPlusAR: { label: '90+ Claim AR', unit: '$' },
  ninetyPlusDiff: { label: 'Change vs LM', unit: '$' },
} as const;

function ARAgeDetails() {
  const { data: facilities } = useQuery(useFacilityFilterOptionsQueryOpts());

  const { start, end } = useArMonthRange();
  const { facilities: filteredFacilities } = useGlobalFilters();

  const snapshotFilters = useCubeFilter('ClaimARSnapshot');
  const userClaimFilters = useCubeFilter('UserClaim');

  const lastSixMonthsStart = formatISO(
    startOfMonth(sub(start, { months: 6 })),
    { representation: 'date' },
  );
  const lastSixMonthsEnd = formatISO(endOfMonth(end), {
    representation: 'date',
  });
  const arByAgeMOMQuery: Query = {
    ...snapshotFilters,
    measures: ['ClaimARSnapshot.currentAmountExpectedSum'],
    dimensions: ['ClaimARSnapshot.arAgeGroup'],
    timeDimensions: [
      {
        dimension: 'ClaimARSnapshot.date',
        dateRange: [lastSixMonthsStart, lastSixMonthsEnd],
        granularity: 'month',
      },
    ],
  };

  const ageAverageMOMQuery: Query = {
    ...snapshotFilters,
    measures: ['ClaimARSnapshot.arAgeSum', 'ClaimARSnapshot.countLessThanYear'],
    timeDimensions: [
      {
        dimension: 'ClaimARSnapshot.date',
        dateRange: [lastSixMonthsStart, lastSixMonthsEnd],
        granularity: 'month',
      },
    ],
  };

  const closedAgeMOMQuery: Query = {
    ...userClaimFilters,
    measures: ['UserClaim.arAgeSum', 'UserClaim.countLessThanYear'],
    timeDimensions: [
      {
        dimension: 'UserClaim.closedDate',
        dateRange: [
          formatISO(startOfMonth(sub(start, { months: 6 })), {
            representation: 'date',
          }),
          formatISO(endOfMonth(end), { representation: 'date' }),
        ],
        granularity: 'month',
      },
    ],
  };

  const ageByFacilityQuery: Query = {
    ...snapshotFilters,
    measures: ['ClaimARSnapshot.currentAmountExpectedSum'],
    dimensions: ['ClaimARSnapshot.facilityID', 'ClaimARSnapshot.arAgeGroup'],
    timeDimensions: [
      {
        dimension: 'ClaimARSnapshot.date',
        dateRange: [
          formatISO(startOfMonth(start), { representation: 'date' }),
          formatISO(endOfMonth(end), { representation: 'date' }),
        ],
        granularity: 'month',
      },
    ],
  };

  const ninetyPlusLMByFacility: Query = {
    ...snapshotFilters,
    measures: ['ClaimARSnapshot.currentAmountExpectedSum'],
    dimensions: ['ClaimARSnapshot.facilityID'],
    timeDimensions: [
      {
        dimension: 'ClaimARSnapshot.date',
        dateRange: [
          formatISO(startOfMonth(sub(start, { months: 1 })), {
            representation: 'date',
          }),
          formatISO(endOfMonth(sub(end, { months: 1 })), {
            representation: 'date',
          }),
        ],
        granularity: 'month',
      },
    ],
    filters: [
      ...snapshotFilters.filters,
      {
        member: 'ClaimARSnapshot.arAgeGroup',
        operator: 'equals',
        values: ['180', '180+'],
      },
    ],
  };

  const { resultSet: ageAverageMOMResult, error: ageAverageMOMError } =
    useCubeQuery(ageAverageMOMQuery);

  const { resultSet: closedAgeMOMResult, error: closedAgeMOMError } =
    useCubeQuery(closedAgeMOMQuery);

  const transformARByAgeMOM = React.useCallback(
    (resultSet: ResultSet | null): Partial<ComposedChartProps> => {
      const bars: ChartDataSeries<SeriesColumnOptions>[] = [];
      const ninetyPlusByTimestamp = new Map<number, Partial<Point>>();
      const totalMOMByTimestamp = new Map<number, Partial<Point>>();
      /** XXX:
       * Have to use the rawData here to do a manual 'chartPivot'. For some
       * reason when using a compareDateRange query the chartPivot can switch
       * some of the groups' data with each other. Almost certainly a bug with
       * cubejs.
       */
      resultSet
        ?.chartPivot({
          x: ['ClaimARSnapshot.arAgeGroup'],
          y: ['ClaimARSnapshot.date', 'measures'],
        })
        .sort((a, b) => sortByAgeGroup(a.x, b.x))
        .forEach((row) => {
          const ageGroup = arAgeGroupLabelMap[row.x];
          const color = arAgeGroupDollarColorMap[row.x];
          const valuesPerMonth: Partial<Point>[] = Object.entries(row)
            .filter(([, value]) => typeof value === 'number')
            .map(([key, value]) => {
              const firstDayOfMonth = parseISO(key.split(',')[0]);
              const firstDayTimestamp = startOfMonth(firstDayOfMonth).getTime();

              if (parseInt(row.x, 10) > 90) {
                let ninetyPlusPoint =
                  ninetyPlusByTimestamp.get(firstDayTimestamp);
                if (ninetyPlusPoint === undefined) {
                  ninetyPlusPoint = {
                    x: firstDayTimestamp,
                    y: 0,
                  };
                  ninetyPlusByTimestamp.set(firstDayTimestamp, ninetyPlusPoint);
                }
                ninetyPlusPoint.y += value;
              }

              let arTotal = totalMOMByTimestamp.get(firstDayTimestamp);
              if (arTotal === undefined) {
                arTotal = {
                  x: firstDayTimestamp,
                  y: 0,
                };
                totalMOMByTimestamp.set(firstDayTimestamp, arTotal);
              }
              arTotal.y += value;

              return {
                x: firstDayTimestamp,
                y: value,
              };
            })
            .sort(sortPointByX);
          bars.push({
            data: valuesPerMonth,
            displayUnit: '$',
            name: ageGroup,
            color,
            /* XXX: For some reason highcharts renders a '%' at the end of the '< 30' group
             * tooltip value. Seems like something that is
             * [not easy to reproduce](https://www.highcharts.com/forum/viewtopic.php?f=9&t=49595&p=180673#p180673)
             * , and a newer version of highcharts doesn't fix it. To avoid having to
             * do a deep dive on _why_ it's happening, I'd prefer to just fix it by
             * specifying the suffix for the columns for now. Maybe someday we can go
             * back and fix...
             */
            tooltip: {
              valueSuffix: '',
            },
            type: 'column',
            yAxis: '0',
          });
        });

      const ninetyPlusData = Array.from(ninetyPlusByTimestamp.entries())
        .map(([timestamp, point]) => {
          const total = totalMOMByTimestamp.get(timestamp)?.y ?? 0;
          return {
            x: point.x,
            y: (100.0 * (point.y ?? 0)) / total,
          };
        })
        /* Only filtering NaN since if total is 0 then the 90+ group has to
         * also be 0 and we won't end up with Infinity
         */
        .filter(({ y }) => !Number.isNaN(y))
        .sort((a, b) => (a?.x ?? 0) - (b?.x ?? 0));

      return {
        bars,
        lines: [
          {
            data: ninetyPlusData,
            displayUnit: '%',
            marker: {
              symbol: 'circle',
            },
            name: '90+',
            type: 'spline',
            yAxis: '1',
          },
        ],
      };
    },
    [],
  );

  const closedAgeMOM =
    closedAgeMOMResult?.chartPivot().map((row) => {
      const sum = row[closedAgeMOMQuery.measures![0]];
      const count = row[closedAgeMOMQuery.measures![1]];
      return {
        x: parseISO(row.x).getTime(),
        y: sum / count,
        sum,
        count,
      };
    }) ?? [];
  const closedAgeMOMByTimestamp = d3.index(closedAgeMOM, (row) => row.x);

  const ageAverageMOM =
    ageAverageMOMResult?.chartPivot().map((row) => {
      const lastDayOfMonth = parseISO(row.x);
      const firstDayOfMonth = startOfMonth(lastDayOfMonth);
      const x = firstDayOfMonth.getTime();

      const sum = row[ageAverageMOMQuery.measures![0]];
      const count = row[ageAverageMOMQuery.measures![1]];
      const closed = closedAgeMOMByTimestamp.get(x);
      let age: number;
      if (closed !== undefined) {
        age = (sum + closed.sum) / (count + closed.count);
      } else {
        age = sum / count;
      }
      return {
        x,
        y: age,
      };
    }) ?? [];

  const transformAgeByFacilityResult = (
    resultSets: [ResultSet, ResultSet] | null,
  ) => {
    if (resultSets === null) return [];
    const [ageByFacilityResult, ninetyPlusLMByFacilityResult] = resultSets;
    const agesByFacility = d3.index(
      ageByFacilityResult.chartPivot({
        x: ['ClaimARSnapshot.facilityID'],
        y: ['ClaimARSnapshot.arAgeGroup', 'measures'],
      }),
      (row) => row.xValues[0],
    );
    const ninetyPlusLMByFacilityResultPivotByFacility = d3.index(
      ninetyPlusLMByFacilityResult.chartPivot({
        x: ['ClaimARSnapshot.facilityID'],
        y: ['measures'],
      }),
      (row) => row.xValues[0],
    );
    const displayFacilities = filteredFacilities.length
      ? filteredFacilities.map(({ facility }) => facility)
      : facilities ?? [];
    return displayFacilities.map((facility) => {
      const facilityAgeRow = agesByFacility.get(facility.id.toString());
      const facilityAges: Record<string, number> = Object.fromEntries(
        Object.entries(facilityAgeRow ?? {})
          .filter(([, value]) => typeof value === 'number')
          .map(([key, value]) => [key.split(','), value]),
      );
      const totalAR = Object.values(facilityAges).reduce((t, x) => t + x, 0);
      const ninetyPlusAR = Object.entries(facilityAges)
        .filter(([key]) => parseInt(key, 10) > 90)
        .reduce((t, [, x]) => t + x, 0);
      const ninetyPlusPercent = (100.0 * ninetyPlusAR) / totalAR;

      const ninetyPlusLM =
        ninetyPlusLMByFacilityResultPivotByFacility.get(
          facility.id.toString(),
        )?.[ninetyPlusLMByFacility.measures![0]] ?? 0;
      const ninetyPlusDiff = ninetyPlusAR - ninetyPlusLM;

      return {
        [DATA_TABLE_KEY]: facility.id.toString(),
        name: facility.name,
        ninetyPlusPercent,
        ninetyPlusAR,
        ninetyPlusDiff,
      };
    });
  };

  return (
    <RouteGridContainer
      data-testid='claim-ar-age-details'
      gridTemplateColumns='1fr 1fr'
    >
      <QueryRenderer
        query={arByAgeMOMQuery}
        transformResult={transformARByAgeMOM}
        render={(chartProps) => (
          <ComposedChart
            {...chartProps}
            leftAxis={{ reversedStacks: false }}
            stack='normal'
            timeGranularity='month'
            title='Claim AR Trend By Age Bucket'
            xAxis={{ type: 'datetime' }}
          />
        )}
        fallback={<LoadDataErrorCard />}
      />
      <Box gridRow='span 2'>
        <QueriesRenderer
          queries={[ageByFacilityQuery, ninetyPlusLMByFacility] as const}
          transformResult={transformAgeByFacilityResult}
          render={(ageByFacility) => (
            <DataTable
              columnLabelMap={tableColumnMap}
              data={ageByFacility}
              defaultSortColumn='ninetyPlusPercent'
              bordered
            />
          )}
          fallback={<LoadDataErrorCard />}
        />
      </Box>
      {!ageAverageMOMError && !closedAgeMOMError ? (
        <ComposedChart
          lines={[
            {
              data: ageAverageMOM,
              displayUnit: 'day',
              color: variables.paletteDayLight,
              dataLabels: {
                enabled: true,
              },
              name: 'Claim AR Age',
              type: 'spline',
            },
            {
              data: closedAgeMOM,
              displayUnit: 'day',
              color: variables.paletteDayDark,
              dataLabels: {
                enabled: true,
              },
              name: 'Closed Claims Age',
              type: 'spline',
            },
          ]}
          timeGranularity='month'
          title='Claim AR Age M.O.M'
          xAxis={{ type: 'datetime' }}
        />
      ) : (
        <LoadDataErrorCard />
      )}
    </RouteGridContainer>
  );
}

export default ARAgeDetails;
