import { useCubeFilter } from '../hooks';
import DimensionBreakdownChart from './DimensionBreakdownChart';

export interface WorkEventsBreakdownChartProps {
  dimensionSpec: { label: string; metric: string };
  sortByValue?: boolean;
}
export default function WorkEventsBreakdownChart(
  props: WorkEventsBreakdownChartProps,
) {
  const { dimensionSpec, sortByValue } = props;
  let baseQuery = useCubeFilter('WorkDoneByUser', {
    useTimeDimensionsInsteadOfSegments: true,
  });
  baseQuery = {
    ...baseQuery,
    segments: [...baseQuery.segments, 'WorkDoneByUser.workedOnly'],
  };
  return (
    <DimensionBreakdownChart
      barSize={25}
      baseQuery={baseQuery}
      chartMetric='count'
      dimensionSpec={dimensionSpec}
      measureSpec={{
        count: { label: 'Work Events', metric: 'WorkDoneByUser.claimCount' },
      }}
      unitSpec={{ count: 'work' }}
      sortByValue={sortByValue}
    />
  );
}
