import { useFlags } from '@insidedesk/tuxedo';
import { Box, Stack } from '@mui/material';
import { PropsWithChildren } from 'react';
import { DataCard, DetailedKPI, NavigationWrapper } from '.';

type KPIRowElement = DetailedKPIPoint & {
  navigation?: { location: string; tooltip: string };
};

export type KPIRowProps = {
  kpis: (KPIRowElement | KPIRowElement[])[];
};

/**
 * Can pass in one level of nested kpis in the kpis prop to merge them into a
 * single DataCard. Navigation from the first item in the nested array will be
 * used.
 */
export default function KPIRow(props: KPIRowProps) {
  const { kpis } = props;
  const flags = useFlags();

  return (
    <Box
      display='grid'
      gridTemplateColumns='repeat(auto-fit, minmax(0, 1fr))'
      gap={3}
      px={2}
      py={1}
      sx={[
        Boolean(flags.horizontalKPIs) && {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          '@container (width < 75rem)': { gap: 1 },
        },
      ]}
    >
      {kpis.map((kpiRowElement) => {
        if (Array.isArray(kpiRowElement)) {
          return (
            <MergedKPIBox
              key={JSON.stringify(kpiRowElement)}
              kpis={kpiRowElement}
            />
          );
        }
        const { navigation, ...rest } = kpiRowElement;
        return (
          <ConditionalNavigation
            key={JSON.stringify(rest)}
            navigationProps={navigation ?? undefined}
          >
            <DetailedKPI {...rest} />
          </ConditionalNavigation>
        );
      })}
    </Box>
  );
}

function MergedKPIBox({ kpis }: { kpis: KPIRowElement[] }) {
  const { navigation } = kpis[0];
  return (
    <DataCard sx={{ gridColumn: `span ${kpis.length}` }}>
      <ConditionalNavigation navigationProps={navigation}>
        <Stack direction='row' justifyContent='space-evenly'>
          {kpis.map(({ navigation: _, ...rest }) => (
            <DetailedKPI
              key={JSON.stringify(rest)}
              {...rest}
              useDataCardWrapper={false}
            />
          ))}
        </Stack>
      </ConditionalNavigation>
    </DataCard>
  );
}

function ConditionalNavigation({
  navigationProps,
  children,
}: PropsWithChildren<{
  navigationProps: { location: string; tooltip: string } | undefined;
}>) {
  if (navigationProps) {
    return (
      <NavigationWrapper {...navigationProps}>{children}</NavigationWrapper>
    );
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}
