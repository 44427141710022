import Highcharts from 'highcharts';
import { convertSeries, formatUnits } from '../utils';
import Chart from './Chart';

export interface PieChartProps {
  title: string;
  data: Highcharts.PointOptionsObject[];
  unit?: DisplayUnit;
  blur?: boolean;
}

export default function PieChart(props: PieChartProps) {
  const { data, title, unit, blur = false } = props;
  const pointLabelFormat = formatUnits('{point.y:.3f}', unit);
  const options: Highcharts.Options = {
    chart: { type: 'pie' },
    plotOptions: {
      pie: {
        dataLabels: {
          connectorShape: 'fixedOffset',
          connectorColor: '#000',
          distance: 10,
          format: '<b>{point.name}</b>: {point.y:.1f}',
          softConnector: false,
        },
      },
    },
    series: [
      convertSeries({
        data,
        dataLabels: {
          format: `{point.name}: ${pointLabelFormat}`,
        },
        displayUnit: unit,
        blurDataLabels: blur,
        innerSize: '40%',
        size: '75%',
        name: '',
        type: 'pie',
        slicedOffset: 0,
      }),
    ],
    title: {
      text: title,
    },
    tooltip: {
      headerFormat: '',
    },
  };

  return <Chart options={options} />;
}
