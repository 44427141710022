import { Flag, FlagSet, useHasRole } from '@insidedesk/tuxedo';
import { ReactNode } from 'react';

interface FlagOrInternalProps {
  children: ReactNode;
  fallback?: ReactNode;
  flagName: keyof FlagSet;
}
export default function FlagOrInternal(props: FlagOrInternalProps) {
  const { children, fallback, flagName } = props;
  const hasRole = useHasRole();
  if (hasRole('internal')) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }

  return (
    <Flag flagName={flagName} fallback={fallback}>
      {children}
    </Flag>
  );
}
