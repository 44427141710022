import { DeeplyReadonly, Query } from '@cubejs-client/core';
import { CubeContext } from '@cubejs-client/react';
import { minutesToMilliseconds } from 'date-fns';
import { useContext } from 'react';
import { getCubeQueryOpts, tidyRawData } from '../utils';

export default function useUserFilterOptionsQueryOpts() {
  const query = {
    segments: ['User.clientIsolation'],
    dimensions: ['User.id', 'User.name'],
  } as const satisfies DeeplyReadonly<Query>;
  const context = useContext(CubeContext);
  return {
    ...getCubeQueryOpts(query, context, (resultSet) =>
      tidyRawData<typeof query>(resultSet).map((row) => ({
        id: row['User.id'],
        name: row['User.name'],
      })),
    ),
    staleTime: minutesToMilliseconds(5),
  };
}
