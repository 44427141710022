/* eslint-disable no-restricted-syntax */
// eslint-disable-next-line max-classes-per-file
import * as d3 from 'd3';
import { SeriesOptionsType } from 'highcharts';

export default class AutoChartPalette {
  public constructor(
    public readonly unitColors: Record<DisplayUnit, string[]>,
  ) {}

  public apply(options: Highcharts.Options): Highcharts.Options {
    const serieses = options.series ?? [];
    const newSerieses = d3
      .flatGroup(
        serieses,
        (series) => series.type,
        (series) =>
          isCompatibleSeries(series) ? series.displayUnit : undefined,
      )
      .flatMap(([type, unit, seriesGroups]) =>
        this.applyToGroup(type, unit, seriesGroups, options),
      );
    return { ...options, series: newSerieses };
  }

  private applyToGroup<TSeries extends SeriesOptionsType>(
    type: SeriesOptionsType['type'],
    unit: DisplayUnit | undefined,
    serieses: TSeries[],
    options: Highcharts.Options,
  ) {
    const colors = this.unitColors[unit ?? 'claim'];

    if (type === 'bar') {
      return serieses.map((series) =>
        applyColorToSeries(series, colors[colors.length - 1]),
      );
    }
    if (type === 'column' && options.plotOptions?.column?.stacking) {
      return serieses.map((series, index) =>
        applyColorToSeries(
          series,
          lastN(colors, serieses.length).reverse()[index % serieses.length],
        ),
      );
    }
    if (type === 'column') {
      return serieses.map((series, index) =>
        applyColorToSeries(
          series,
          lastN(colors, serieses.length)[index % serieses.length],
        ),
      );
    }
    if (type === 'line' || type === 'spline') {
      return serieses.map((series, index) =>
        applyColorToSeries(
          series,
          firstN(colors, serieses.length).reverse()[index % serieses.length],
        ),
      );
    }
    return serieses;
  }
}

function lastN<T>(items: T[], n: number) {
  return items.slice(Math.max(0, items.length - n));
}

function firstN<T>(items: T[], n: number) {
  return items.slice(0, n);
}

function applyColorToSeries<TSeries extends SeriesOptionsType>(
  series: TSeries,
  color: string,
): TSeries {
  return { color, ...series };
}

function isCompatibleSeries(
  series: SeriesOptionsType,
): series is ChartDataSeries<HighchartsSeriesOptions> {
  return ['bar', 'column', 'line', 'spline'].includes(series.type);
}
