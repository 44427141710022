import { isNotNullish } from '@insidedesk/tuxedo';
import { ReactNode, useMemo } from 'react';
import useMatches from './useMatches';

export default function useTitleFragments(root: string) {
  const matches = useMatches();
  return useMemo(() => {
    let fragments: ReactNode[][] = [];
    matches
      .map((match) => match.handle)
      .filter(isNotNullish)
      .forEach((handle) => {
        if (handle.titleRoot) {
          fragments = [...handle.titleRoot];
        }
        const title = handle.title ?? (handle.crumb && [handle.crumb]);
        if (title) {
          fragments.push(title);
        }
      });
    return [[root], ...fragments];
  }, [matches, root]);
}
