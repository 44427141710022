import { DeeplyReadonly, Query, ResultSet } from '@cubejs-client/core';
import { Box } from '@mui/material';
import { useSuspenseQuery } from '@suspensive/react-query';
import * as d3 from 'd3';
import {
  ColumnLabelMapDescription,
  DataTable,
  DataTableValue,
  DATA_TABLE_KEY,
  LoadDataErrorMessage,
  QueryRenderer,
} from '../../../components';
import {
  useCubeFilter,
  useFacilityFilterOptionsQueryOpts,
  useGlobalFilters,
} from '../../../hooks';
import { TidyQueryRecord, tidyRawData } from '../../../utils';

type RowData = {
  facility: DataTableValue;
  sampleSize: number | null;
  denialRate: number | null;
  numDeniedClaims: number | null;
  expectedAmt: number | null;
  deniedBenefits: number | null;
  numDeniedClaimsOpen: number | null;
  deniedBenefitsOpen: number | null;
  numDeniedClaimsOpenWorked: number | null;
};

const tableColumnMap = {
  facility: { label: 'Office', phi: true, defaultSortOrder: 'asc' },
  sampleSize: '# EOBs',
  denialRate: { label: 'Denial Rate', unit: '%' },
  numDeniedClaims: '# Denied Claims',
  expectedAmt: { label: '$ Expected Procedures', unit: '$' },
  deniedBenefits: { label: '$ Denied Benefits', unit: '$' },
  numDeniedClaimsOpen: '# Denied Open Claims',
  deniedBenefitsOpen: { label: '$ Denied Open Claims', unit: '$' },
  numDeniedClaimsOpenWorked: '# Denied Open Claims Worked',
} satisfies Record<keyof RowData, ColumnLabelMapDescription>;

export default function DenialsByOfficeTable() {
  const { data: facilities } = useSuspenseQuery(
    useFacilityFilterOptionsQueryOpts(),
  );

  const { facilities: facilityFilters } = useGlobalFilters();
  const baseQuery = useCubeFilter('Claim');
  const query = {
    ...baseQuery,
    measures: [
      'Claim.count',
      'AdjudicatedClaims.totalExpected',
      'AdjudicatedClaims.deniedBenefits',
    ],
    dimensions: [
      'Claim.facilityID',
      'Claim.resolved',
      'Claim.workDone',
      'AdjudicatedClaims.denialStatus',
    ],
    filters: [
      ...baseQuery.filters,
      {
        member: 'AdjudicatedClaims.denialStatus',
        operator: 'notEquals',
        values: ['unknown'],
      },
    ],
  } as const satisfies DeeplyReadonly<Query>;

  const getData = (tidyData: TidyQueryRecord<typeof query>[]) => {
    const byFacility = d3.group(tidyData, (row) => row['Claim.facilityID']);
    const mappedData: Record<Facility['id'], RowData> = Object.fromEntries(
      facilities.map((facility) => [
        facility.id,
        createEmptyFacilityRow(facility),
      ]),
    );

    facilities.forEach((facility) => {
      const facilityData = byFacility.get(String(facility.id));
      if (!facilityData) return;

      const sampleSize = d3.sum(facilityData, (row) => row['Claim.count']);
      const deniedClaims = facilityData.filter(
        (row) =>
          row['AdjudicatedClaims.denialStatus'] === 'full' ||
          row['AdjudicatedClaims.denialStatus'] === 'partial',
      );
      const deniedBenefits = d3.sum(
        deniedClaims,
        (row) => row['AdjudicatedClaims.deniedBenefits'],
      );
      const expectedAmt = d3.sum(
        facilityData,
        (row) => row['AdjudicatedClaims.totalExpected'],
      );

      mappedData[facility.id] = {
        ...mappedData[facility.id],
        sampleSize,
        denialRate:
          deniedBenefits === 0 && expectedAmt === 0
            ? 0
            : Math.round((deniedBenefits / expectedAmt) * 100),
        numDeniedClaims: d3.sum(deniedClaims, (row) => row['Claim.count']),
        expectedAmt,
        deniedBenefits,
        numDeniedClaimsOpen: d3.sum(
          deniedClaims.filter((row) => row['Claim.resolved'] === 'false'),
          (row) => row['Claim.count'],
        ),
        deniedBenefitsOpen: d3.sum(
          deniedClaims.filter((row) => row['Claim.resolved'] === 'false'),
          (row) => row['AdjudicatedClaims.deniedBenefits'],
        ),
        numDeniedClaimsOpenWorked: d3.sum(
          deniedClaims.filter(
            (row) =>
              row['Claim.resolved'] === 'false' &&
              row['Claim.workDone'] === 'true',
          ),
          (row) => row['Claim.count'],
        ),
      };
    });

    return Object.entries(mappedData)
      .filter(([facilityId]) => {
        if (facilityFilters.length === 0) return true;
        return facilityFilters.some(
          (facility) => String(facility.id) === facilityId,
        );
      })
      .map(([facilityId, row]) => ({ ...row, [DATA_TABLE_KEY]: facilityId }));
  };

  const getTotals = (tidyData: TidyQueryRecord<typeof query>[]) => {
    const denied = tidyData.filter(
      (row) =>
        row['AdjudicatedClaims.denialStatus'] === 'full' ||
        row['AdjudicatedClaims.denialStatus'] === 'partial',
    );
    const rate = Math.round(
      (100 * d3.sum(denied, (row) => row['AdjudicatedClaims.deniedBenefits'])) /
        d3.sum(tidyData, (row) => row['AdjudicatedClaims.totalExpected']),
    );

    const denialRate = Number.isNaN(rate) ? 0 : rate;
    return { denialRate };
  };

  const transformResult = (resultSet: ResultSet | null) => {
    if (resultSet === null) return { data: [], totals: {} };
    const tidyData = tidyRawData<typeof query>(resultSet);
    return { data: getData(tidyData), totals: getTotals(tidyData) };
  };

  return (
    <QueryRenderer
      query={query}
      transformResult={transformResult}
      render={({ data, totals }) => (
        <DataTable
          columnLabelMap={tableColumnMap}
          data={data}
          totals={totals}
          defaultSortColumn='facility'
        />
      )}
      fallback={
        <Box display='grid' height='100%' sx={{ placeItems: 'center' }}>
          <LoadDataErrorMessage />
        </Box>
      }
      queryKey={facilities.map((facility) => String(facility.id))}
    />
  );
}

function createEmptyFacilityRow(facility: Facility): RowData {
  return {
    facility: {
      cellValue: facility.name,
      to: { pathname: String(facility.id) },
    },
    sampleSize: null,
    denialRate: null,
    numDeniedClaims: null,
    expectedAmt: null,
    deniedBenefits: null,
    numDeniedClaimsOpen: null,
    deniedBenefitsOpen: null,
    numDeniedClaimsOpenWorked: null,
  };
}
