import { Query, QueryRecordType, ResultSet } from '@cubejs-client/core';
import { PointOptionsObject } from 'highcharts';
import { useCallback } from 'react';
import { LoadDataErrorCard, PieChart, QueryRenderer } from '../components';
import variables from '../styles/variables.module.scss';

interface ARAndRCMStatusProps extends ReportOptions {
  rcmStatusQuery: Query;
}

const labelMap: Record<string, string> = {
  worked: 'Worked',
  newAR: '< 30',
  unworked: 'To Be Worked',
};
const colorMap: Record<string, string> = {
  worked: variables.progressionDollar1,
  newAR: variables.progressionDollar3,
  unworked: variables.progressionDollar4,
};

const labelIndices = Object.fromEntries(
  // This is only correct because none of the labelMap keys are numeric
  Object.values(labelMap).map((value, index) => [value, index]),
);

export default function ARAndRCMStatus(props: ARAndRCMStatusProps) {
  const { rcmStatusQuery } = props;
  const measure =
    rcmStatusQuery.measures?.[0] ?? 'ClaimARSnapshot.currentAmountExpectedSum';

  const transformResult = useCallback(
    (
      result: ResultSet<QueryRecordType<Query>> | null,
    ): PointOptionsObject[] | undefined =>
      result
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        ?.chartPivot({ x: [rcmStatusQuery.dimensions![0]], y: ['measures'] })
        .map((row) => {
          const status = row.xValues[0];
          return {
            name: labelMap[status],
            value: row[measure],
            y: row[measure],
            color: colorMap[status],
            sliced: status === 'unworked',
          };
        })
        .sort((a, b) => labelIndices[a.name] - labelIndices[b.name]),
    [measure, rcmStatusQuery.dimensions],
  );

  return (
    <QueryRenderer
      query={rcmStatusQuery}
      transformResult={transformResult}
      render={(data) => (
        <PieChart data={data ?? []} title='Claim AR & RCM Status' unit='$' />
      )}
      fallback={<LoadDataErrorCard />}
    />
  );
}
