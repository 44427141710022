import { QueryRecordType, ResultSet } from '@cubejs-client/core';
import * as d3 from 'd3';
import { SeriesColumnOptions } from 'highcharts';
import { ComposedChartProps } from '..';
import { tidyRawData } from '../../utils';
import { UseTrendChartOptions } from './types';

export default function useTrendChart<
  TQuery extends QueryWithTimeDimension,
  TChartMetric extends ChartMetric,
>(options: UseTrendChartOptions<TQuery, TChartMetric>) {
  const {
    title,
    height,
    chartMetric,
    query,
    trendLine,
    barConfig,
    ...composedChartProps
  } = options;
  const timeDimension = query.timeDimensions[0].dimension;
  const { granularity: timeGranularity = 'month' } = query.timeDimensions[0];

  const commonChartProps: ComposedChartProps = {
    height,
    stack: 'normal',
    timeGranularity,
    leftAxis: { reversedStacks: false },
    ...composedChartProps,
  };

  const transformResult = (
    result: ResultSet<QueryRecordType<TQuery>> | null,
  ): ComposedChartProps => {
    if (result === null)
      return { title: title[chartMetric], ...commonChartProps };

    const data = tidyRawData<TQuery>(result, {
      fillMissingDates: false,
    });

    const byDate = d3.group(
      data,
      (row) => row[timeDimension as keyof typeof row] as Date,
    );

    const trendLinePoints = trendLine
      ? d3.map(byDate.entries(), ([date, rows]) => ({
          x: date.getTime(),
          y: trendLine.transformData(rows),
        }))
      : [];

    const defaultBarUnit = chartMetric === 'dollar' ? '$' : undefined;
    const bars = barConfig.groupOrder
      .map(
        (group) =>
          ({
            name: barConfig.labelMap[group],
            ...(barConfig.colorMap ? { color: barConfig.colorMap[group] } : {}),
            type: 'column',
            displayUnit: barConfig.unitSpec?.[chartMetric] ?? defaultBarUnit,
            data: d3.map(byDate.entries(), ([date, rows]) => ({
              x: date.getTime(),
              y: d3.sum(
                rows.filter(barConfig.filterFns[group]),
                (row) => row[barConfig.measureSpec[chartMetric]],
              ),
            })),
          } satisfies ChartDataSeries<SeriesColumnOptions>),
      )
      .filter(
        (group) =>
          !barConfig.excludeEmptyGroups ||
          group.data.some((point) => point.y > 0),
      );

    return {
      title: title[chartMetric],
      bars,
      xAxis: { type: 'datetime' },
      lines: trendLine
        ? [
            {
              name: trendLine.label,
              type: 'spline',
              data: trendLinePoints,
              displayUnit: '%',
              yAxis: 1,
              marker: { symbol: 'circle' },
              ...(trendLine.color && { color: trendLine.color }),
            },
          ]
        : undefined,
      ...commonChartProps,
    };
  };

  return { query, transformResult, queryKey: [chartMetric] };
}
