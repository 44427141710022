import {
  DeeplyReadonly,
  Query,
  QueryRecordType,
  ResultSet,
} from '@cubejs-client/core';
import { CubeContext } from '@cubejs-client/react';
import { Box } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import * as d3 from 'd3';
import { differenceInDays, isValid, max, min, parseISO } from 'date-fns';
import { useCallback, useContext } from 'react';
import {
  ColumnLabelMap,
  DataTable,
  DataTableExpandRow,
  DataTableTotal,
  DataTableValue,
  DATA_TABLE_KEY,
  ExpandRowFilterValue,
  GetExpandedData,
  rowId,
} from '../../../components';
import LoadDataErrorMessage from '../../../components/LoadDataErrorMessage';
import {
  useClaimsWorkedCube,
  useCubeFilter,
  useFacilityFilterOptionsQueryOpts,
  useGlobalFilters,
  useLoader,
} from '../../../hooks';
import {
  CubeDataSlice,
  fetchCubeDataSlices,
  formatDateField,
  tidyRawData,
} from '../../../utils';
import { claimsWorkedData as getClaimsWorkedData } from '../utils';

const columnLabelMap = {
  userName: {
    label: "Person's Name",
    adornment: 'expand',
    phi: true,
    defaultSortOrder: 'asc',
    minWidth: 250,
  },
  touchEvents: '# Touch Events',
  workEvents: '# Work Events',
  viewEvents: '# View Events',
  claimsWorked: '# Claims Worked',
  claimsWorkedOpen: '# Claims Worked Open',
  closeRateWorked: { label: 'Close Rate Worked', unit: '%' },
  lastWorkDate: { label: 'Last Work Date', showTotal: false },
  workToClose: 'Work To Close (Days)',
  workEventsPerDay: 'Work Events / Day',
  dollarsPostedWorked: { label: '$ Posted: Worked', unit: '$' },
  claimYieldWorked: { label: 'Claim Yield: Worked', unit: '%' },
} as const satisfies ColumnLabelMap;

type TableRow = DataTableExpandRow<keyof typeof columnLabelMap>;
type TotalsRow = Record<keyof typeof columnLabelMap, DataTableTotal>;

type WorkDoneExpandedData = {
  key: string;
  facilityName: DataTableValue;
  touchEvents: DataTableValue;
  workEvents: DataTableValue;
  viewEvents: DataTableValue;
  lastWorkDate: DataTableValue;
  workEventsPerDay: DataTableValue;
};

type ClaimsWorkedExpandedData = {
  claimsWorked: DataTableValue;
  claimsWorkedOpen: DataTableValue;
  closeRateWorked: DataTableValue;
  workToClose: DataTableValue;
  dollarsPostedWorked: DataTableValue;
  claimYieldWorked: DataTableValue;
};

type TableExpandedRow = WorkDoneExpandedData &
  ClaimsWorkedExpandedData & { [DATA_TABLE_KEY]: string };

export default function ProductivityByUserTable() {
  const { data, totals, error } = useLoadTableData();
  const getExpandedData = useGetExpandedData();

  if (error)
    return (
      <Box
        data-testid='productivity-by-user'
        display='grid'
        height='100%'
        sx={{ placeItems: 'center' }}
      >
        <LoadDataErrorMessage />
      </Box>
    );
  return (
    <DataTable
      data-testid='productivity-by-user'
      columnLabelMap={columnLabelMap}
      data={data}
      totals={totals}
      getExpandedData={getExpandedData}
      defaultSortColumn='userName'
      remapExpandedColumn={{ facilityName: 'userName' }}
    />
  );
}

function useLoadTableData() {
  const slices = [
    useWorkDoneByUserDataSliceOpts(),
    useClaimsWorkedDataSliceOpts(),
    useClaimsWorkedTotalsDataSliceOpts(),
  ] as const;

  const results = useLoader({
    async loader({ fetchCubeQuery }) {
      const slicesData = await fetchCubeDataSlices(fetchCubeQuery, slices);

      const mergedData = new Map<string, Partial<TableRow>>();
      slicesData.forEach(({ tableData }) => {
        // eslint-disable-next-line no-restricted-syntax
        for (const [user, row] of tableData?.entries() ?? []) {
          mergedData.set(user, { ...mergedData.get(user), ...row });
        }
      });
      let mergedTotals: Partial<TotalsRow> = {};
      slicesData.forEach(({ tableTotals }) => {
        mergedTotals = { ...mergedTotals, ...tableTotals };
      });
      return {
        data: Array.from(mergedData.values()) as TableRow[],
        totals: mergedTotals,
      };
    },
    default() {
      return {
        data: [] as TableRow[],
        totals: {} as Partial<TotalsRow>,
      };
    },
    loaderKey: [slices.map((slice) => slice.query)],
  });

  const { data, totals } = results.data;
  return { data, totals, error: results.error };
}

type TableSliceData = {
  tableData: d3.InternMap<string, Partial<TableRow>>;
  tableTotals: Partial<TotalsRow>;
};

function useWorkDoneByUserDataSliceOpts(): CubeDataSlice<
  typeof query,
  TableSliceData
> {
  const { startDate, endDate } = useGlobalFilters().workedMonth.range;
  const daysInRange = differenceInDays(min([endDate, new Date()]), startDate);

  const baseQuery = useCubeFilter('WorkDoneByUser', {
    useTimeDimensionsInsteadOfSegments: true,
  });
  const query = {
    ...baseQuery,
    measures: ['WorkDoneByUser.claimCount', 'WorkDoneByUser.lastWorkDate'],
    dimensions: ['WorkDoneByUser.workTypeGroup', 'User.id', 'User.name'],
    filters: [
      ...baseQuery.filters,
      {
        member: 'WorkDoneByUser.workTypeGroup',
        operator: 'equals',
        values: ['CLAIM_WORKED', 'VIEW_ONLY'],
      },
    ],
  } as const satisfies DeeplyReadonly<Query>;

  return {
    query,
    transformResult: (resultSet) => {
      // No tidyRawData here because WorkDoneByUser.lastWorkDate is a string
      const data = resultSet.rawData();
      const tableData = d3.rollup(
        data,
        (rows): Partial<TableRow> => {
          const userID = String(rows[0]['User.id']);
          const userName = {
            cellValue: String(rows[0]['User.name']),
            to: userID,
          };
          const touchEvents = d3.sum(rows, (row) =>
            Number(row['WorkDoneByUser.claimCount']),
          );
          const workEvents = d3.sum(
            rows.filter(
              (row) => row['WorkDoneByUser.workTypeGroup'] === 'CLAIM_WORKED',
            ),
            (row) => Number(row['WorkDoneByUser.claimCount']),
          );
          const viewEvents = d3.sum(
            rows.filter(
              (row) => row['WorkDoneByUser.workTypeGroup'] === 'VIEW_ONLY',
            ),
            (row) => Number(row['WorkDoneByUser.claimCount']),
          );
          const lastWorkDate = formatDateField(
            max(
              rows
                .map((row) =>
                  parseISO(String(row['WorkDoneByUser.lastWorkDate'])),
                )
                .filter(isValid),
            ),
          );
          const workEventsPerDay = workEvents / daysInRange;

          return {
            [DATA_TABLE_KEY]: userID,
            [rowId]: userID,
            userName,
            touchEvents,
            workEvents,
            viewEvents,
            lastWorkDate,
            workEventsPerDay,
          };
        },
        (row) => String(row['User.id']),
      );
      return { tableData, tableTotals: {} };
    },
  };
}

function useClaimsWorkedDataSliceOpts(): CubeDataSlice<
  typeof query,
  TableSliceData
> {
  const query = {
    ...useCubeFilter('ClaimsWorkedByUser'),
    measures: [
      'ClaimsWorkedByUser.adjustedAmountExpectedSum',
      'ClaimsWorkedByUser.amountPaidSum',
      'ClaimsWorkedByUser.claimClosedCount',
      'ClaimsWorkedByUser.claimCount',
      'ClaimsWorkedByUser.daysToClose',
      'ClaimsWorkedByUser.deletedAdjustedAmountExpectedSum',
    ],
    dimensions: ['ClaimsWorkedByUser.closed', 'User.id', 'User.name'],
  } as const satisfies DeeplyReadonly<Query>;

  return {
    query,
    transformResult: (resultSet) => {
      const data = tidyRawData<typeof query>(resultSet);
      const tableData = d3.rollup(
        data,
        (rows): Partial<TableRow> => {
          const userName = {
            cellValue: rows[0]['User.name'],
            to: rows[0]['User.id'],
          };
          return {
            userName,
            ...getClaimsWorkedData(rows, 'ClaimsWorkedByUser'),
          };
        },
        (row) => row['User.id'],
      );
      return { tableData, tableTotals: {} };
    },
  };
}

function useClaimsWorkedTotalsDataSliceOpts(): CubeDataSlice<
  typeof query,
  TableSliceData
> {
  const cube = useClaimsWorkedCube();
  const query = {
    ...useCubeFilter(cube),
    measures: [
      `${cube}.adjustedAmountExpectedSum`,
      `${cube}.amountPaidSum`,
      `${cube}.claimClosedCount`,
      `${cube}.claimCount`,
      `${cube}.daysToClose`,
      `${cube}.deletedAdjustedAmountExpectedSum`,
    ],
    dimensions: [`${cube}.closed`],
  } as const satisfies DeeplyReadonly<Query>;

  return {
    query,
    transformResult: (resultSet) => {
      const data = tidyRawData<typeof query>(resultSet);
      const tableTotals: Partial<TotalsRow> = getClaimsWorkedData(data, cube);

      return { tableData: new d3.InternMap(), tableTotals };
    },
  };
}

function useGetExpandedData(): GetExpandedData<
  keyof typeof columnLabelMap,
  'userName'
> {
  const { cubejsApi } = useContext(CubeContext);

  const workDonePerFacilityFactory = useWorkDonePerFacility();
  const claimsWorkedPerFacilityFactory = useClaimsWorkedPerFacility();

  return useCallback(
    async (filterValue: ExpandRowFilterValue): Promise<TableExpandedRow[]> => {
      const {
        query: workDonePerFacilityQuery,
        transformResult: transformWorkDonePerFacility,
      } = workDonePerFacilityFactory(filterValue);

      const {
        query: claimsWorkedPerFacilityQuery,
        transformResult: transformClaimsWorkedPerFacility,
      } = claimsWorkedPerFacilityFactory(filterValue);

      const [workDoneData, claimsWorkedData] = await Promise.all([
        cubejsApi
          .load(workDonePerFacilityQuery)
          .then((result) => transformWorkDonePerFacility(result)),
        cubejsApi
          .load(claimsWorkedPerFacilityQuery)
          .then((result) => transformClaimsWorkedPerFacility(result)),
      ]);

      const data = Array.from(workDoneData.entries()).map(
        ([facilityID, workDoneRow]) => ({
          ...workDoneRow,
          ...(claimsWorkedData.get(facilityID) ?? {
            claimsWorked: null,
            claimsWorkedOpen: null,
            closeRateWorked: null,
            workToClose: null,
            dollarsPostedWorked: null,
            claimYieldWorked: null,
          }),
          [DATA_TABLE_KEY]: facilityID,
        }),
      );

      return data;
    },
    [claimsWorkedPerFacilityFactory, cubejsApi, workDonePerFacilityFactory],
  );
}

function useWorkDonePerFacility() {
  const { startDate, endDate } = useGlobalFilters().workedMonth.range;
  const daysInRange = differenceInDays(min([endDate, new Date()]), startDate);
  const { data: facilities } = useQuery(useFacilityFilterOptionsQueryOpts());

  const baseQuery = useCubeFilter('WorkDoneByUser', {
    useTimeDimensionsInsteadOfSegments: true,
  });
  const getQuery = useCallback(
    (filter: string) =>
      ({
        ...baseQuery,
        measures: ['WorkDoneByUser.claimCount', 'WorkDoneByUser.lastWorkDate'],
        dimensions: ['WorkDoneByUser.workTypeGroup', 'Facility.id'],
        filters: [
          ...baseQuery.filters,
          {
            member: 'WorkDoneByUser.workTypeGroup',
            operator: 'equals',
            values: ['CLAIM_WORKED', 'VIEW_ONLY'],
          },
          {
            member: 'User.id',
            operator: 'equals',
            values: [filter],
          },
        ],
      } as const satisfies DeeplyReadonly<Query>),
    [baseQuery],
  );

  const transformResult = useCallback(
    (resultSet: ResultSet<QueryRecordType<ReturnType<typeof getQuery>>>) => {
      // No tidyRawData here because WorkDoneByUser.lastWorkDate is a string
      const data = resultSet.rawData();
      return d3.rollup(
        data,
        (rows): WorkDoneExpandedData => {
          const facilityID = String(rows[0]['Facility.id']);
          const facilityName =
            facilities?.find((f) => f.id.toString() === facilityID)?.name ??
            facilityID;
          const touchEvents = d3.sum(rows, (row) =>
            Number(row['WorkDoneByUser.claimCount']),
          );
          const workEvents = d3.sum(
            rows.filter(
              (row) => row['WorkDoneByUser.workTypeGroup'] === 'CLAIM_WORKED',
            ),
            (row) => Number(row['WorkDoneByUser.claimCount']),
          );
          const viewEvents = d3.sum(
            rows.filter(
              (row) => row['WorkDoneByUser.workTypeGroup'] === 'VIEW_ONLY',
            ),
            (row) => Number(row['WorkDoneByUser.claimCount']),
          );
          const lastWorkDate = formatDateField(
            max(
              rows
                .map((row) =>
                  parseISO(String(row['WorkDoneByUser.lastWorkDate'])),
                )
                .filter(isValid),
            ),
          );
          const workEventsPerDay = workEvents / daysInRange;

          return {
            key: facilityID,
            facilityName,
            touchEvents,
            workEvents,
            viewEvents,
            lastWorkDate,
            workEventsPerDay,
          };
        },
        (row) => String(row['Facility.id']),
      );
    },
    [daysInRange, facilities],
  );
  return useCallback(
    (filterValue: ExpandRowFilterValue) => ({
      query: getQuery(filterValue.toString()),
      transformResult,
    }),
    [getQuery, transformResult],
  );
}

function useClaimsWorkedPerFacility() {
  const baseQuery = useCubeFilter('ClaimsWorkedByUser');
  const getQuery = useCallback(
    (filter: string) =>
      ({
        ...baseQuery,
        measures: [
          'ClaimsWorkedByUser.adjustedAmountExpectedSum',
          'ClaimsWorkedByUser.amountPaidSum',
          'ClaimsWorkedByUser.claimClosedCount',
          'ClaimsWorkedByUser.claimCount',
          'ClaimsWorkedByUser.daysToClose',
          'ClaimsWorkedByUser.deletedAdjustedAmountExpectedSum',
        ],
        dimensions: ['ClaimsWorkedByUser.closed', 'Facility.id'],
        filters: [
          ...baseQuery.filters,
          {
            member: 'User.id',
            operator: 'equals',
            values: [filter],
          },
        ],
      } as const satisfies DeeplyReadonly<Query>),
    [baseQuery],
  );

  const transformResult = useCallback(
    (resultSet: ResultSet<QueryRecordType<ReturnType<typeof getQuery>>>) => {
      const data = tidyRawData<ReturnType<typeof getQuery>>(resultSet);
      return d3.rollup(
        data,
        (rows): ClaimsWorkedExpandedData =>
          getClaimsWorkedData(rows, 'ClaimsWorkedByUser'),
        (row) => row['Facility.id'],
      );
    },
    [],
  );
  return useCallback(
    (filterValue: ExpandRowFilterValue) => ({
      query: getQuery(filterValue.toString()),
      transformResult,
    }),
    [getQuery, transformResult],
  );
}
