import { Outlet } from 'react-router-dom';
import { ChartMetricSwitcher } from '../../components';
import { ProductivityKPIRow } from '../../reports';
import variables from '../../styles/variables.module.scss';
import { RouteObject } from '../types';
import ProductivityByOffice, {
  childRoutes as productivityByOfficeChildRoutes,
} from './by-office';
import ProductivityByUser, {
  childRoutes as byUserChildRoutes,
} from './by-user';
import ProductivitySummary from './ProductivitySummary';

export const childRoutes: RouteObject[] = [
  {
    index: true,
    element: <ProductivitySummary />,
    handle: {
      title: ['Summary'],
      mainActions: (
        <ChartMetricSwitcher countColor={variables.paletteWorkDark} />
      ),
    },
  },
  {
    path: 'by-office',
    children: productivityByOfficeChildRoutes,
    element: <ProductivityByOffice />,
    handle: {
      crumb: 'By Office',
      skipBreadcrumbs: true,
    },
  },
  {
    path: 'by-user',
    element: <ProductivityByUser />,
    handle: {
      crumb: 'By User',
      skipBreadcrumbs: true,
    },
    children: byUserChildRoutes,
  },
];

export default function ProductivityReport() {
  return (
    <>
      <ProductivityKPIRow />
      <Outlet />
    </>
  );
}
