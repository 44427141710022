import { BASE_DEFAULT_WORKED_MONTH } from '../constants';
import makeDosMonthOptions from './makeDosMonthOptions';

export default function makeWorkedMonthOptions(length: number) {
  const monthOptions = makeDosMonthOptions(length);

  const minStart = monthOptions
    .map((option) => option.range.startDate)
    .reduce((a, b) => (a < b ? a : b));
  const maxEnd = monthOptions
    .map((option) => option.range.endDate)
    .reduce((a, b) => (a > b ? a : b));

  const defaultWorkedMonth = {
    ...BASE_DEFAULT_WORKED_MONTH,
    range: {
      startDate: minStart,
      endDate: maxEnd,
    },
  };
  return [defaultWorkedMonth, ...monthOptions];
}
