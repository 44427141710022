import { TwoToneHeader } from '@insidedesk/tuxedo';
import { Card, useTheme } from '@mui/material';
import { ChartErrorIcon } from '../assets';

export default function LoadDataErrorMessage() {
  const theme = useTheme();
  return (
    <Card
      variant='outlined'
      sx={{ p: 3, display: 'grid', placeItems: 'center', gap: 1 }}
    >
      <ChartErrorIcon
        width={32}
        height={32}
        color={theme.palette.primary.main}
      />
      <TwoToneHeader
        component='span'
        text={['Oops, ', 'we are a little busy right now']}
        fontSize={22}
        fontWeight={600}
      />
    </Card>
  );
}
