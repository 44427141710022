/**
 * Composed chart allows displaying multiple data sets as multiple bars and lines.
 */
import { TimeDimensionGranularity } from '@cubejs-client/core';
import Highcharts from 'highcharts';
import { TICK_INTERVAL } from '../constants';
import { convertSeries, formatUnits } from '../utils';
import Chart from './Chart';

export interface ComposedChartProps extends ReportOptions {
  error?: boolean;
  stack?: 'normal' | 'percent';
  leftAxis?: Highcharts.YAxisOptions;
  rightAxis?: Highcharts.YAxisOptions;
  bars?: ChartDataSeries<Highcharts.SeriesColumnOptions>[];
  lines?: ChartDataSeries<
    Highcharts.SeriesLineOptions | Highcharts.SeriesSplineOptions
  >[];
  barLabels?: boolean;
  lineLabels?: boolean;
  timeGranularity?: TimeDimensionGranularity;
  xAxis?: Highcharts.XAxisOptions;
}

function ComposedChart(props: ComposedChartProps) {
  const {
    barLabels = true,
    bars = [],
    error = false,
    height,
    leftAxis,
    lineLabels = false,
    lines = [],
    rightAxis,
    stack,
    title,
    timeGranularity,
    xAxis,
  } = props;

  const allData = [...bars, ...lines];

  let leftAxisUnit: DisplayUnit | undefined;
  allData.forEach((config) => {
    if ((config.yAxis?.toString() ?? '0') === '0' && config.displayUnit) {
      if (leftAxisUnit === undefined) {
        leftAxisUnit = config.displayUnit;
        return;
      }
      if (leftAxisUnit !== config.displayUnit) {
        leftAxisUnit = undefined;
      }
    }
  });
  let rightAxisUnit: DisplayUnit | undefined;
  allData.forEach((config) => {
    if (config.yAxis?.toString() === '1' && config.displayUnit) {
      if (rightAxisUnit === undefined) {
        rightAxisUnit = config.displayUnit;
        return;
      }
      if (rightAxisUnit !== config.displayUnit) {
        rightAxisUnit = undefined;
      }
    }
  });

  let dateFormatLong: string | undefined;
  let dateFormatShort: string | undefined;
  if (xAxis?.type === 'datetime' && timeGranularity) {
    switch (timeGranularity) {
      case 'day':
        // 2022 Jun 01, Wed
        dateFormatLong = '%Y %b %d, %a';
        // Jun\n01
        dateFormatShort = '%b<br>%d';
        break;
      case 'month':
        // 2022 June
        dateFormatLong = '%Y %B';
        // 2022\nJun
        dateFormatShort = '%Y<br>%b';
        break;
      case 'year':
        // 2022
        dateFormatLong = '%Y';
        dateFormatShort = '%Y';
        break;
      default:
        throw new Error(`Unsupported timeGranularity ${timeGranularity}`);
    }
  }

  const options: Highcharts.Options = {
    chart: { height },
    legend: {
      verticalAlign: 'top',
    },
    plotOptions: {
      column: {
        stacking: stack,
        dataLabels: {
          enabled: barLabels,
        },
      },
      line: {
        dataLabels: {
          enabled: lineLabels,
        },
      },
    },
    series: [...bars, ...lines].map(convertSeries),
    title: {
      text: title,
    },
    tooltip: {
      shared: true,
      xDateFormat: dateFormatLong,
    },
    xAxis: {
      ...(xAxis?.type === 'datetime' && {
        labels: { format: dateFormatShort && `{value:${dateFormatShort}}` },
        tickInterval: TICK_INTERVAL[timeGranularity ?? 'month'],
      }),
      ...xAxis,
    },
    yAxis: [
      {
        title: { text: '' },
        labels: {
          format: leftAxisUnit
            ? formatUnits('{value:.1f}', leftAxisUnit)
            : undefined,
          ...leftAxis?.labels,
        },
        ...leftAxis,
        id: '0',
      },
      {
        title: { text: '' },
        labels: {
          format: rightAxisUnit
            ? formatUnits('{value:.1f}', rightAxisUnit)
            : undefined,
          ...rightAxis?.labels,
        },
        ...rightAxis,
        id: '1',
        opposite: true,
      },
    ],
  };
  return <Chart options={options} />;
}
export default ComposedChart;
