import React from 'react';
import { v4 as uuid } from 'uuid';

export const LoadingContext = React.createContext<{
  loading: boolean;
  getLoadingSetter: () => (v: boolean) => void;
}>({
  loading: false,
  getLoadingSetter: () => () => {},
});

interface State {
  loading: boolean;
  indicators: Map<string, boolean>;
}

type Action = { type: 'set'; id: string; payload: boolean };

function loadingReducer(state: State, action: Action) {
  switch (action.type) {
    case 'set': {
      state.indicators.set(action.id, action.payload);

      const values = Array.from(state.indicators.values());
      if (!values.includes(true)) {
        return {
          loading: false,
          indicators: new Map<string, boolean>(),
        };
      }

      return { ...state, loading: true };
    }

    default:
      return state;
  }
}

export function LoadingContextProvider({
  children,
}: {
  children?: React.ReactNode;
}) {
  const [state, dispatch] = React.useReducer(loadingReducer, {
    loading: false,
    indicators: new Map<string, boolean>(),
  });

  const getLoadingSetter = React.useCallback(() => {
    const id = uuid();
    return (value: boolean) => {
      dispatch({ type: 'set', id, payload: value });
    };
  }, []);

  const defaultValue = React.useMemo(
    () => ({ loading: state.loading, getLoadingSetter }),
    [getLoadingSetter, state.loading],
  );

  return (
    <LoadingContext.Provider value={defaultValue}>
      {children}
    </LoadingContext.Provider>
  );
}
