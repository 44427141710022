import { RouteGridContainer } from '../../components';
import { useClaimsWorkedCube, useCubeFilter } from '../../hooks';
import {
  DimensionBreakdownChart,
  TouchEventsTrendChart,
  WorkEventsBreakdownChart,
  WorkEventsTrendChart,
} from '../../reports';

export type WorkEventsTrendChartProps = {
  chartMetric: ChartMetric;
};

export default function ProductivitySummary() {
  return (
    <RouteGridContainer
      data-testid='productivity-summary'
      gridTemplateColumns='repeat(3, 1fr)'
    >
      <TouchEventsTrendChart />
      <WorkEventsBreakdownChart
        dimensionSpec={{
          label: 'Age Bucket',
          metric: 'WorkDoneByUser.workedArAgeGroup',
        }}
      />
      <WorkEventsBreakdownChart
        dimensionSpec={{
          label: 'Responses',
          metric: 'WorkDoneByUser.claimResponseType',
        }}
        sortByValue
      />
      <WorkEventsTrendChart />
      <WorkEventsBreakdownChart
        dimensionSpec={{
          label: '$ Expected',
          metric: 'WorkDoneByUser.expectedValueGroup',
        }}
      />
      <ClaimsByWorkEventsPerClaimChart />
    </RouteGridContainer>
  );
}

function ClaimsByWorkEventsPerClaimChart() {
  const cube = useClaimsWorkedCube();
  const baseQuery = useCubeFilter(cube);
  return (
    <DimensionBreakdownChart
      barSize={25}
      baseQuery={baseQuery}
      chartMetric='count'
      dimensionSpec={{
        label: 'Work Events / Claim',
        metric: `${cube}.workCountGroup`,
      }}
      measureSpec={{
        count: {
          label: 'Claims Worked',
          metric: `${cube}.claimCount`,
        },
      }}
    />
  );
}
