import {
  DeeplyReadonly,
  Query,
  QueryRecordType,
  ResultSet,
} from '@cubejs-client/core';
import { ReactNode } from 'react';
import { useLoader } from '../../hooks';

type QueryResultSet<T> = T extends DeeplyReadonly<Query>
  ? ResultSet<QueryRecordType<T>>
  : never;

type TupleQueriesResultSets<T extends readonly unknown[]> = T extends readonly [
  infer TQuery,
  ...infer Tail,
]
  ? [QueryResultSet<TQuery>, ...TupleQueriesResultSets<[...Tail]>]
  : [];

type ArrayQueriesResultSets<T extends readonly unknown[]> =
  T extends (infer TQuery)[] ? QueryResultSet<TQuery>[] : [];

export type QueriesResultSets<T extends readonly unknown[]> =
  IsTuple<T> extends true
    ? TupleQueriesResultSets<T>
    : ArrayQueriesResultSets<T>;

interface QueriesRendererProps<T extends DeeplyReadonly<Query[]>, TData> {
  queries: T;
  transformResult: (resultSets: QueriesResultSets<T> | null) => TData;
  render: (data: TData) => ReactNode;
  fallback?: ReactNode;
}
export default function QueriesRenderer<
  T extends DeeplyReadonly<Query[]>,
  TData,
>(props: QueriesRendererProps<T, TData>) {
  const { queries, transformResult, render, fallback } = props;

  const { data, error } = useLoader({
    loader: async ({ fetchCubeQuery }) => {
      const queriesData = (await Promise.all(
        queries.map((query) => fetchCubeQuery(query)),
      )) as QueriesResultSets<T>;
      return transformResult(queriesData);
    },
    default: () => transformResult(null),
    loaderKey: queries,
  });

  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (error) return <>{fallback}</>;
  return <>{render(data)}</>;
}
