import { HTTPError } from '@insidedesk/tuxedo';

// eslint-disable-next-line import/prefer-default-export
export function noticeError(error: unknown) {
  if (typeof newrelic !== 'object') return;

  const response = error instanceof HTTPError ? error.response : null;
  const resource = error instanceof HTTPError ? error.resource : null;

  newrelic.noticeError(error, {
    fetchResource: resource,
    fetchStatus: response?.status,
  });
}
