import * as d3 from 'd3';
import { KPIRow } from '../components';
import { CubeFilters, useCubeQuery } from '../hooks';
import { tidyRawData } from '../utils';

export default function DenialsKPIRow(props: { baseQuery: CubeFilters }) {
  const { baseQuery } = props;
  const query = {
    ...baseQuery,
    measures: [
      'AdjudicatedClaims.totalExpected',
      'Claim.count',
      'AdjudicatedClaims.deniedBenefits',
    ],
    dimensions: [
      'AdjudicatedClaims.denialStatus',
      'Claim.workDone',
      'Claim.resolved',
    ],
    filters: [
      ...baseQuery.filters,
      {
        member: 'AdjudicatedClaims.denialStatus',
        operator: 'notEquals',
        values: ['unknown'],
      },
    ],
  } as const;

  const { resultSet, error } = useCubeQuery(query);
  let dollarsDenied = NaN;
  let countDenied = NaN;
  let countFullDenied = NaN;
  let denialRate = NaN;
  let deniedWorked = NaN;

  if (resultSet) {
    const data = tidyRawData<typeof query>(resultSet);
    if (data.length > 0) {
      const denied = data.filter(
        (row) =>
          row['AdjudicatedClaims.denialStatus'] === 'full' ||
          row['AdjudicatedClaims.denialStatus'] === 'partial',
      );
      const deniedFull = denied.filter(
        (row) => row['AdjudicatedClaims.denialStatus'] === 'full',
      );

      dollarsDenied = d3.sum(
        denied,
        (row) => row['AdjudicatedClaims.deniedBenefits'],
      );
      countDenied = d3.sum(denied, (row) => row['Claim.count']);
      countFullDenied = d3.sum(deniedFull, (row) => row['Claim.count']);

      denialRate =
        (100 *
          d3.sum(denied, (row) => row['AdjudicatedClaims.deniedBenefits'])) /
        d3.sum(data, (row) => row['AdjudicatedClaims.totalExpected']);

      deniedWorked = d3.sum(
        denied.filter((row) => row['Claim.workDone'] === 'true'),
        (row) => row['Claim.count'],
      );
    }
  }

  const kpis: KPIPoint[] = [
    { name: '$ Denied Benefits', value: dollarsDenied, type: 'currency' },
    { name: '# Denied Claims', value: countDenied },
    { name: '# Full Denied Claims', value: countFullDenied },
    { name: 'Denial Rate', value: denialRate, type: 'percent' },
    { name: '# Denied Claims: Worked', value: deniedWorked },
  ];

  return (
    <KPIRow
      kpis={kpis.map((kpi) => ({ main: { ...kpi, error: Boolean(error) } }))}
    />
  );
}
