import numbro from 'numbro';

export default function formatThousands(
  value: number,
  format: Omit<numbro.Format, 'thousandSeparated'> = {},
) {
  const num = numbro(value);
  return num.format({
    thousandSeparated: true,
    mantissa: 0,
    ...format,
  });
}
