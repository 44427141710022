import {
  isNotNullish,
  MultiSelectOption,
  SingleSelectOption,
} from '@insidedesk/tuxedo';
import { createSearchParams } from 'react-router-dom';

export function getMatchingOptions<
  TValue extends string | number,
  TOption extends { id: TValue },
>(searchParams: URLSearchParams, key: string, options: TOption[]) {
  const ids = searchParams.getAll(key);
  return ids
    .map((id) => options.find((option) => option.id.toString() === id))
    .filter(isNotNullish);
}

export function getMatchingId<T extends string | number>(
  searchParams: URLSearchParams,
  key: string,
  options: { id: T }[],
) {
  const id = searchParams.get(key);
  return options.find((option) => option.id.toString() === id)?.id ?? null;
}

export function getMatchingOption<
  TValue extends string | number,
  TOption extends { id: TValue },
>(searchParams: URLSearchParams, key: string, options: TOption[]) {
  const id = searchParams.get(key);
  return options.find((option) => option.id.toString() === id) ?? null;
}

export function toSearchParamsInit(
  obj: Partial<
    Record<
      string,
      SingleSelectOption<string | number> | MultiSelectOption<string | number>[]
    >
  >,
): Record<string, string | string[]> {
  return Object.fromEntries(
    Object.entries(obj)
      .filter(([, value]) => isNotNullish(value))
      .map(([name, optionOrOptions]) => {
        if (Array.isArray(optionOrOptions)) {
          return [name, optionOrOptions.map((option) => option.id.toString())];
        }
        return [name, optionOrOptions?.id.toString()];
      }),
  );
}

export function produceSearchParams(init: Record<string, string | string[]>) {
  return (prevParams: URLSearchParams) => {
    const nextInit = { ...init };
    Array.from(prevParams.keys()).forEach((name) => {
      if (!(name in nextInit)) {
        nextInit[name] = prevParams.getAll(name);
      }
    });
    return createSearchParams(nextInit);
  };
}
