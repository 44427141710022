import { useCallback, useContext, useEffect } from 'react';
import { LoadingContext } from '../context';

export default function useTrackLoading(value: boolean) {
  const { getLoadingSetter } = useContext(LoadingContext);
  const setLoading = useCallback(getLoadingSetter(), []);

  useEffect(() => {
    setLoading(value);
    return () => {
      setLoading(false);
    };
  }, [setLoading, value]);
}
