import { endOfMonth, format, startOfMonth, sub } from 'date-fns';

export default function makeDosMonthOptions(length: number) {
  const now = new Date();
  return Array.from({ length }, (_, i) => {
    const date = sub(now, { months: i });
    const start = startOfMonth(date);
    const end = endOfMonth(date);

    return {
      id: i,
      label: format(start, 'MMMM yyyy'),
      range: { startDate: start, endDate: end },
    };
  });
}
