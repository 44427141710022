import { Button, Stack } from '@mui/material';
import { PropsWithChildren } from 'react';
import { useChartMetric } from '../hooks';
import variables from '../styles/variables.module.scss';

type ChartMetricSwitcherProps = {
  dollarColor?: string;
  countColor?: string;
};
export default function ChartMetricSwitcher(props: ChartMetricSwitcherProps) {
  const {
    dollarColor = variables.paletteDollarDark,
    countColor = variables.paletteClaimDark,
  } = props;
  const { chartMetric, setChartMetric } = useChartMetric();

  return (
    <Stack direction='row' justifyContent='end' spacing={0.5}>
      <MetricSwitcherButton
        onClick={() => setChartMetric('dollar')}
        active={chartMetric === 'dollar'}
        color={dollarColor}
      >
        $
      </MetricSwitcherButton>
      <MetricSwitcherButton
        onClick={() => setChartMetric('count')}
        active={chartMetric === 'count'}
        color={countColor}
      >
        #
      </MetricSwitcherButton>
    </Stack>
  );
}

function MetricSwitcherButton(
  props: PropsWithChildren<{
    active: boolean;
    color: string;
    onClick: () => void;
  }>,
) {
  const { active, color, onClick, children } = props;
  return (
    <Button
      variant='tab'
      sx={[
        active && {
          background: color,
          color: 'white',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          '&:hover': { background: color },
        },
      ]}
      onClick={onClick}
    >
      {children}
    </Button>
  );
}
