import { useDeferredValue, useEffect, useReducer, useState } from 'react';
import { ColumnLabelMap, SortAction, SortState } from './types';
import { formatDescription } from './utils';

function sortReducer<TColumn extends string>(
  state: SortState<TColumn>,
  action: SortAction<TColumn>,
): SortState<TColumn> {
  switch (action.type) {
    case 'setActive':
      return {
        ...Object.fromEntries(
          Object.entries(state).map(([k, v]) => [k, { ...v, active: false }]),
        ),
        [action.column]: {
          ...state[action.column],
          active: true,
        },
      };
    case 'changeDirection':
      return {
        ...state,
        [action.column]: {
          ...state[action.column],
          direction: state[action.column].direction === 'desc' ? 'asc' : 'desc',
        },
      };
    default:
      return state;
  }
}

// eslint-disable-next-line import/prefer-default-export
export function useTableSort<TColumn extends string>(
  columnLabelMap: ColumnLabelMap<TColumn>,
  defaultSortColumn?: string,
) {
  if (
    defaultSortColumn !== undefined &&
    !(defaultSortColumn in columnLabelMap)
  ) {
    throw new Error(
      'Default sort column must be a key of the column label map.',
    );
  }

  const [sortState, dispatch] = useReducer(
    sortReducer<TColumn>,
    Object.fromEntries(
      Object.entries(columnLabelMap).map(([column]) => {
        const { defaultSortOrder } = formatDescription(columnLabelMap[column]);
        return [
          column,
          {
            active:
              (defaultSortColumn ?? Object.keys(columnLabelMap)[0]) === column,
            direction: defaultSortOrder ?? 'desc',
          },
        ];
      }),
    ),
  );
  return {
    sortState,
    setActiveSort: (column: TColumn) => dispatch({ type: 'setActive', column }),
    changeSortDirection: (column: TColumn) =>
      dispatch({ type: 'changeDirection', column }),
  };
}

export function useDeferredOrDefault<T, TDefault>(
  value: T,
  default_: TDefault,
) {
  const [prevValue, setPrevValue] = useState<T | TDefault>(default_);
  const deferredValue = useDeferredValue(prevValue);
  useEffect(() => {
    setPrevValue(value);
  }, [value]);
  return deferredValue;
}
