import { useClientAPIQueryFn } from '@insidedesk/tuxedo';
import { minutesToMilliseconds } from 'date-fns';

export default function useFacilityFilterOptionsQueryOpts() {
  return {
    queryKey: ['facility-filter-options'],
    queryFn: useClientAPIQueryFn<Facility[]>('claims/facility-filter-options'),
    staleTime: minutesToMilliseconds(5),
  };
}
