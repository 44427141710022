import {
  DeeplyReadonly,
  Query,
  QueryRecordType,
  ResultSet,
} from '@cubejs-client/core';
import { CubeContextProps } from '@cubejs-client/react';
import { UseQueryOptions } from '@tanstack/react-query';

export default function getCubeQueryOpts<
  TQuery extends DeeplyReadonly<Query | Query[]>,
  TData = ResultSet<QueryRecordType<TQuery>>,
>(
  query: TQuery,
  context: CubeContextProps,
  select?: (resultSet: ResultSet<QueryRecordType<TQuery>>) => TData,
) {
  const { cubejsApi } = context;
  return {
    queryKey: [JSON.stringify(query)],
    queryFn: () => cubejsApi.load(query).then(select),
    refetchOnMount: true,
  } as const satisfies UseQueryOptions;
}
