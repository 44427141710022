import { Box, BoxProps } from '@mui/material';
import { CHART_MIN_HEIGHT } from '../constants';

export default function RouteGridContainer(props: BoxProps) {
  const { children, ...rest } = props;
  return (
    <Box
      display='grid'
      gridAutoRows={`minmax(${CHART_MIN_HEIGHT}px, 1fr)`}
      overflow='scroll'
      gap={3}
      p={2}
      height='100%'
      {...rest}
    >
      {children}
    </Box>
  );
}
