import {
  DeeplyReadonly,
  Query,
  QueryRecordType,
  ResultSet,
} from '@cubejs-client/core';
import { useCallback } from 'react';
import {
  ColumnLabelMap,
  DataTable,
  DATA_TABLE_KEY,
  LoadDataErrorCard,
  QueryRenderer,
} from '../components';
import { useReportOutletContext } from '../routes/hooks';
import { tidyRawData } from '../utils';

const columnLabelMap = {
  responsibility: {
    label: 'Responsibility',
    alignment: { header: 'left', body: 'left' },
    defaultSortOrder: 'asc',
    phi: true,
  },
  state: {
    label: 'Claim Status',
    alignment: { header: 'left', body: 'left' },
    showTotal: false,
  },
  description: {
    label: 'Description',
    alignment: { header: 'left', body: 'left' },
    showTotal: false,
  },
  count: 'Claims (#)',
  outstanding: { label: 'Outstanding ($)', unit: '$' },
} satisfies ColumnLabelMap;

type WorkflowTableProps = {
  responsibilityMap: Record<string, string>;
};

export default function WorkflowTable(props: WorkflowTableProps) {
  const { responsibilityMap } = props;
  const { baseQuery } = useReportOutletContext();
  const workflowQuery = {
    ...baseQuery,
    measures: [
      'ClaimARSnapshot.currentAmountExpectedSum',
      'ClaimARSnapshot.count',
    ],
    dimensions: [
      'WorkflowState.responsibility',
      'WorkflowState.state',
      'WorkflowState.description',
    ],
  } as const satisfies DeeplyReadonly<Query>;

  const transformResult = useCallback(
    (results: ResultSet<QueryRecordType<Query>> | null) => {
      const tidyData = results
        ? tidyRawData<typeof workflowQuery>(results)
        : [];

      return tidyData.map((row) => ({
        [DATA_TABLE_KEY]: JSON.stringify(row),
        responsibility:
          responsibilityMap[row['WorkflowState.responsibility'] ?? ''],
        state:
          row['WorkflowState.state'] === 'null'
            ? null
            : row['WorkflowState.state'],
        description: row['WorkflowState.description'],
        count: row['ClaimARSnapshot.count'],
        outstanding: row['ClaimARSnapshot.currentAmountExpectedSum'],
      }));
    },
    [responsibilityMap],
  );

  return (
    <QueryRenderer
      query={workflowQuery}
      transformResult={transformResult}
      render={(data) => (
        <DataTable
          columnLabelMap={columnLabelMap}
          data={data}
          defaultSortColumn='responsibility'
        />
      )}
      fallback={<LoadDataErrorCard />}
    />
  );
}
