import { ReactNode, useMemo } from 'react';
import useTitleFragments from './useTitleFragments';

export default function useTitle(): ReactNode | ReactNode[] {
  const fragments = useTitleFragments('InsideIQ');

  const title = useMemo(
    () =>
      fragments
        .map((fragment) =>
          fragment.filter((node) => typeof node === 'string').join(''),
        )
        .filter((fragment) => fragment.length > 0)
        .join(' - '),
    [fragments],
  );

  return title;
}
