import { DeeplyReadonly, Query, ResultSet } from '@cubejs-client/core';
import * as d3 from 'd3';
import { PointOptionsObject, SeriesBarOptions } from 'highcharts';
import {
  HorizontalBarChart,
  LoadDataErrorCard,
  QueryRenderer,
} from '../components';
import { CubeFilters } from '../hooks';
import { tidyRawData } from '../utils';

type NumericPoint = { name: string; y: number };
type AxisSpec = { label: string; metric: string };

export default function DimensionBreakdownChart<
  TChartMetric extends ChartMetric,
>(props: {
  barSize: number;
  baseQuery: CubeFilters;
  chartMetric: TChartMetric;
  dimensionSpec: AxisSpec;
  measureSpec: Record<TChartMetric, AxisSpec>;
  unitSpec?: Record<TChartMetric, DisplayUnit>;
  sortByValue?: boolean;
}) {
  const {
    barSize,
    baseQuery,
    chartMetric,
    dimensionSpec,
    unitSpec,
    measureSpec,
    sortByValue = false,
  } = props;

  const query = {
    ...baseQuery,
    measures: Object.values<AxisSpec>(measureSpec).map((spec) => spec.metric),
    dimensions: [dimensionSpec.metric],
  } as const satisfies DeeplyReadonly<Query>;

  const transformResult = (result: ResultSet | null) => {
    const data = result ? tidyRawData<typeof query>(result) : [];
    const byDimension = d3.group(data, (row) => row[dimensionSpec.metric]);

    const sortComparator = sortByValue
      ? (a: NumericPoint, b: NumericPoint) => d3.descending(a.y, b.y)
      : (a: NumericPoint, b: NumericPoint) => naturalCompare(a.name, b.name);
    const pointsByDimension: PointOptionsObject[] = d3
      .map(byDimension.entries(), ([name, rows]) => ({
        name,
        y: d3.sum(rows, (row) => row[measureSpec[chartMetric].metric]),
      }))
      .sort(sortComparator);
    const defaultUnit = chartMetric === 'dollar' ? '$' : undefined;
    const seriesByDimension: ChartDataSeries<SeriesBarOptions> = {
      data: pointsByDimension,
      type: 'bar',
      displayUnit: unitSpec?.[chartMetric] ?? defaultUnit,
    };
    const prefix = getChartMetricPrefix(chartMetric);
    return {
      data: [
        {
          ...seriesByDimension,
          name: `${prefix} ${measureSpec[chartMetric].label}`,
        },
      ],
      title: `${prefix} ${measureSpec[chartMetric].label} By ${dimensionSpec.label}`,
    };
  };

  return (
    <QueryRenderer
      query={query}
      queryKey={[chartMetric]}
      transformResult={transformResult}
      render={(chartProps) => (
        <HorizontalBarChart barSize={barSize} {...chartProps} />
      )}
      fallback={<LoadDataErrorCard />}
    />
  );
}

function naturalCompare(a: string, b: string) {
  return a.localeCompare(b, undefined, {
    numeric: true,
    sensitivity: 'base',
  });
}

function getChartMetricPrefix(chartMetric: ChartMetric) {
  switch (chartMetric) {
    case 'count':
      return '#';
    case 'dollar':
      return '$';
    default:
      throw new Error('No label for metric: ', chartMetric);
  }
}
