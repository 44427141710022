import {
  DeeplyReadonly,
  Query,
  QueryRecordType,
  ResultSet,
} from '@cubejs-client/core';
import { CubeContext } from '@cubejs-client/react';
import { useQueryClient } from '@tanstack/react-query';
import { minutesToMilliseconds } from 'date-fns';
import _ from 'lodash';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import invariant from 'tiny-invariant';
import { getCubeQueryOpts } from '../utils';
import { useSetLastUpdated } from './lastUpdated';
import useTrackLoading from './useTrackLoading';

export type FetchCubeQuery = <TQuery extends DeeplyReadonly<Query | Query[]>>(
  query: TQuery,
) => Promise<ResultSet<QueryRecordType<TQuery>>>;

type Loader<TData> = (params: {
  fetchCubeQuery: FetchCubeQuery;
}) => Promise<TData>;

type UseLoaderResult<TData> = {
  data: TData;
  isLoading: boolean;
  error: Error | null;
};

const DATA_DEFAULT = Symbol('DATA_DEFAULT');

type LoaderParams<TData> = {
  loader: Loader<TData>;
  default: () => TData;
  /** Modify `loaderKey` to revalidate the entire loader, including query fetches. */
  loaderKey: unknown;
  /** Modify `loaderSoftKey` to trigger a "soft" update which revalidate the
   * loader function but keeps prior query results.
   */
  loaderSoftKey?: unknown;
};

export default function useLoader<TData>(
  params: LoaderParams<TData>,
): UseLoaderResult<TData> {
  const { loader, default: default_, loaderKey, loaderSoftKey } = params;
  const fetchCubeQuery = useFetchCubeQuery(loaderKey);

  const [data, setData] = useState<TData | typeof DATA_DEFAULT>(DATA_DEFAULT);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useTrackLoading(isLoading);

  const [loaderByKey, defaultByKey] = useReactToKey(
    [loaderKey, loaderSoftKey],
    () => [loader, default_()],
  );

  useEffect(() => {
    setIsLoading(true);
    let cancelled = false;
    loaderByKey({ fetchCubeQuery })
      .then((value) => {
        if (!cancelled) {
          setData(value);
          setError(null);
        }
      })
      .catch((_error) => {
        if (!cancelled) {
          setError(_error);
        }
      })
      .finally(() => {
        if (!cancelled) {
          setIsLoading(false);
        }
      });
    return () => {
      cancelled = true;
      setIsLoading(false);
    };
  }, [fetchCubeQuery, loaderByKey]);

  return {
    data: data === DATA_DEFAULT ? defaultByKey : data,
    isLoading,
    error,
  };
}

function useFetchCubeQuery(loaderKey: unknown) {
  // XXX These two values work together to mimic the old query loading behavior.
  // Every react-query key is scoped under a unique "mount key" so that all
  // queries can be removed from the cache when the parent component unmounts.
  // `randomLoaderKey` adds a randomized component to the query key that changes
  // on every "hard" load, to ensure that queries only re-fetch on hard loads
  // but do not otherwise use cached results.
  const [mountKey] = useState(() => String(Math.random()));
  const randomLoaderKey = useReactToKey(loaderKey, () => String(Math.random()));

  // CubeProvider does not memoize the entire context so it is important to
  // destructure here.
  const { cubejsApi } = useContext(CubeContext);
  const queryClient = useQueryClient();
  const setLastUpdated = useSetLastUpdated();

  const fetchCubeQuery: FetchCubeQuery = useCallback(
    async (query) => {
      const queryOpts = getCubeQueryOpts(query, { cubejsApi });
      const resultSet = await queryClient.fetchQuery({
        ...queryOpts,
        queryKey: [mountKey, ...queryOpts.queryKey, randomLoaderKey],
        staleTime: minutesToMilliseconds(5),
      });
      setLastUpdated(resultSet);
      return resultSet;
    },
    [cubejsApi, queryClient, mountKey, randomLoaderKey, setLastUpdated],
  );

  useEffect(
    () => () => {
      queryClient.cancelQueries([mountKey]);
      queryClient.removeQueries([mountKey]);
    },
    [mountKey, queryClient],
  );

  return fetchCubeQuery;
}

const REACT_TO_KEY_UNSET = Symbol('REACT_TO_KEY_UNSET');

function useReactToKey<TKey, TVal>(key: TKey, getVal: () => TVal): TVal {
  // Don't initialize to `null` or `undefined` because `null` or `undefined`
  // keys are valid and shouldn't have special semantics associated with them.
  const keyRef = useRef<TKey | typeof REACT_TO_KEY_UNSET>(REACT_TO_KEY_UNSET);
  const valRef = useRef<TVal | typeof REACT_TO_KEY_UNSET>(REACT_TO_KEY_UNSET);

  useMemo(() => {
    if (!_.isEqual(keyRef.current, key)) {
      keyRef.current = key;
      valRef.current = getVal();
    }
  }, [key, getVal]);

  invariant(valRef.current !== REACT_TO_KEY_UNSET, 'valRef is not initialized');
  return valRef.current;
}
