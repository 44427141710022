import { format, isValid } from 'date-fns';

/**
 * Formats a timestamp as an isodate, suitable for formatting a table column.
 * XXX This should probably be moved into `DataTable`
 */
export default function formatDateField(value: string | null | number | Date) {
  if (value === null) return null;
  if (typeof value === 'number') return value;
  const valueAsDate = value instanceof Date ? value : new Date(value);
  if (!isValid(valueAsDate)) return null;
  return format(valueAsDate, 'yyyy-MM-dd');
}
