import { PHI } from '@insidedesk/tuxedo';
import { Link } from '@mui/material';
import { ReactNode } from 'react';
import { To } from 'react-router-dom';
import { formatThousands, formatUnits } from '../../utils';
import NavLinkWithQuery from '../NavLinkWithQuery';
import { ColumnLabelMapDescription, DataTableValue } from './types';
import { formatDescription } from './utils';

type CellValueProps = {
  value: DataTableValue | undefined;
  description: ColumnLabelMapDescription;
};
export default function CellValue(props: CellValueProps): JSX.Element {
  const { value, description } = props;

  const { unit, phi, maxValue = Infinity } = formatDescription(description);
  const { cellValue, href, to } = formatValue(value);
  let displayValue: ReactNode;

  if (
    typeof cellValue === 'number' &&
    Number.isFinite(Math.min(maxValue, cellValue))
  ) {
    displayValue = formatUnits(
      formatThousands(Math.min(maxValue, cellValue)),
      unit,
    );
    if (cellValue > maxValue) displayValue = `${displayValue}+`;
  } else if (typeof cellValue === 'number') {
    displayValue = '-';
  } else if (cellValue === null || cellValue === undefined) {
    displayValue = '-';
  } else if (typeof cellValue === 'string' && cellValue !== '') {
    displayValue = formatUnits(cellValue, unit);
  } else {
    displayValue = cellValue;
  }

  if (href !== undefined) {
    displayValue = (
      <Link href={href} target='_blank' rel='noreferrer'>
        {displayValue}
      </Link>
    );
  }
  if (to !== undefined) {
    displayValue = (
      <NavLinkWithQuery to={to} style={{ all: 'unset', cursor: 'pointer' }}>
        {displayValue}
      </NavLinkWithQuery>
    );
  }

  if (phi) {
    displayValue = <PHI>{displayValue}</PHI>;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{displayValue}</>;
}

function formatValue(value: DataTableValue | undefined): {
  cellValue: string | number | null | undefined;
  href?: string;
  to?: To;
} {
  if (typeof value === 'object' && value !== null) return value;
  return { cellValue: value };
}
