import { Outlet, RouteObject } from 'react-router-dom';
import ProductivityByOfficeTable from './ProductivityByOfficeTable';

export const childRoutes: RouteObject[] = [
  {
    index: true,
    element: <ProductivityByOfficeTable />,
    handle: {
      skipBreadcrumbs: true,
    },
  },
];

export default function ProductivityByOffice() {
  return <Outlet />;
}
