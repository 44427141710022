import { Query } from '@cubejs-client/core';
import { DimensionBreakdownChart } from '.';
import { CubeFilters, useChartMetric } from '../hooks';

export interface DenialsBreakdownChartProps {
  baseQuery: CubeFilters;
  dimensionSpec: { label: string; metric: string };
  sortByValue?: boolean;
}
export default function DenialsBreakdownChart(
  props: DenialsBreakdownChartProps,
) {
  const { baseQuery, dimensionSpec, sortByValue } = props;
  const { chartMetric } = useChartMetric();

  const query = {
    ...baseQuery,
    filters: [
      ...baseQuery.filters,
      {
        member: 'AdjudicatedClaims.denialStatus',
        operator: 'equals',
        values: ['full', 'partial'],
      },
    ],
  } satisfies Query;

  return (
    <DimensionBreakdownChart
      barSize={25}
      baseQuery={query}
      chartMetric={chartMetric}
      dimensionSpec={dimensionSpec}
      measureSpec={{
        count: { label: 'Denied Claims', metric: 'Claim.count' },
        dollar: {
          label: 'Denied Benefits',
          metric: 'AdjudicatedClaims.deniedBenefits',
        },
      }}
      sortByValue={sortByValue}
    />
  );
}
