import {
  Filter,
  Query,
  TimeDimension,
  TimeDimensionGranularity,
} from '@cubejs-client/core';
import {
  endOfDay,
  endOfMonth,
  formatISO,
  startOfDay,
  startOfMonth,
  sub,
} from 'date-fns';
import _ from 'lodash';
import { useMemo } from 'react';
import { useGlobalFilters } from '.';
import {
  BASE_DEFAULT_WORKED_MONTH,
  DATE_OF_SERVICE_FILTER_MONTHS,
} from '../constants';
import useActiveCubeFilters from './useActiveCubeFilters';

export interface UseCubeFilterOptions {
  // https://github.com/cube-js/cube.js/issues/5109
  // Pre-aggregations can't combine rolling window measures with segments, so
  // use this case to force useCubeFilter to use filters rather than segments
  // when applicable.
  useFiltersInsteadOfSegments?: boolean;
  useTimeDimensionsInsteadOfSegments?: boolean;
  hasGranularity?: boolean;
}
export type CubeFilters = Required<
  Pick<Query, 'filters' | 'segments' | 'timeDimensions'>
>;

/**
 * Generates filters from the CubeFilterContext.
 *
 * The cube, filter dimension, and filter type (Filter | Segment) can be
 * specified in order to customise the resulting filter set.
 */
export default function useCubeFilter(
  cube: string,
  options: UseCubeFilterOptions = {},
): CubeFilters {
  const {
    useFiltersInsteadOfSegments,
    useTimeDimensionsInsteadOfSegments,
    hasGranularity,
  } = options;
  const { overrideKeys, ...filterValues } = useGlobalFilters();
  const activeFilters = useActiveCubeFilters();

  return useMemo(() => {
    const filters: Filter[] = [];
    const segments: string[] = [];
    const timeDimensions: TimeDimension[] = [];

    if (_.intersection(activeFilters, overrideKeys).length > 0) {
      // eslint-disable-next-line no-console
      console.warn(
        'The following keys have been both activated and overridden, this is likely a mistake',
        _.intersection(activeFilters, overrideKeys),
      );
    }

    const activeOrOverride = [...activeFilters, ...overrideKeys];

    if (
      activeOrOverride.includes('adjudicationReasonCategories') &&
      filterValues.adjudicationReasonCategories.length > 0
    ) {
      filters.push({
        member: `${cube}.adjudicationReasonCategory`,
        operator: 'equals',
        values: filterValues.adjudicationReasonCategories.map(
          (option) => option.id,
        ),
      });
    }

    if (activeOrOverride.includes('arAges') && filterValues.arAges.length > 0) {
      filters.push({
        member: `${cube}.arAgeGroup`,
        operator: 'equals',
        values: filterValues.arAges.map((option) => option.id),
      });
    }

    if (
      activeOrOverride.includes('claimStatuses') &&
      filterValues.claimStatuses.length > 0
    ) {
      filters.push({
        member: `${cube}.resolved`,
        operator: 'equals',
        values: filterValues.claimStatuses.map((status) =>
          (status.id === 'closed').toString(),
        ),
      });
    }

    if (
      activeOrOverride.includes('facilities') &&
      filterValues.facilities.length > 0
    ) {
      filters.push({
        member: 'Facility.id',
        operator: 'equals',
        values: filterValues.facilities.map((facility) =>
          facility.id.toString(),
        ),
      });
    }
    if (
      activeOrOverride.includes('officeFacility') &&
      filterValues.officeFacility !== null
    ) {
      filters.push({
        member: 'Facility.id',
        operator: 'equals',
        values: [filterValues.officeFacility.id.toString()],
      });
    }

    if (activeOrOverride.includes('ortho') && filterValues.ortho.id !== 'all') {
      filters.push({
        member: `${cube}.ortho`,
        operator: 'equals',
        values: [(filterValues.ortho.id === 'ortho').toString()],
      });
    }

    if (activeOrOverride.includes('dosMonths')) {
      if (filterValues.dosMonths.length > 0) {
        timeDimensions.push({
          dimension: `${cube}.serviceDateStart`,
          compareDateRange: filterValues.dosMonths.map((dosMonth) => [
            formatISO(startOfMonth(dosMonth.range.startDate), {
              representation: 'date',
            }),
            formatISO(endOfMonth(dosMonth.range.startDate), {
              representation: 'date',
            }),
          ]),
          granularity: getGranularity(hasGranularity, 'month'),
        });
      } else {
        timeDimensions.push({
          dimension: `${cube}.serviceDateStart`,
          dateRange: [
            formatISO(
              startOfMonth(
                sub(new Date(), { months: DATE_OF_SERVICE_FILTER_MONTHS }),
              ),
              { representation: 'date' },
            ),
            formatISO(endOfMonth(new Date()), { representation: 'date' }),
          ],
          granularity: getGranularity(hasGranularity, 'month'),
        });
      }
    }

    if (
      activeOrOverride.includes('workedMonth') ||
      (activeOrOverride.includes('workedGranularity') &&
        filterValues.workedGranularity.id === 'monthly')
    ) {
      if (
        filterValues.workedMonth.id !== BASE_DEFAULT_WORKED_MONTH.id ||
        useTimeDimensionsInsteadOfSegments
      ) {
        timeDimensions.push({
          dimension: `${cube}.workDate`,
          dateRange: [
            formatISO(startOfMonth(filterValues.workedMonth.range.startDate), {
              representation: 'date',
            }),
            formatISO(endOfMonth(filterValues.workedMonth.range.endDate), {
              representation: 'date',
            }),
          ],
          granularity: getGranularity(hasGranularity, 'month'),
        });
      } else {
        segments.push(`${cube}.l6m`);
      }
    }

    if (
      activeOrOverride.includes('workedGranularity') &&
      filterValues.workedGranularity.id === 'daily'
    ) {
      if (useTimeDimensionsInsteadOfSegments) {
        timeDimensions.push({
          dimension: `${cube}.workDate`,
          dateRange: [
            formatISO(startOfDay(sub(new Date(), { weeks: 2 })), {
              representation: 'date',
            }),
            formatISO(endOfDay(new Date()), {
              representation: 'date',
            }),
          ],
          granularity: getGranularity(hasGranularity, 'day'),
        });
      } else {
        segments.push(`${cube}.l14d`);
      }
    }

    if (activeOrOverride.includes('users') && filterValues.users.length > 0) {
      filters.push({
        member: 'User.id',
        operator: 'equals',
        values: filterValues.users.map((user) => user.id),
      });
    }

    // Limit to active facilities
    if (useFiltersInsteadOfSegments) {
      filters.push({
        member: 'Facility.active',
        operator: 'equals',
        values: ['true'],
      });
    } else {
      segments.push('Facility.activeOnly');
    }

    return { filters, segments, timeDimensions };
  }, [
    activeFilters,
    overrideKeys,
    filterValues.adjudicationReasonCategories,
    filterValues.arAges,
    filterValues.claimStatuses,
    filterValues.facilities,
    filterValues.ortho.id,
    filterValues.users,
    filterValues.dosMonths,
    filterValues.workedMonth.id,
    filterValues.workedMonth.range.startDate,
    filterValues.workedMonth.range.endDate,
    filterValues.workedGranularity.id,
    useFiltersInsteadOfSegments,
    cube,
    hasGranularity,
    useTimeDimensionsInsteadOfSegments,
  ]);
}

function getGranularity(
  hasGranularity: boolean | undefined,
  intrinsic: TimeDimensionGranularity,
) {
  return hasGranularity ? intrinsic : undefined;
}
