import { Query, ResultSet } from '@cubejs-client/core';
import { Box } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import {
  add,
  differenceInBusinessDays,
  endOfMonth,
  formatISO,
  parseISO,
  startOfMonth,
  sub,
} from 'date-fns';
import { Point } from 'highcharts';
import React from 'react';
import {
  ColumnLabelMap,
  ComposedChart,
  DataTable,
  DataTableRow,
  DATA_TABLE_KEY,
  LoadDataErrorCard,
  PieChart,
  PieChartProps,
  QueriesRenderer,
  QueryRenderer,
  RouteGridContainer,
} from '../../components';
import { arAgeGroupDollarColorMap, arAgeGroupLabelMap } from '../../constants';
import {
  useArMonthRange,
  useCubeFilter,
  useFacilityFilterOptionsQueryOpts,
  useGlobalFilters,
} from '../../hooks';
import { sortByAgeGroup } from '../../utils';

const tableColumnMap = {
  name: { label: 'Office', phi: true, defaultSortOrder: 'asc' },
  collectedMTD: { label: 'Collection MTD', unit: '$' },
  forecastedCollection: {
    label: 'Projected Collection CM',
    unit: '$',
  },
  percentChange: { label: 'Change', unit: '%', showTotal: false },
} as const satisfies ColumnLabelMap;

function CollectionDetails() {
  const { data: facilities } = useQuery(useFacilityFilterOptionsQueryOpts());

  const { start, end } = useArMonthRange();

  const { facilities: filteredFacilities } = useGlobalFilters();

  const userClaimFilters = useCubeFilter('UserClaim');
  const rollingCollectionFilters = useCubeFilter('UserClaim', {
    useFiltersInsteadOfSegments: true,
  });

  const momDateRange: [string, string] = [
    formatISO(startOfMonth(sub(start, { months: 6 })), {
      representation: 'date',
    }),
    formatISO(endOfMonth(end), { representation: 'date' }),
  ];

  const collectedMeasure = 'UserClaim.amountPaidSum';
  const collectionMOM: Query = {
    ...userClaimFilters,
    measures: [collectedMeasure],
    timeDimensions: [
      {
        dimension: 'UserClaim.adjustedPostedDate',
        dateRange: momDateRange,
        granularity: 'month',
      },
    ],
  };

  const producedMeasure = 'UserClaim.totalAmountExpectedSum';
  const productionMOM: Query = {
    ...userClaimFilters,
    measures: [producedMeasure],
    timeDimensions: [
      {
        dimension: 'UserClaim.serviceDateStart',
        dateRange: momDateRange,
        granularity: 'month',
      },
    ],
  };

  const collectionAge: Query = {
    ...userClaimFilters,
    measures: [collectedMeasure],
    dimensions: ['UserClaim.arAgeGroup'],
    timeDimensions: [
      {
        dimension: 'UserClaim.adjustedPostedDate',
        dateRange: [
          formatISO(sub(end, { days: 90 }), { representation: 'date' }),
          formatISO(end, { representation: 'date' }),
        ],
      },
    ],
  };

  const rollingCollectionPerFacilityQuery: Query = {
    ...rollingCollectionFilters,
    measures: [
      'UserClaim.ninetyDayCollectionSum',
      'UserClaim.thirtyDayCollectionSum',
    ],
    dimensions: ['UserClaim.facilityID'],
    timeDimensions: [
      {
        dimension: 'UserClaim.adjustedPostedDate',
        dateRange: formatISO(end, { representation: 'date' }),
        granularity: 'day',
      },
    ],
  };

  const collectionPerFacilityQuery: Query = {
    ...userClaimFilters,
    measures: [collectedMeasure],
    dimensions: ['UserClaim.facilityID'],
    timeDimensions: [
      {
        dimension: 'UserClaim.adjustedPostedDate',
        dateRange: [
          formatISO(startOfMonth(sub(start, { months: 1 })), {
            representation: 'date',
          }),
          formatISO(endOfMonth(end), { representation: 'date' }),
        ],
        granularity: 'month',
      },
    ],
  };

  const transformCollectionMOM = React.useCallback(
    (result: ResultSet | null) => {
      const collection: Partial<Point>[] = [];
      const production: Partial<Point>[] = [];

      result?.chartPivot().forEach((row) => {
        const date = parseISO(row.x).getTime();

        collection.push({
          x: date,
          y: row[collectedMeasure],
        });
        production.push({
          x: date,
          y: row[producedMeasure],
        });
      });

      return {
        collection,
        production,
      };
    },
    [],
  );

  const transformCollectionAge = React.useCallback(
    (result: ResultSet | null): Pick<PieChartProps, 'data'> => ({
      data:
        result
          ?.chartPivot()
          .sort((a, b) => sortByAgeGroup(a.x, b.x))
          .map((row) => {
            const groupLabel = arAgeGroupLabelMap[row.x];
            return {
              name: groupLabel,
              color: arAgeGroupDollarColorMap[row.x],
              y: row[collectedMeasure],
              sliced: groupLabel.endsWith('+'),
            };
          }) ?? [],
    }),
    [],
  );

  const transformCollectionByFacility = (
    resultSets: [ResultSet, ResultSet] | null,
  ) => {
    if (resultSets === null) return [];
    const [rollingCollectionPerFacilityResult, collectionMTDPerFacilityResult] =
      resultSets;
    const collectionByFacility: DataTableRow<keyof typeof tableColumnMap>[] =
      [];
    const rollingCollectionPerFacility =
      rollingCollectionPerFacilityResult.chartPivot({
        x: ['UserClaim.facilityID'],
        y: ['measures'],
      });
    const collectionMTDPerFacility = collectionMTDPerFacilityResult.chartPivot({
      x: ['UserClaim.facilityID'],
      y: ['UserClaim.adjustedPostedDate', 'measures'],
    });

    let displayFacilities: Facility[];
    if (filteredFacilities.length) {
      displayFacilities = filteredFacilities.map(({ facility }) => facility);
    } else {
      displayFacilities = facilities ?? [];
    }
    displayFacilities.forEach((facility) => {
      const rollingCollection = rollingCollectionPerFacility.find(
        (row) => row.xValues[0] === facility.id.toString(),
      );
      const collection30 =
        rollingCollection?.[rollingCollectionPerFacilityQuery.measures![1]] ??
        0;
      const weekdaysLast30 = differenceInBusinessDays(
        add(end, { days: 1 }),
        sub(end, { days: 29 }),
      );
      const dailyCollection30 = collection30 / weekdaysLast30;

      const collection90 =
        rollingCollection?.[rollingCollectionPerFacilityQuery.measures![0]] ??
        0;
      const weekdaysLast90 = differenceInBusinessDays(
        add(end, { days: 1 }),
        sub(end, { days: 89 }),
      );
      const dailyCollection90 = collection90 / weekdaysLast90;

      const avgDailyCollection = (dailyCollection30 + dailyCollection90) / 2;

      const collected =
        collectionMTDPerFacility.find(
          (row) => row.x === facility.id.toString(),
        ) ?? ({} as Record<string, any>);

      const lastMonthKey = `${formatISO(
        startOfMonth(sub(start, { months: 1 })),
        { representation: 'date' },
      )}T00:00:00.000,${collectedMeasure}`;
      const collectedLM = collected[lastMonthKey] ?? 0;

      const mtdKey = `${formatISO(startOfMonth(end), {
        representation: 'date',
      })}T00:00:00.000,${collectedMeasure}`;
      const collectedMTD = collected[mtdKey] ?? 0;

      const remainingWeekdays = differenceInBusinessDays(endOfMonth(end), end);
      const forecastedCollection =
        collectedMTD + avgDailyCollection * remainingWeekdays;

      const percentChange =
        (100.0 * (forecastedCollection - collectedLM)) / collectedLM;

      collectionByFacility.push({
        [DATA_TABLE_KEY]: String(facility.id),
        name: facility.name,
        collectedMTD,
        forecastedCollection,
        percentChange,
      });
    });
    return collectionByFacility;
  };

  return (
    <RouteGridContainer
      data-testid='collection-details'
      gridTemplateColumns='1fr 1fr'
    >
      <QueryRenderer
        query={[collectionMOM, productionMOM]}
        transformResult={transformCollectionMOM}
        render={(result) => (
          <ComposedChart
            bars={[
              {
                data: result.collection ?? [],
                displayUnit: '$',
                name: 'Claim Collection',
                type: 'column',
              },
              {
                data: result.production ?? [],
                displayUnit: '$',
                name: 'Net Production',
                type: 'column',
              },
            ]}
            timeGranularity='month'
            title='Claim Collection and Net Production M.O.M'
            xAxis={{ type: 'datetime' }}
          />
        )}
        fallback={<LoadDataErrorCard />}
      />
      <Box gridRow='span 2'>
        <QueriesRenderer
          queries={
            [
              rollingCollectionPerFacilityQuery,
              collectionPerFacilityQuery,
            ] as const
          }
          transformResult={transformCollectionByFacility}
          render={(collectionByFacility) => (
            <DataTable
              columnLabelMap={tableColumnMap}
              data={collectionByFacility}
              defaultSortColumn='collectedMTD'
              bordered
            />
          )}
          fallback={<LoadDataErrorCard />}
        />
      </Box>
      <QueryRenderer
        query={collectionAge}
        transformResult={transformCollectionAge}
        render={(chartProps) => (
          <PieChart
            {...chartProps}
            title='Claim Collection Last 90 Days'
            unit='$'
          />
        )}
        fallback={<LoadDataErrorCard />}
      />
    </RouteGridContainer>
  );
}

export default CollectionDetails;
