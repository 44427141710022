import {
  DeeplyReadonly,
  Query,
  QueryRecordType,
  ResultSet,
} from '@cubejs-client/core';
import { useFlags } from '@insidedesk/tuxedo';
import { Box, tableBodyClasses } from '@mui/material';
import { capitalize } from 'lodash';
import { useCallback } from 'react';
import { v4 as uuid4 } from 'uuid';
import {
  DataTable,
  DATA_TABLE_KEY,
  LoadDataErrorMessage,
  QueryRenderer,
} from '../../../../components';
import { useCubeFilter } from '../../../../hooks';
import { formatDateField, tidyRawData } from '../../../../utils';

export default function OpenDenialsTable() {
  const flags = useFlags();
  const baseQuery = useCubeFilter(
    flags.fixOpenDenialsANSICategoryFilter
      ? 'AdjudicatedClaimDetails'
      : 'Claim',
  );
  const arAgeColumn = flags.fixOpenDenialsANSICategoryFilter
    ? 'AdjudicatedClaimDetails.arAgeGroup'
    : 'Claim.arAgeGroup';
  const query = {
    ...baseQuery,
    measures: [
      'AdjudicatedClaimDetails.deniedBenefits',
      'AdjudicatedClaimDetails.benefitPaid' /* EOB Benefit Paid */,
      'AdjudicatedClaimDetails.totalExpected' /* $ Estimate: Proc */,
      'Claim.amountPaidSum' /* PMS Posted Amt */,
      'Claim.totalAmountExpectedSum' /* $ Estimate */,
    ],
    dimensions: [
      'AdjudicatedClaimDetails.id' /* claim_id */,
      'AdjudicatedClaimDetails.denialStatus',
      'AdjudicatedClaimDetails.adjudicationReasonCategory' /* ANSI Category */,
      'Claim.url',
      'Claim.submissionDate',
      'Claim.originalInsurerName',
      'Claim.orthoMedicaidCategory',
      'Claim.workDone',
      'FirstWorkEvents.lastWorkDate',
      arAgeColumn,
    ],
    filters: [
      ...baseQuery.filters,
      {
        member: 'AdjudicatedClaimDetails.denialStatus',
        operator: 'equals',
        values: ['full', 'partial'],
      },
    ],
    timeDimensions: [{ ...baseQuery.timeDimensions[0], granularity: 'day' }],
  } as const satisfies DeeplyReadonly<Query>;

  const transformResult = useCallback(
    (results: ResultSet<QueryRecordType<Query>> | null) => {
      const data = results
        ? tidyRawData<typeof query, true>(results, {
            fillMissingDates: false,
            keepNullDimensions: true,
          })
        : [];

      return data.map((row) => ({
        /* XXX: The ID shouldn't ever be null so the uuid is mostly to appease
         * typscript in case it's right.
         */
        [DATA_TABLE_KEY]: row['AdjudicatedClaimDetails.id'] ?? uuid4(),
        claimUrl: {
          cellValue: 'View',
          href: row['Claim.url'] ?? '',
        },
        denialStatus: row['AdjudicatedClaimDetails.denialStatus'],
        adjudicationReasonCategory:
          row['AdjudicatedClaimDetails.adjudicationReasonCategory'],
        serviceDateStart: formatDateField(row['Claim.serviceDateStart']),
        submissionDate: formatDateField(row['Claim.submissionDate']),
        totalAmountExpectedSum: row['Claim.totalAmountExpectedSum'],
        amountPaidSum: row['Claim.amountPaidSum'],
        totalExpected: row['AdjudicatedClaimDetails.totalExpected'],
        benefitPaid: row['AdjudicatedClaimDetails.benefitPaid'],
        insurerName: row['Claim.originalInsurerName'],
        category:
          typeof row['Claim.orthoMedicaidCategory'] === 'string'
            ? capitalize(row['Claim.orthoMedicaidCategory'])
            : row['Claim.orthoMedicaidCategory'],
        worked: row['Claim.workDone'] === 'false' ? 'Not Worked' : 'Worked',
        lastWorkDate: formatDateField(row['FirstWorkEvents.lastWorkDate']),
        arAge: row[arAgeColumn],
      }));
    },
    [arAgeColumn],
  );

  return (
    <QueryRenderer
      query={query}
      transformResult={transformResult}
      render={(transformedData) => (
        <DataTable
          columnLabelMap={{
            claimUrl: {
              label: 'Claim Link',
              alignment: { body: 'center' },
              sortable: false,
            },
            denialStatus: { label: 'Denial Status', showTotal: false },
            adjudicationReasonCategory: {
              label: 'ANSI Category',
              showTotal: false,
            },
            serviceDateStart: { label: 'DOS', showTotal: false },
            submissionDate: { label: 'Submit Dt', showTotal: false },
            totalAmountExpectedSum: { label: 'Claim Ins Est', unit: '$' },
            amountPaidSum: { label: 'PMS Posted Amt', unit: '$' },
            totalExpected: { label: 'Total Proc Ins Est', unit: '$' },
            benefitPaid: { label: 'EOB Benefit Paid', unit: '$' },
            insurerName: { label: 'Insurer', showTotal: false },
            category: { label: 'Category', showTotal: false },
            worked: { label: 'Worked', showTotal: false },
            lastWorkDate: { label: 'Last Work Dt', showTotal: false },
            arAge: { label: 'AR Age', showTotal: false },
          }}
          data={transformedData}
          defaultSortColumn='totalExpected'
          sx={{
            [`& .${tableBodyClasses.root}`]: {
              /* eslint-disable @typescript-eslint/naming-convention */
              '& :first-of-type': { color: 'inherit' },
              '& a': {
                textDecoration: 'underline !important',
              },
              /* eslint-enable @typescript-eslint/naming-convention */
            },
          }}
        />
      )}
      fallback={
        <Box display='grid' height='100%' sx={{ placeItems: 'center' }}>
          <LoadDataErrorMessage />
        </Box>
      }
    />
  );
}
