import {
  DeeplyReadonly,
  Query,
  QueryRecordType,
  ResultSet,
} from '@cubejs-client/core';
import { useCallback } from 'react';
import { LoadDataErrorCard, PieChart, QueryRenderer } from '../components';
import { arAgeGroupDollarColorMap, arAgeGroupLabelMap } from '../constants';

interface ARByAgeProps extends ReportOptions {
  detailed?: boolean;
}

export default function ARByAge(props: ARByAgeProps) {
  const { detailed = false, query: baseQuery } = props;
  const measure = 'ClaimARSnapshot.currentAmountExpectedSum';
  const query = {
    ...baseQuery,
    measures: [measure],
    dimensions: ['ClaimARSnapshot.arAgeGroup'],
  } as const satisfies DeeplyReadonly<Query>;

  const transformResult = useCallback(
    (result: ResultSet<QueryRecordType<Query>> | null) => {
      const formattedData = result
        ?.chartPivot({ x: ['ClaimARSnapshot.arAgeGroup'], y: ['measures'] })
        .filter((row) => !row.x.includes('None'))
        // Sort needs to happen before grouping if detailed === false
        .sort((a, b) => {
          const aAgeGroup = a.xValues[0];
          const bAgeGroup = b.xValues[0];
          if (aAgeGroup.endsWith('+')) {
            return 1;
          }
          if (bAgeGroup.endsWith('+')) {
            return -1;
          }
          const aVal = parseInt(aAgeGroup, 10);
          const bVal = parseInt(bAgeGroup, 10);
          return aVal - bVal;
        })
        .map((row) => {
          const ageGroup = row.xValues[0];
          return {
            name: arAgeGroupLabelMap[ageGroup],
            color: arAgeGroupDollarColorMap[ageGroup],
            value: row[measure],
            y: row[measure],
            sliced: ageGroup.endsWith('+'),
          };
        });

      if (!detailed) {
        const labels = Object.values(arAgeGroupLabelMap);
        const oldestGroup = formattedData?.find(
          (group) => group.name === labels[labels.length - 1],
        );
        const secondOldestGroup = formattedData?.find(
          (group) => group.name === labels[labels.length - 2],
        );
        const secondOldestLabel = labels[labels.length - 2];
        const secondOldestNewLabel = `${secondOldestLabel.split('-')[0]}+`;

        if (oldestGroup && secondOldestGroup) {
          secondOldestGroup.name = secondOldestNewLabel;
          secondOldestGroup.y += oldestGroup?.y ?? 0;
          secondOldestGroup.value += oldestGroup?.value ?? 0;
          secondOldestGroup.sliced = true;
          formattedData?.pop();
        } else if (oldestGroup) {
          oldestGroup.name = secondOldestNewLabel;
        } else if (secondOldestGroup) {
          secondOldestGroup.name = secondOldestNewLabel;
        }
      }

      return formattedData;
    },
    [detailed],
  );

  return (
    <QueryRenderer
      query={query}
      transformResult={transformResult}
      render={(data) => (
        <PieChart
          title='Claim AR by Age'
          data={data as Highcharts.PointOptionsObject[]}
          unit='$'
        />
      )}
      fallback={<LoadDataErrorCard />}
    />
  );
}
