import { useSuspenseQueries } from '@suspensive/react-query';
import * as d3 from 'd3';
import { useMemo } from 'react';
import useFacilityFilterOptionsQueryOpts from './useFacilityFilterOptionsQueryOpts';
import useUserFilterOptionsQueryOpts from './useUserFilterOptionsQueryOpts';

export default function useFilterOptions() {
  const [facilitiesResult, usersResult] = useSuspenseQueries({
    queries: [
      useFacilityFilterOptionsQueryOpts(),
      useUserFilterOptionsQueryOpts(),
    ],
  });
  return useMemo(
    () => ({
      facilityOptions: facilitiesResult.data.map((facility) => ({
        id: facility.id,
        label: facility.name,
        facility,
      })),
      userOptions: usersResult.data
        .map((user) => ({
          id: user.id,
          label: user.name,
          user,
        }))
        .sort((userA, userB) => d3.ascending(userA.label, userB.label)),
    }),
    [facilitiesResult.data, usersResult.data],
  );
}
