import numbro from 'numbro';

/**
 * Truncate the provided number amount to display up to 3 digits. Although any
 * type is allowed as `value` if `value` is not a number 'N/A' will be returned.
 * eg 1 000 000 -> 1M
 *
 * @param {any} value amount to truncate.
 * @param {{dollars?: boolean}} [options]
 * @returns {string} formatted as $?\d{1,3}\.?\d{0,2}<digit place>
 */
export default function formatNumber(
  value: any,
  options?: { dollars?: boolean; totalLength?: number; decimalPlaces?: number },
): string {
  if (typeof value !== 'number') {
    return '-';
  }

  const { dollars, totalLength, decimalPlaces } = options || {};
  const defaults: numbro.Format = {
    average: true,
    lowPrecision: false,
    trimMantissa: true,
    mantissa: 0,
    optionalMantissa: true,
  };

  if (decimalPlaces === undefined) {
    defaults.totalLength = totalLength ?? 3;
  } else if (decimalPlaces === 0) {
    defaults.mantissa = 0;
  } else if (decimalPlaces > 0) {
    defaults.mantissa = decimalPlaces;
  }
  if (value >= 999500000) {
    defaults.forceAverage = 'billion';
  } else if (value >= 999500) {
    defaults.forceAverage = 'million';
  } else if (value >= 999.5) {
    defaults.forceAverage = 'thousand';
  }
  const num = numbro(value);
  if (dollars) {
    return num.formatCurrency(defaults).toUpperCase();
  }
  return num.format(defaults).toUpperCase();
}
