import {
  BlurPHIContext,
  PHI,
  SearchableDropdown,
  SearchableDropdownProps,
  SingleSelectOption,
} from '@insidedesk/tuxedo';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFilterOptions, useGlobalFilters } from '../../hooks';
import { useDefaultOfficeFacility } from './hooks';

export function OfficeFacilityName() {
  const { officeFacility } = useGlobalFilters();
  return <PHI>{officeFacility?.label ?? 'All Offices'}</PHI>;
}

type OfficeFacilityDropdownProps = Partial<
  SearchableDropdownProps<SingleSelectOption<number> & { facility: Facility }>
>;
export function OfficeFacilityDropdown(props: OfficeFacilityDropdownProps) {
  const { facilityOptions } = useFilterOptions();
  const { officeFacility } = useGlobalFilters();
  const defaultFacility = useDefaultOfficeFacility();
  const { blur } = useContext(BlurPHIContext);
  const navigate = useNavigate();

  return (
    <SearchableDropdown
      {...props}
      buttonLabel='Change Office'
      blurInputValue={blur}
      inputLabel='Search Office'
      options={facilityOptions}
      value={officeFacility ?? defaultFacility}
      onChange={(_, value) => navigate(`office/${value?.id}`)}
      groupBy={(option) => option.label[0]}
      renderOption={(_props, option) => (
        <li {..._props}>
          <PHI>{option.label}</PHI>
        </li>
      )}
      disableClearable
    />
  );
}
