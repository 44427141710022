import { Typography, TypographyProps } from '@mui/material';
import { useLastUpdated } from '../hooks';

const TIME_FORMAT = new Intl.DateTimeFormat(undefined, {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
  hour: 'numeric',
  minute: '2-digit',
  hour12: true,
});

function LastUpdated(props: TypographyProps) {
  const updated = useLastUpdated();

  if (updated === undefined) {
    return null;
  }

  return (
    <Typography
      fontSize='0.75rem'
      fontWeight={700}
      color='#979797'
      textTransform='uppercase'
      {...props}
    >
      Last Updated: {TIME_FORMAT.format(updated)}
    </Typography>
  );
}

export default LastUpdated;
