import { Outlet } from 'react-router-dom';
import { ARKPIRow } from '../../reports';
import { redirectWithQuery } from '../../utils';
import { useReportOutletContext } from '../hooks';
import { RouteObject } from '../types';
import ARAgeDetails from './ARAgeDetails';
import ARDetails from './ARDetails';
import ARReportIndex from './ARReportIndex';
import CleanClaimDetails from './CleanClaimDetails';
import CollectionDaysDetails from './CollectionDaysDetails';
import CollectionDetails from './CollectionDetails';
import NCRDetails from './NCRDetails';
import WorkedDetails from './WorkedDetails';

export const childRoutes: RouteObject[] = [
  {
    index: true,
    element: <ARReportIndex />,
    handle: { title: ['Summary'] },
  },
  {
    path: 'claim-ar-age-details',
    element: <ARAgeDetails />,
    handle: {
      title: ['Claim AR Age ', 'Details'],
      crumb: 'Claim AR Age Details',
    },
  },
  {
    path: 'ar-details',
    element: <ARDetails />,
    handle: {
      title: ['AR ', 'Details'],
      crumb: 'AR Details',
    },
  },
  {
    path: 'clean-claim-details',
    element: <CleanClaimDetails />,
    handle: {
      title: ['Clean Claim ', 'Details'],
      crumb: 'Clean Claim Details',
    },
  },
  {
    path: 'collection-days-details',
    element: <CollectionDaysDetails />,
    handle: {
      title: ['Collection Days ', 'Details'],
      crumb: 'Collection Days Details',
    },
  },
  {
    path: 'collection-details',
    element: <CollectionDetails />,
    handle: {
      title: ['Collection ', 'Details'],
      crumb: 'Collection Details',
    },
  },
  {
    path: 'ncr-details',
    element: <NCRDetails />,
    handle: {
      title: ['NCR ', 'Details'],
      crumb: 'NCR Details',
    },
  },
  {
    path: 'worked-details',
    element: <WorkedDetails />,
    handle: {
      title: ['Worked ', 'Details'],
      crumb: 'Worked Details',
    },
  },
  {
    // For backwards compatibility
    path: 'ar-age-details',
    loader: ({ request }) =>
      redirectWithQuery(request, '../claim-ar-age-details'),
  },
];

export default function ARReport() {
  const { baseQuery } = useReportOutletContext();
  return (
    <>
      <ARKPIRow baseQuery={baseQuery} />
      <Outlet context={{ baseQuery }} />
    </>
  );
}
