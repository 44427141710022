/**
 * Wrapper for App to add Context providers in a way that App can consume them directly
 */
import cubejs from '@cubejs-client/core';
import { CubeProvider } from '@cubejs-client/react';
import {
  BlurPHIProvider,
  ClientAPIProvider,
  CommonUtilityWrapper,
  FlagProvider,
  HTTPError,
  provider,
  ProviderStack,
  useRefreshToken,
  UserProfileProvider,
} from '@insidedesk/tuxedo';
import { CssBaseline } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React, { PropsWithChildren } from 'react';

import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { FULL_STORY_CONFIG, PENDO_CONFIG } from './config';
import { CLIENT_API_URL } from './constants';
import {
  ChartLabelContext,
  LastUpdatedProvider,
  LoadingContextProvider,
} from './context';
import { noticeError } from './utils';

const API_URL = process.env.REACT_APP_API_URL || '';

export default function ProviderContainer({ children }: PropsWithChildren) {
  const [showChartLabels, setShowChartLabels] = React.useState(false);

  const tokenPromise = useRefreshToken();

  const cubejsAPI = React.useMemo(
    () =>
      cubejs(() => tokenPromise, {
        apiUrl: API_URL,
      }),
    [tokenPromise],
  );

  const queryClient = React.useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            retry: (failureCount, error) => {
              if (error instanceof HTTPError) {
                if (
                  error.response.status === 404 ||
                  error.response.status === 401
                ) {
                  return false;
                }
              }
              return failureCount < 1;
            },
            refetchOnWindowFocus: false,
            keepPreviousData: true,
          },
        },
        queryCache: new QueryCache({
          onError: (err) => noticeError(err),
        }),
        mutationCache: new MutationCache({
          onError: (err) => noticeError(err),
        }),
      }),
    [],
  );

  const toggleChartLabels = React.useCallback(() => {
    setShowChartLabels((prev) => !prev);
  }, []);

  const chartLabelContext = React.useMemo(
    () => ({
      display: showChartLabels,
      toggleDisplay: toggleChartLabels,
    }),
    [showChartLabels, toggleChartLabels],
  );

  return (
    <ProviderStack
      providers={[
        provider(LocalizationProvider, { dateAdapter: AdapterDateFns }),
        provider(LoadingContextProvider, {}),
        provider(ClientAPIProvider, {
          baseURL: CLIENT_API_URL,
          tokenPromise: () => tokenPromise,
        }),
        provider(UserProfileProvider, { tokenPromise }),
        provider(FlagProvider, {
          clientSideID: process.env.REACT_APP_LD_CLIENT_ID ?? '',
          useClientContext: true,
        }),
        provider(BlurPHIProvider, {}),
        provider(CubeProvider, { cubejsApi: cubejsAPI }),
        provider(QueryClientProvider, { client: queryClient }),
        provider(ChartLabelContext.Provider, { value: chartLabelContext }),
        provider(LastUpdatedProvider, {}),
        provider(CommonUtilityWrapper, {
          pendoConfig: PENDO_CONFIG,
          fullStoryConfigOptions: FULL_STORY_CONFIG,
        }),
      ]}
    >
      <ReactQueryDevtools />
      <CssBaseline />
      {children}
    </ProviderStack>
  );
}
