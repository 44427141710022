import { DeeplyReadonly, Query } from '@cubejs-client/core';
import { useCubeQuery as useCubeQueryCUBEJS } from '@cubejs-client/react';
import { useTrackLastUpdated } from './lastUpdated';
import useTrackLoading from './useTrackLoading';
/**
 * Wrapper around useCubeQuery which tracks query loading and updated status
 */
export default function useCubeQuery<
  TQuery extends DeeplyReadonly<Query | Query[]>,
>(query: TQuery) {
  const result = useCubeQueryCUBEJS(query, { resetResultSetOnChange: false });
  useTrackLoading(result.isLoading);
  useTrackLastUpdated(result.resultSet);
  return result;
}
