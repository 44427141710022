import { LabelledCell, useFlags } from '@insidedesk/tuxedo';
import {
  HorizontalRule,
  KeyboardArrowDown,
  KeyboardArrowUp,
  PriorityHigh,
} from '@mui/icons-material';
import { Box, SvgIcon } from '@mui/material';
import { formatNumber } from '../utils';

interface KPIProps extends KPIPoint {
  main?: boolean;
  size?: KPISize;
}
function parseStatusIcon(status: KPIStatus | undefined) {
  switch (status) {
    case 'down':
      return KeyboardArrowDown;
    case 'important':
      return PriorityHigh;
    case 'neutral':
      return HorizontalRule;
    case 'up':
      return KeyboardArrowUp;
    default:
      return null;
  }
}

function parseHighlightModifier(highlight: Highlight | undefined) {
  switch (highlight) {
    case 'good':
      return 'success.main';
    case 'neutral':
      return 'primary.main';
    case 'warning':
      return 'error.dark';
    case 'bad':
      return 'error.dark';
    default:
      return 'primary';
  }
}

function KPI(props: KPIProps) {
  const {
    error = false,
    formatter,
    highlight,
    main = false,
    size = 'medium',
    name,
    status,
    type = 'number',
    unit,
    value,
  } = props;

  let kpiString: string;
  if (formatter) {
    kpiString = formatter(value);
  } else {
    kpiString = formatNumber(value, {
      decimalPlaces: type === 'currency' ? 1 : 0,
    });
  }
  if (type === 'currency') {
    kpiString = `${unit ?? '$'}${kpiString}`;
  } else if (type === 'percent') {
    kpiString = `${kpiString}%`;
  } else if (unit) {
    kpiString = `${kpiString} ${unit}`;
  }

  if (Number.isNaN(value) || value === Infinity) {
    kpiString = '-';
  }

  if (error) {
    kpiString = 'N/A';
  }

  const flags = useFlags();
  const StatusIcon = parseStatusIcon(status) ?? SvgIcon;
  const highlightModifier = parseHighlightModifier(highlight);

  let valueSize: number;
  if (size === 'large') {
    valueSize = 30;
  } else if (size === 'medium') {
    valueSize = 22;
  } else if (size === 'small') {
    valueSize = 16;
  } else {
    throw new Error(`Unknown KPI size ${size}`);
  }

  return (
    <LabelledCell
      label={name}
      sx={{ display: 'flex', flexDirection: 'column', gap: 1, lineHeight: 1 }}
      value={
        <Box component='span' sx={{ display: 'grid', margin: 'auto' }}>
          <StatusIcon
            fontSize={flags.horizontalKPIs ? 'inherit' : 'large'}
            className='kpi-status'
            sx={{
              gridRow: 1,
              gridColumn: 1,
              visibility: status ? 'visible' : 'hidden',
            }}
          />
          <Box
            component='span'
            sx={{
              gridRow: status ? 1 : 1,
              gridColumn: status ? 2 : 1,
              margin: 'auto',
            }}
          >
            {kpiString}
          </Box>
        </Box>
      }
      valueProps={{
        fontSize: valueSize,
        fontWeight: main ? 600 : 500,
        color: highlightModifier,
        margin: 'auto',
      }}
      labelProps={{ fontSize: '0.75rem', fontWeight: 500, textAlign: 'center' }}
    />
  );
}

export default KPI;
