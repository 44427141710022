import { Outlet } from 'react-router-dom';
import { ChartMetricSwitcher } from '../../components';
import { useCubeFilter } from '../../hooks';
import { DenialsKPIRow } from '../../reports';
import { RouteObject } from '../types';
import DenialByOffice, {
  childRoutes as denialByOfficeChildRoutes,
} from './by-office';
import DenialSummary from './DenialSummary';

export const childRoutes: RouteObject[] = [
  {
    index: true,
    element: <DenialSummary />,
    handle: {
      title: ['Summary'],
      skipBreadcrumbs: true,
      mainActions: <ChartMetricSwitcher />,
    },
  },
  {
    path: 'by-office',
    children: denialByOfficeChildRoutes,
    element: <DenialByOffice />,
    handle: {
      crumb: 'By Office',
      skipBreadcrumbs: true,
    },
  },
];

export default function DenialReport() {
  const baseQuery = useCubeFilter('Claim');
  return (
    <>
      <DenialsKPIRow baseQuery={baseQuery} />
      <Outlet />
    </>
  );
}
