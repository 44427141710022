import { ResultSet } from '@cubejs-client/core';
import { parseISO } from 'date-fns';
import { useCallback, useContext, useEffect } from 'react';
import { LastUpdatedContext } from '../context';
import useMatches from './useMatches';

export function useLastUpdated() {
  const { dateMap } = useContext(LastUpdatedContext);
  const key = useLastUpdatedKey();

  return dateMap.get(key);
}

type DataUpdate = Date | ResultSet | string;

export function useTrackLastUpdated(
  update: DataUpdate | null | (DataUpdate | null)[],
) {
  const setLastUpdated = useSetLastUpdated();

  useEffect(() => {
    setLastUpdated(update);
  }, [setLastUpdated, update]);
}

export function useSetLastUpdated() {
  const { updateDateMap } = useContext(LastUpdatedContext);
  const key = useLastUpdatedKey();

  return useCallback(
    (update: DataUpdate | null | (DataUpdate | null)[]) =>
      updateDateMap((prev) => {
        const oldDate = prev.get(key);

        let newDate: Date | undefined;
        if (Array.isArray(update)) {
          newDate = update
            .filter((value): value is DataUpdate => value !== null)
            .map(parseDate)
            .sort((a, b) => b.getTime() - a.getTime())[0];
        } else if (update !== null) {
          newDate = parseDate(update);
        }

        if (newDate !== undefined && (oldDate ?? new Date(0)) < newDate) {
          prev.set(key, newDate!);
        }
      }),
    [key, updateDateMap],
  );
}

function useLastUpdatedKey(): string {
  const matches = useMatches();
  return matches[matches.length - 1].id;
}

function parseDate(value: DataUpdate) {
  if (value instanceof Date) {
    return value;
  }
  if (typeof value === 'string') {
    return parseISO(value);
  }
  if (value.serialize().loadResponse.queryType === 'blendingQuery') {
    const results = value.decompose();
    return results.reduce((prev, curr) => {
      const lastRefreshTime = parseISO(
        curr.serialize().loadResponse.results[0].lastRefreshTime,
      );
      if (lastRefreshTime > prev) {
        return lastRefreshTime;
      }
      return prev;
    }, new Date(0));
  }
  return parseISO(value.serialize().loadResponse.results[0].lastRefreshTime);
}
