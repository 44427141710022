import { To, useSearchParams } from 'react-router-dom';

export default function useToWithQuery(to: To): To {
  const [searchParams] = useSearchParams();
  let toWithQuery: To;
  if (typeof to === 'string') {
    toWithQuery = `${to}?${searchParams}`;
  } else {
    toWithQuery = {
      search: searchParams.toString(),
      ...to,
    };
  }
  return toWithQuery;
}
