import { Query, ResultSet } from '@cubejs-client/core';
import { Box } from '@mui/material';
import { endOfMonth, formatISO, parseISO, startOfMonth, sub } from 'date-fns';
import { Point } from 'highcharts';
import {
  ComposedChart,
  DataTable,
  DataTableRow,
  DATA_TABLE_KEY,
  LoadDataErrorCard,
  QueriesRenderer,
  RouteGridContainer,
} from '../../components';
import {
  useArMonthRange,
  useCubeFilter,
  useCubeQuery,
  useFilterOptions,
} from '../../hooks';
import { CleanClaims } from '../../reports';
import { useARReportOutletContext } from './hooks';

const tableColumnMap = {
  name: { label: 'Office', phi: true, defaultSortOrder: 'asc' },
  cleanRate: { label: 'Clean Claim Rate', unit: '%' },
  numDirty: '# Dirty Claims Open',
  arDirty: { label: 'Dirty Claim AR', unit: '$' },
} as const;

function CleanClaimDetails() {
  const { baseQuery } = useARReportOutletContext();
  const { facilityOptions } = useFilterOptions();
  const { start, end } = useArMonthRange();

  const snapshotFilters = useCubeFilter('ClaimARSnapshot');
  const userClaimFilters = useCubeFilter('UserClaim');

  const arMeasure = 'ClaimARSnapshot.currentAmountExpectedSum';
  const cleanRateMeasure = 'ClaimARSnapshot.totalAmountExpectedSum';
  const countMeasure = 'ClaimARSnapshot.count';
  const cleanClosedMeasure = 'UserClaim.totalAmountExpectedSum';

  const cleanARMOMQuery: Query = {
    ...snapshotFilters,
    measures: [arMeasure, cleanRateMeasure],
    dimensions: ['ClaimARSnapshot.clean'],
    timeDimensions: [
      {
        dimension: 'ClaimARSnapshot.date',
        dateRange: [
          formatISO(startOfMonth(sub(start, { months: 6 })), {
            representation: 'date',
          }),
          formatISO(endOfMonth(end), { representation: 'date' }),
        ],
        granularity: 'month',
      },
    ],
  };

  const cleanClosedMOMQuery: Query = {
    ...userClaimFilters,
    measures: [cleanClosedMeasure],
    dimensions: ['UserClaim.closedClean'],
    timeDimensions: [
      {
        dimension: 'UserClaim.closedDate',
        dateRange: [
          formatISO(startOfMonth(sub(start, { months: 6 })), {
            representation: 'date',
          }),
          formatISO(endOfMonth(end), { representation: 'date' }),
        ],
        granularity: 'month',
      },
    ],
  };

  const cleanByFacilityQuery: Query = {
    ...snapshotFilters,
    measures: [arMeasure, cleanRateMeasure, countMeasure],
    dimensions: ['ClaimARSnapshot.facilityID', 'ClaimARSnapshot.clean'],
    timeDimensions: [
      {
        dimension: 'ClaimARSnapshot.date',
        dateRange: [
          formatISO(startOfMonth(start), { representation: 'date' }),
          formatISO(endOfMonth(end), { representation: 'date' }),
        ],
        granularity: 'month',
      },
    ],
  };

  const cleanClosedByFacilityQuery: Query = {
    ...userClaimFilters,
    measures: [cleanClosedMeasure],
    dimensions: ['UserClaim.facilityID', 'UserClaim.closedClean'],
    timeDimensions: [
      {
        dimension: 'UserClaim.closedDate',
        dateRange: [
          formatISO(startOfMonth(start), { representation: 'date' }),
          formatISO(endOfMonth(end), { representation: 'date' }),
        ],
        granularity: 'month',
      },
    ],
  };

  const { resultSet: cleanARMOMResult, error: cleanARMOMError } =
    useCubeQuery(cleanARMOMQuery);

  const { resultSet: cleanClosedMOMResult, error: cleanClosedMOMError } =
    useCubeQuery(cleanClosedMOMQuery);

  const arMOMData: Partial<Point>[] = [];
  const cleanARMOMData: Partial<Point>[] = [];
  const cleanRateMOMData: Partial<Point>[] = [];
  if (cleanARMOMResult && cleanClosedMOMResult) {
    const cleanClosedMOM = cleanClosedMOMResult.chartPivot();
    const cleanARMOMChart = cleanARMOMResult.chartPivot({
      x: ['ClaimARSnapshot.date'],
      y: ['ClaimARSnapshot.clean', 'measures'],
    });
    cleanARMOMChart.forEach((row) => {
      const dateStr = row.x;

      const cleanAR = row[`true,${arMeasure}`] ?? 0;
      const notCleanAR = row[`false,${arMeasure}`] ?? 0;
      const nullCleanAR = row[arMeasure] ?? 0;

      const totalAR = cleanAR + notCleanAR + nullCleanAR;

      const monthClosed = cleanClosedMOM.find(
        (closedRow) => closedRow.x === dateStr,
      );

      const closedCleanExpected =
        monthClosed?.[`true,${cleanClosedMeasure}`] ?? 0;
      const closedDirtyExpected =
        monthClosed?.[`false,${cleanClosedMeasure}`] ?? 0;
      const closedNullExpected = monthClosed?.[cleanClosedMeasure] ?? 0;

      const cleanAmountExpected =
        (row[`true,${cleanRateMeasure}`] ?? 0) + closedCleanExpected;
      const notCleanAmountExpected =
        (row[`false,${cleanRateMeasure}`] ?? 0) + closedDirtyExpected;
      const nullCleanAmountExpected =
        (row[cleanRateMeasure] ?? 0) + closedNullExpected;

      const cleanRate =
        (100.0 * cleanAmountExpected) /
        (cleanAmountExpected +
          notCleanAmountExpected +
          nullCleanAmountExpected);

      const date = parseISO(row.x).getTime();
      arMOMData.push({ x: date, y: totalAR });
      cleanARMOMData.push({ x: date, y: cleanAR });
      cleanRateMOMData.push({ x: date, y: cleanRate });
    });
  }

  const transformCleanByFacility = (
    resultSets: [ResultSet, ResultSet] | null,
  ) => {
    if (resultSets === null) return { cleanByFacility: [], totals: {} };
    const [cleanARByFacilityResult, cleanClosedByFacilityResult] = resultSets;
    const cleanClosedByFacility = cleanClosedByFacilityResult.chartPivot({
      x: ['UserClaim.facilityID'],
      y: ['UserClaim.closedClean', 'measures'],
    });

    const cleanByFacility: DataTableRow<keyof typeof tableColumnMap>[] = [];
    let allCleanExpected = 0;
    let allDirtyExpected = 0;
    let allNullExpected = 0;
    cleanARByFacilityResult
      ?.chartPivot({
        x: ['ClaimARSnapshot.facilityID'],
        y: ['ClaimARSnapshot.clean', 'measures'],
      })
      .forEach((row) => {
        const facility = facilityOptions.find((option) =>
          row.xValues.includes(option.id.toString()),
        )?.facility;

        const cleanOpen = row[`true,${cleanRateMeasure}`] ?? 0;
        const dirtyOpen = row[`false,${cleanRateMeasure}`] ?? 0;
        const nullOpen = row[cleanRateMeasure] ?? 0;

        const facilityClosed = cleanClosedByFacility.find(
          (closedRow) => closedRow.x === row.x,
        );

        const cleanClosed = facilityClosed?.[`true,${cleanClosedMeasure}`] ?? 0;
        const dirtyClosed =
          facilityClosed?.[`false,${cleanClosedMeasure}`] ?? 0;
        const nullClosed = facilityClosed?.[cleanClosedMeasure] ?? 0;

        const cleanExpected = cleanOpen + cleanClosed;
        const dirtyExpected = dirtyOpen + dirtyClosed;
        const nullExpected = nullOpen + nullClosed;
        const cleanRate =
          (100.0 * cleanExpected) /
          (cleanExpected + dirtyExpected + nullExpected);

        allCleanExpected += cleanExpected;
        allDirtyExpected += dirtyExpected;
        allNullExpected += nullExpected;

        const numDirty =
          (row[`false,${countMeasure}`] ?? 0) + (row[countMeasure] ?? 0);

        const arDirty =
          (row[`false,${arMeasure}`] ?? 0) + (row[arMeasure] ?? 0);

        cleanByFacility.push({
          [DATA_TABLE_KEY]: String(row.xValues[0]),
          name: facility?.name ?? '',
          cleanRate,
          numDirty,
          arDirty,
        });
      });
    const cleanRateTotal =
      (100.0 * allCleanExpected) /
      (allCleanExpected + allDirtyExpected + allNullExpected);
    return { cleanByFacility, totals: { cleanRate: cleanRateTotal } };
  };

  return (
    <RouteGridContainer
      data-testid='clean-claim-details'
      gridTemplateColumns='1fr 1fr'
    >
      {!cleanARMOMError && !cleanClosedMOMError ? (
        <ComposedChart
          bars={[
            {
              data: arMOMData,
              displayUnit: '$',
              name: 'Claim AR',
              type: 'column',
            },
            {
              data: cleanARMOMData,
              displayUnit: '$',
              name: 'Clean Claim AR',
              type: 'column',
            },
          ]}
          lines={[
            {
              data: cleanRateMOMData,
              displayUnit: '%',
              name: 'Clean Rate',
              type: 'spline',
              yAxis: '1',
            },
          ]}
          timeGranularity='month'
          title='Clean Claim Rate M.O.M.'
          xAxis={{
            type: 'datetime',
            minPadding: 0.075,
            maxPadding: 0.075,
          }}
        />
      ) : (
        <LoadDataErrorCard />
      )}
      <Box gridRow='span 2'>
        <QueriesRenderer
          queries={[cleanByFacilityQuery, cleanClosedByFacilityQuery] as const}
          transformResult={transformCleanByFacility}
          render={({ cleanByFacility, totals }) => (
            <DataTable
              columnLabelMap={tableColumnMap}
              data={cleanByFacility}
              defaultSortColumn='cleanRate'
              totals={totals}
              bordered
            />
          )}
          fallback={<LoadDataErrorCard />}
        />
      </Box>
      <CleanClaims query={baseQuery} />
    </RouteGridContainer>
  );
}

export default CleanClaimDetails;
