/* The values provided in this file are never used so it's ok to use empty
 * functions.
 */
import { createContext } from 'react';

export * from './lastUpdated';
export * from './loadingContext';

export const ChartLabelContext = createContext<{
  display: boolean;
  toggleDisplay: () => void;
}>({ display: false, toggleDisplay: () => {} });
