import useGlobalFilters from './useGlobalFilters';

export type ClaimsWorkedCube = 'ClaimsWorkedByFacility' | 'ClaimsWorkedByUser';

export default function useClaimsWorkedCube(): ClaimsWorkedCube {
  const filterValues = useGlobalFilters();
  if (filterValues.users.length > 0) {
    return 'ClaimsWorkedByUser';
  }
  return 'ClaimsWorkedByFacility';
}
