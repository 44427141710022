import { useCallback, useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import {
  ADJUDICATION_REASON_CATEGORY_OPTIONS,
  AR_AGE_BUCKET_OPTIONS,
  CLAIM_STATUS_OPTIONS,
  ORTHO_OPTIONS,
  WORKED_GRANULARITY_OPTIONS,
  WORK_DATE_MONTHS,
} from '../constants';
import {
  getMatchingOption,
  getMatchingOptions,
  makeArMonthOptions,
  makeDosMonthOptions,
  makeWorkedMonthOptions,
  produceSearchParams,
  toSearchParamsInit,
} from '../utils';
import useFilterOptions from './useFilterOptions';

type Action = {
  [K in keyof GlobalCubeFilterState]: {
    type: K;
    value: GlobalCubeFilterState[K];
  };
}[keyof GlobalCubeFilterState];

const AR_MONTH_OPTIONS = makeArMonthOptions();
const WORKED_MONTH_OPTIONS = makeWorkedMonthOptions(WORK_DATE_MONTHS + 1);

export const DEFAULT_CUBE_FILTER_STATE: GlobalCubeFilterState = {
  adjudicationReasonCategories: [],
  arAges: [],
  arMonth: AR_MONTH_OPTIONS[0],
  claimStatuses: [],
  dosMonths: [],
  facilities: [],
  officeFacility: null,
  ortho: ORTHO_OPTIONS[0],
  users: [],
  workedGranularity: WORKED_GRANULARITY_OPTIONS[0],
  workedMonth: WORKED_MONTH_OPTIONS[0],
};

export default function useGlobalFilters() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { facilityOptions, userOptions } = useFilterOptions();
  const overrides = useGlobalFilterOverrides();

  const filters: GlobalCubeFilterState = useMemo(
    () => ({
      adjudicationReasonCategories: getMatchingOptions(
        searchParams,
        'adjudicationReasonCategories',
        ADJUDICATION_REASON_CATEGORY_OPTIONS,
      ),
      arAges: getMatchingOptions(searchParams, 'arAges', AR_AGE_BUCKET_OPTIONS),
      arMonth:
        getMatchingOption(searchParams, 'arMonth', AR_MONTH_OPTIONS) ??
        AR_MONTH_OPTIONS[0],
      claimStatuses: getMatchingOptions(
        searchParams,
        'claimStatuses',
        CLAIM_STATUS_OPTIONS,
      ),
      facilities: getMatchingOptions(
        searchParams,
        'facilities',
        facilityOptions ?? [],
      ),
      officeFacility: null,
      ortho:
        getMatchingOption(searchParams, 'ortho', ORTHO_OPTIONS) ??
        ORTHO_OPTIONS[0],
      dosMonths: getMatchingOptions(
        searchParams,
        'dosMonths',
        makeDosMonthOptions(12),
      ),
      workedMonth:
        getMatchingOption(searchParams, 'workedMonth', WORKED_MONTH_OPTIONS) ??
        WORKED_MONTH_OPTIONS[0],
      workedGranularity: WORKED_GRANULARITY_OPTIONS[0],
      users: getMatchingOptions(searchParams, 'users', userOptions ?? []),
      ...overrides,
    }),
    [searchParams, facilityOptions, userOptions, overrides],
  );

  const updateCubeFilters = useCallback(
    (action: Action) => {
      setSearchParams(
        produceSearchParams(
          toSearchParamsInit({ [action.type]: action.value ?? undefined }),
        ),
      );
    },
    [setSearchParams],
  );

  const resetCubeFilters = useCallback(() => {
    setSearchParams((prevParams) => {
      const params = new URLSearchParams(prevParams);
      Object.keys(DEFAULT_CUBE_FILTER_STATE).forEach((key) =>
        params.delete(key),
      );
      return params;
    });
  }, [setSearchParams]);

  const overrideKeys = useMemo(() => Object.keys(overrides), [overrides]);

  return { ...filters, updateCubeFilters, resetCubeFilters, overrideKeys };
}

function useGlobalFilterOverrides(): Partial<GlobalCubeFilterState> {
  const { userId, facilityId, workedGranularityId, officeFacilityId } =
    useParams();
  const { userOptions, facilityOptions } = useFilterOptions();

  const overrides = useMemo(() => {
    const overrides_: Partial<GlobalCubeFilterState> = {};
    if (userId) {
      const matchingUser = userOptions.find((user) => user.id === userId);
      if (!matchingUser) return new Response(null, { status: 404 });
      overrides_.users = [matchingUser];
    }
    if (facilityId) {
      const matchingFacility = facilityOptions.find(
        (facility) => String(facility.id) === facilityId,
      );
      if (!matchingFacility) return new Response(null, { status: 404 });
      overrides_.facilities = [matchingFacility];
    }
    if (officeFacilityId) {
      const matchingFacility = facilityOptions.find(
        (option) => String(option.id) === officeFacilityId,
      );
      if (!matchingFacility) return new Response(null, { status: 404 });
      overrides_.officeFacility = matchingFacility;
    }
    if (workedGranularityId) {
      const matchingGranularity = WORKED_GRANULARITY_OPTIONS.find(
        (option) => option.id === workedGranularityId,
      );
      if (!matchingGranularity) return new Response(null, { status: 404 });
      overrides_.workedGranularity = matchingGranularity;
    }
    return overrides_;
  }, [
    facilityId,
    facilityOptions,
    officeFacilityId,
    userId,
    userOptions,
    workedGranularityId,
  ]);

  if (overrides instanceof Response) throw overrides;
  return overrides;
}
