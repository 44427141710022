import { Outlet } from 'react-router-dom';
import { SelectedFacilityName } from '../../../components';
import { RouteObject } from '../../types';
import DenialsByOfficeTable from './DenialsByOfficeTable';
import OfficeDenialReport, {
  children as officeDenialReportChildren,
} from './office';

export const childRoutes: RouteObject[] = [
  {
    index: true,
    element: <DenialsByOfficeTable />,
    handle: {
      skipBreadcrumbs: true,
    },
  },
  {
    path: ':facilityId',
    children: officeDenialReportChildren,
    element: <OfficeDenialReport />,
    handle: {
      activeCubeFilters: [
        'dosMonths',
        'adjudicationReasonCategories',
        'arAges',
        'claimStatuses',
      ],
      crumb: <SelectedFacilityName key='officeName' />,
    },
  },
];

export default function DenialByOffice() {
  return <Outlet />;
}
