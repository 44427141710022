export function sortByAgeGroup(
  a: string,
  b: string,
  options?: { order?: 'asc' | 'desc' },
) {
  let modifier = 1;
  if (options?.order === 'desc') {
    modifier = -1;
  }
  if (a.endsWith('+')) {
    return 1 * modifier;
  }
  if (b.endsWith('+')) {
    return -1 * modifier;
  }
  const aVal = parseInt(a, 10);
  const bVal = parseInt(b, 10);
  return (aVal - bVal) * modifier;
}
