import { isNotNullish } from '@insidedesk/tuxedo';
import useMatches from './useMatches';

export default function useActiveCubeFilters() {
  return (
    useMatches()
      .map((match) => match.handle)
      .filter(isNotNullish)
      .map((handle) => handle.activeCubeFilters)
      .filter(isNotNullish)
      .slice(-1)[0] ?? []
  );
}
