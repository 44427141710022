import { useMatches as useMatchesOrig } from 'react-router-dom';
import { RouteMatch } from '../routes/types';

export default function useMatches(): RouteMatch[] {
  return useMatchesOrig().map((match) =>
    typeof match.handle === 'function'
      ? { ...match, handle: match.handle(match) }
      : match,
  ) as RouteMatch[];
}
