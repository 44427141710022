import { useCallback } from 'react';
import { makeArMonthOptions } from '../utils';
import useGlobalFilters from './useGlobalFilters';

const AR_MONTH_OPTIONS = makeArMonthOptions();

export default function useArMonthRange() {
  const {
    arMonth: {
      id: month,
      range: { startDate: start, endDate: end },
    },
    updateCubeFilters,
  } = useGlobalFilters();
  const setRange = useCallback(
    (newMonth: ArMonthFilter) =>
      updateCubeFilters({
        type: 'arMonth',
        value:
          AR_MONTH_OPTIONS.find((option) => option.id === newMonth) ??
          AR_MONTH_OPTIONS[0],
      }),
    [updateCubeFilters],
  );
  return { month, start, end, setRange };
}
