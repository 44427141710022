import { DeeplyReadonly, Query } from '@cubejs-client/core';
import { CubeContext, CubeContextProps } from '@cubejs-client/react';
import { Box } from '@mui/material';
import { useSuspenseQuery } from '@suspensive/react-query';
import * as d3 from 'd3';
import { differenceInDays, min } from 'date-fns';
import { useContext } from 'react';
import {
  ColumnLabelMap,
  DataTable,
  DataTableExpandedRow,
  DataTableExpandRow,
  DataTableTotal,
  DATA_TABLE_KEY,
  ExpandRowFilterValue,
  LoadDataErrorMessage,
  rowId,
} from '../../../components';
import {
  useClaimsWorkedCube,
  useCubeFilter,
  useFacilityFilterOptionsQueryOpts,
  useGlobalFilters,
  useLoader,
} from '../../../hooks';
import {
  CubeDataSlice,
  fetchCubeDataSlices,
  tidyRawData,
} from '../../../utils';
import { claimsWorkedData } from '../utils';

const columnLabelMap = {
  office: {
    label: 'Office Name',
    phi: true,
    defaultSortOrder: 'asc',
    adornment: 'expand',
  },
  users: { label: '# Users', showTotal: false },
  touchEvents: '# Touch Events',
  workEvents: '# Work Events',
  viewEvents: '# View Events',
  claimsWorked: '# Claims Worked',
  claimsWorkedOpen: '# Claims Worked Open',
  closeRateWorked: { label: 'Close Rate Worked', unit: '%' },
  workToClose: 'Work To Close (Days)',
  workEventsPerDay: 'Work Events / Day',
  dollarsPostedWorked: { label: '$ Posted: Worked', unit: '$' },
  claimYieldWorked: { label: 'Claim Yield: Worked', unit: '%' },
} as const satisfies ColumnLabelMap;

type ColumnKey = keyof typeof columnLabelMap;
type TableRow = DataTableExpandRow<ColumnKey>;
type TotalsRow = Record<ColumnKey, DataTableTotal>;
type TableSliceData = {
  tableData: d3.InternMap<string, Partial<TableRow>>;
  tableTotals: Partial<TotalsRow>;
};
type ExpandedTableRow = DataTableExpandedRow<ColumnKey, 'office'>;
type ExpandedTableSliceData = {
  tableData: d3.InternMap<string, Partial<ExpandedTableRow>>;
};

export default function ProductivityByOfficeTable() {
  const { data, totals, error } = useLoadTableData();
  const getExpandedDataCallback = useGetExpandedDataCallback();

  if (error) {
    return (
      <Box
        data-testid='productivity-by-office'
        display='grid'
        height='100%'
        sx={{ placeItems: 'center' }}
      >
        <LoadDataErrorMessage />
      </Box>
    );
  }

  return (
    <DataTable
      data-testid='productivity-by-office'
      data={data}
      totals={totals}
      columnLabelMap={columnLabelMap}
      getExpandedData={getExpandedDataCallback}
      remapExpandedColumn={{ userName: 'office' }}
      defaultSortColumn='office'
    />
  );
}

function useLoadTableData() {
  const { data: facilityOptions } = useSuspenseQuery(
    useFacilityFilterOptionsQueryOpts(),
  );
  const filterFacilities = useGlobalFilters().facilities.map(
    ({ facility }) => facility,
  );
  const facilities =
    filterFacilities.length > 0 ? filterFacilities : facilityOptions;

  const slices = [
    useUserCountsDataSliceOpts(),
    useWorkDoneByUserDataSliceOpts(),
    useClaimsWorkedDataSliceOpts(),
  ] as const;

  const results = useLoader({
    async loader({ fetchCubeQuery }) {
      const slicesData = await fetchCubeDataSlices(fetchCubeQuery, slices);
      const mergedData = new Map<
        string,
        Partial<DataTableExpandRow<ColumnKey>>
      >();

      facilities.forEach((facility) => {
        const id = String(facility.id);
        mergedData.set(id, {
          [rowId]: id,
          [DATA_TABLE_KEY]: id,
          office: facility.name,
        });
      });

      slicesData?.forEach(({ tableData }) =>
        Array.from(tableData?.entries()).forEach(([facilityId, row]) =>
          mergedData.set(facilityId, { ...mergedData.get(facilityId), ...row }),
        ),
      );
      let mergedTotals: Partial<TotalsRow> = {};
      slicesData?.forEach(({ tableTotals }) => {
        mergedTotals = { ...mergedTotals, ...tableTotals };
      });
      return {
        data: Array.from(mergedData.values()) as TableRow[],
        totals: mergedTotals,
      };
    },
    default() {
      return { data: [] as TableRow[], totals: {} as Partial<TotalsRow> };
    },
    loaderKey: slices.map((slice) => slice.query),
  });

  const { data, totals } = results.data;
  return { data, totals, error: results.error };
}

function useUserCountsDataSliceOpts(): CubeDataSlice<
  typeof query,
  TableSliceData
> {
  const cube = 'ActiveUsersByFacility';
  const { workedMonth } = useGlobalFilters();
  const baseQuery = useCubeFilter(cube, {
    useTimeDimensionsInsteadOfSegments: workedMonth.id !== 'all',
  });

  const query = {
    ...baseQuery,
    measures: [`${cube}.userCount`],
    dimensions: ['Facility.id'],
  } as const satisfies DeeplyReadonly<Query>;

  return {
    query,
    transformResult: (resultSet) => {
      const data = tidyRawData<typeof query>(resultSet);
      const tableData = d3.rollup(
        data,
        (rows): Partial<TableRow> => ({
          users: d3.sum(rows, (row) => row[`${cube}.userCount`]),
        }),
        (row) => row['Facility.id'],
      );
      return { tableData, tableTotals: {} };
    },
  };
}

function useWorkDoneByUserDataSliceOpts(): CubeDataSlice<
  typeof query,
  TableSliceData
> {
  const {
    workedMonth: {
      range: { startDate, endDate },
    },
  } = useGlobalFilters();
  const daysInRange = differenceInDays(min([endDate, new Date()]), startDate);

  const cube = 'WorkDoneByUser';
  const baseQuery = useCubeFilter(cube, {
    useTimeDimensionsInsteadOfSegments: true,
  });

  const query = {
    ...baseQuery,
    measures: ['WorkDoneByUser.claimCount'],
    dimensions: ['WorkDoneByUser.workTypeGroup', 'Facility.id'],
  } as const satisfies DeeplyReadonly<Query>;

  return {
    query,
    transformResult: (resultSet) => {
      const data = tidyRawData<typeof query>(resultSet);
      const tableData = d3.rollup(
        data,
        (rows): Partial<TableRow> => {
          const touchEvents = d3.sum(
            rows,
            (row) => row['WorkDoneByUser.claimCount'],
          );
          const workEvents = d3.sum(
            rows.filter(
              (row) => row['WorkDoneByUser.workTypeGroup'] === 'CLAIM_WORKED',
            ),
            (row) => row['WorkDoneByUser.claimCount'],
          );
          const viewEvents = d3.sum(
            rows.filter(
              (row) => row['WorkDoneByUser.workTypeGroup'] === 'VIEW_ONLY',
            ),
            (row) => row['WorkDoneByUser.claimCount'],
          );
          const workEventsPerDay = workEvents / daysInRange;
          return { touchEvents, workEvents, viewEvents, workEventsPerDay };
        },
        (row) => row['Facility.id'],
      );
      return { tableData, tableTotals: {} };
    },
  };
}

function useClaimsWorkedDataSliceOpts(): CubeDataSlice<
  typeof query,
  TableSliceData
> {
  const cube = useClaimsWorkedCube();
  const query = {
    ...useCubeFilter(cube),
    measures: [
      `${cube}.adjustedAmountExpectedSum`,
      `${cube}.amountPaidSum`,
      `${cube}.claimClosedCount`,
      `${cube}.claimCount`,
      `${cube}.daysToClose`,
      `${cube}.deletedAdjustedAmountExpectedSum`,
    ],
    dimensions: [`${cube}.closed`, 'Facility.id'],
  } as const satisfies DeeplyReadonly<Query>;

  return {
    query,
    transformResult: (resultSet) => {
      const data = tidyRawData<typeof query>(resultSet);
      const tableData = d3.rollup(
        data,
        (rows): Partial<TableRow> => claimsWorkedData(rows, cube),
        (row) => row['Facility.id'],
      );

      const tableTotals = claimsWorkedData(data, cube);

      return { tableData, tableTotals };
    },
  };
}

function useGetExpandedDataCallback() {
  const cubeContext = useContext(CubeContext);
  const expandedDataSliceFactories = [
    useWorkDoneByUserExpandedDataSliceFactory(),
    useClaimsWorkedExpandedDataSliceFactory(),
  ];

  return (filterValue: ExpandRowFilterValue) =>
    getExpandedData(filterValue, cubeContext, expandedDataSliceFactories);
}

async function getExpandedData(
  filterValue: ExpandRowFilterValue,
  context: CubeContextProps,
  dataSliceFactories: ((
    facilityId: ExpandRowFilterValue,
  ) => DeeplyReadonlyWithFunctions<CubeDataSlice>)[],
) {
  const { cubejsApi } = context;

  const results = (await Promise.all(
    dataSliceFactories
      .map((factory) => factory(filterValue))
      .map(({ query, transformResult }) =>
        cubejsApi.load(query).then((resultSet) => transformResult(resultSet)),
      ),
  )) as ExpandedTableSliceData[];

  const mergedData = new Map<string, Partial<ExpandedTableRow>>();

  results?.forEach(({ tableData }) =>
    Array.from(tableData?.entries()).forEach(([userId, row]) =>
      mergedData.set(userId, {
        ...mergedData.get(userId),
        ...row,
        users: 1,
      }),
    ),
  );

  return Array.from(mergedData.values()) as ExpandedTableRow[];
}

function useWorkDoneByUserExpandedDataSliceFactory(): (
  facilityId: ExpandRowFilterValue,
) => CubeDataSlice<ReturnType<typeof getQuery>, ExpandedTableSliceData> {
  const cube = 'WorkDoneByUser';
  const {
    workedMonth: {
      range: { startDate, endDate },
    },
  } = useGlobalFilters();
  const daysInRange = differenceInDays(min([endDate, new Date()]), startDate);
  const baseQuery = useCubeFilter(cube, {
    useTimeDimensionsInsteadOfSegments: true,
  });
  const getQuery = (facilityId: ExpandRowFilterValue) =>
    ({
      ...baseQuery,
      measures: ['WorkDoneByUser.claimCount'],
      dimensions: ['WorkDoneByUser.workTypeGroup', 'User.id', 'User.name'],
      filters: [
        ...(baseQuery.filters ?? []),
        {
          member: 'Facility.id',
          operator: 'equals',
          values: [String(facilityId)],
        },
      ],
    } as const satisfies DeeplyReadonly<Query>);

  return (facilityId) => ({
    query: getQuery(facilityId),
    transformResult: (resultSet) => {
      const data = tidyRawData<ReturnType<typeof getQuery>>(resultSet);
      const tableData = d3.rollup(
        data,
        (rows): Partial<ExpandedTableRow> => {
          const userName = rows[0]['User.name'];
          const touchEvents = d3.sum(
            rows,
            (row) => row['WorkDoneByUser.claimCount'],
          );
          const workEvents = d3.sum(
            rows.filter(
              (row) => row['WorkDoneByUser.workTypeGroup'] === 'CLAIM_WORKED',
            ),
            (row) => row['WorkDoneByUser.claimCount'],
          );
          const viewEvents = d3.sum(
            rows.filter(
              (row) => row['WorkDoneByUser.workTypeGroup'] === 'VIEW_ONLY',
            ),
            (row) => row['WorkDoneByUser.claimCount'],
          );
          const workEventsPerDay = workEvents / daysInRange;
          return {
            userName: {
              cellValue: userName,
              to: `../by-user/${rows[0]['User.id']}`,
            },
            touchEvents,
            workEvents,
            viewEvents,
            workEventsPerDay,
          };
        },
        (row) => row['User.id'],
      );
      return { tableData };
    },
  });
}

function useClaimsWorkedExpandedDataSliceFactory(): (
  facilityId: ExpandRowFilterValue,
) => CubeDataSlice<ReturnType<typeof getQuery>, ExpandedTableSliceData> {
  const cube = 'ClaimsWorkedByUser';
  const baseQuery = useCubeFilter(cube);
  const getQuery = (facilityId: ExpandRowFilterValue) =>
    ({
      ...baseQuery,
      measures: [
        `${cube}.adjustedAmountExpectedSum`,
        `${cube}.amountPaidSum`,
        `${cube}.claimClosedCount`,
        `${cube}.claimCount`,
        `${cube}.daysToClose`,
        `${cube}.deletedAdjustedAmountExpectedSum`,
      ],
      dimensions: [`${cube}.closed`, 'User.id', 'User.name'],
      filters: [
        ...(baseQuery.filters ?? []),
        {
          member: 'Facility.id',
          operator: 'equals',
          values: [String(facilityId)],
        },
      ],
    } as const satisfies DeeplyReadonly<Query>);

  return (facilityId) => ({
    query: getQuery(facilityId),
    transformResult: (resultSet) => {
      const data = tidyRawData<ReturnType<typeof getQuery>>(resultSet);
      const tableData = d3.rollup(
        data,
        (rows): Partial<ExpandedTableRow> => ({
          userName: {
            cellValue: rows[0]['User.name'],
            to: `../by-user/${rows[0]['User.id']}`,
          },
          ...claimsWorkedData(rows, cube),
        }),
        (row) => row['User.id'],
      );

      return { tableData };
    },
  });
}
