import { propertyIn } from '@insidedesk/tuxedo';
import * as d3 from 'd3';
import { ColumnLabelMapDescription, DataTableRow, SortState } from './types';

export function formatDescription(description: ColumnLabelMapDescription) {
  if (typeof description === 'object') return description;
  return { label: description } satisfies ColumnLabelMapDescription;
}

export function sortData<TColumn extends string>(
  data: DataTableRow<TColumn>[],
  sortState: SortState<TColumn>,
) {
  const sortBy = Object.entries(sortState).find(([, { active }]) => active);
  if (!sortBy) throw new Error('No active sort state found.');

  const [column, { direction }] = sortBy;

  const sortedData = d3.sort(data, (a, b) => {
    const aColValue = a[column];
    const bColValue = b[column];

    let aVal: string | number | null | undefined;
    if (propertyIn(aColValue, 'cellValue')) {
      aVal = aColValue.cellValue;
    } else {
      aVal = aColValue ?? undefined;
    }

    let bVal: string | number | null | undefined;
    if (propertyIn(bColValue, 'cellValue')) {
      bVal = bColValue.cellValue;
    } else {
      bVal = bColValue ?? undefined;
    }

    return direction === 'asc'
      ? d3.ascending(aVal, bVal)
      : d3.descending(aVal, bVal);
  });

  return sortedData;
}
