import { PHI } from '@insidedesk/tuxedo';
import { useGlobalFilters } from '../hooks';

export default function SelectedFacilityName() {
  const { facilities } = useGlobalFilters();

  let name: string;
  if (facilities.length === 1) {
    ({ label: name } = facilities[0]);
  } else if (facilities.length > 1) {
    name = 'Multiple Offices';
  } else {
    name = 'All Offices';
  }

  return <PHI>{name}</PHI>;
}
