/* eslint-disable @typescript-eslint/naming-convention */
import { TimeDimensionGranularity } from '@cubejs-client/core';
import { MultiSelectOption, SingleSelectOption } from '@insidedesk/tuxedo';
import variables from './styles/variables.module.scss';
import { ChartPalette, formatNumber } from './utils';

export const CLIENT_API_URL = process.env.REACT_APP_CLIENT_API_URL ?? '';

export const NAVBAR_CONFIG = {
  assistUrl: process.env.REACT_APP_ASSIST_PAGE ?? '',
  credentialsUrl: process.env.REACT_APP_CREDENTIALS_PAGE ?? '',
  iqUrl: process.env.REACT_APP_IQ_PAGE ?? '',
  remitUrl: process.env.REACT_APP_REMIT_PAGE ?? '',
  usersUrl: process.env.REACT_APP_USERS_PAGE ?? '',
  helpUrl: process.env.REACT_APP_HELP_URL ?? '',
};

export const BASE_CHART_OPTIONS: Highcharts.Options = {
  credits: { enabled: false },
  chart: {
    numberFormatter: (num) => formatNumber(num, { decimalPlaces: 1 }),
    style: { fontFamily: 'Montserrat', textTransform: 'capitalize' },
  },
  title: {
    align: 'center',
    style: {
      color: variables.chartTitleColor,
      fontSize: '18px',
      fontWeight: '500',
    },
    margin: 10,
  },
};

export const arAgeGroupLabelMap: Record<string, string> = {
  '30': '< 30',
  '60': '30-60',
  '90': '60-90',
  '180': '90-180',
  '180+': '180+',
};
export const arAgeGroupDollarColorMap: Record<string, string> = {
  '30': variables.progressionDollar1,
  '60': variables.progressionDollar2,
  '90': variables.progressionDollar3,
  '180': variables.progressionDollar4,
  '180+': variables.progressionDollar5,
};
export const arAgeGroupClaimColorMap: Record<string, string> = {
  '30': variables.progressionClaim1,
  '60': variables.progressionClaim2,
  '90': variables.progressionClaim3,
  '180': variables.progressionClaim4,
  '180+': variables.progressionClaim5,
};

export const claimResolvedLabelMap: Record<string, string> = {
  false: 'Open',
  true: 'Closed',
};

export const ADJUDICATION_REASON_CATEGORY_OPTIONS = [
  'Auth/Precert',
  'Bundled/Inclusive Services',
  'Coins/Deductible',
  'Contractual Adjustment',
  'Coordination of Benefits',
  'Coverage/Eligibility',
  'Duplicate',
  'Information Only',
  'Medical Necessity',
  'Non-Covered Services',
  'Other',
  'Request Clinical Info',
  'Request Non-Clinical Info',
  'Timely Filing',
  'Witholding',
].map((category) => ({ id: category, label: category }));

export const CHART_MIN_HEIGHT = 300;
export const DATA_TABLE_MIN_HEIGHT = 400;

export const AR_AGE_BUCKET_OPTIONS: MultiSelectOption<ArAgeFilter>[] = [
  { id: '30', label: '<30' },
  { id: '60', label: '<60' },
  { id: '90', label: '<90' },
  { id: '180', label: '<180' },
  { id: '180+', label: '180+' },
];

export const CLAIM_STATUS_OPTIONS: MultiSelectOption<ClaimStatusFilter>[] = [
  { id: 'open', label: 'Open' },
  { id: 'closed', label: 'Closed' },
];

export const ORTHO_OPTIONS: SingleSelectOption<OrthoFilter>[] = [
  { id: 'non-ortho', label: 'Non-Ortho' },
  { id: 'ortho', label: 'Ortho Only' },
  { id: 'all', label: 'All' },
];

export const DATE_OF_SERVICE_FILTER_MONTHS = 6;

export const WORK_DATE_MONTHS = 6;
export const BASE_DEFAULT_WORKED_MONTH: SingleSelectOption<WorkedMonthFilter> =
  {
    id: 'all',
    label: 'All',
  };

export const WORKED_GRANULARITY_OPTIONS: SingleSelectOption<WorkedGranularityFilter>[] =
  [
    { id: 'all', label: 'All' },
    { id: 'monthly', label: 'Monthly' },
    { id: 'daily', label: 'Daily' },
  ];

export const TICK_INTERVAL: Record<TimeDimensionGranularity, number> = {
  day: 1000 * 3600 * 24,
  hour: 1000 * 3600,
  minute: 1000 * 60,
  month: 1000 * 3600 * 24 * 30,
  quarter: 1000 * 3600 * 24 * 30 * 3,
  second: 1000,
  week: 1000 * 3600 * 24 * 7,
  year: 1000 * 3600 * 24 * 365,
};

export const CHART_PALETTE = new ChartPalette({
  '%': [variables.palettePercentLight, variables.palettePercentDark],
  $: [variables.paletteDollarLight, variables.paletteDollarDark],
  claim: [variables.paletteClaimLight, variables.paletteClaimDark],
  day: [variables.paletteDayLight, variables.paletteDayDark],
  work: [variables.paletteWorkLight, variables.paletteWorkDark],
});
