import React from 'react';
import { Updater, useImmer } from 'use-immer';

export const LastUpdatedContext = React.createContext<{
  dateMap: Map<string, Date>;
  updateDateMap: Updater<Map<string, Date>>;
}>({
  dateMap: new Map(),
  // eslint-disable-next-line no-unused-vars
  updateDateMap: () => {},
});

export function LastUpdatedProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [dateMap, updateDateMap] = useImmer(new Map<string, Date>());

  const value = React.useMemo(
    () => ({
      dateMap,
      updateDateMap,
    }),
    [dateMap, updateDateMap],
  );

  return (
    <LastUpdatedContext.Provider value={value}>
      {children}
    </LastUpdatedContext.Provider>
  );
}
