import { useFilterOptions } from '../../hooks';

/* eslint-disable import/prefer-default-export */
export function useDefaultOfficeFacility() {
  const { facilityOptions } = useFilterOptions();
  if (facilityOptions.length === 0) {
    throw new Error(
      'There are no facility options available, cannot determine a default',
    );
  }
  const facilityId = sessionStorage.getItem('facilityId');
  const defaultFacility =
    facilityOptions.find((option) => String(option.id) === facilityId) ??
    facilityOptions[0];

  return defaultFacility;
}
