import { forwardRef } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { useToWithQuery } from '../hooks';

const NavLinkWithQuery = forwardRef<HTMLAnchorElement, NavLinkProps>(
  (props, ref) => {
    const { to, ...rest } = props;
    const toWithQuery = useToWithQuery(to);

    return <NavLink ref={ref} to={toWithQuery} {...rest} />;
  },
);

NavLinkWithQuery.displayName = 'NavLinkWithQuery';

export default NavLinkWithQuery;
