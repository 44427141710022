import {
  MultiSelectChip,
  PHI,
  SidebarFilterSection,
  SidebarNav,
  SidebarNavEntry,
  useUserProfile,
} from '@insidedesk/tuxedo';
import { Box, CardContent } from '@mui/material';
import {
  DEFAULT_CUBE_FILTER_STATE,
  useActiveCubeFilters,
  useGlobalFilters,
} from '../hooks';
import LastUpdated from './LastUpdated';
import NavLinkWithQuery from './NavLinkWithQuery';

export default function NavigationPanel() {
  const client = useUserProfile().client.name;
  const { resetCubeFilters } = useGlobalFilters();

  const entries: SidebarNavEntry[] = [
    {
      label: 'AR',
      path: 'ar',
      children: [
        { label: 'Summary', index: true },
        { path: 'ar-details', label: 'AR Details' },
        { path: 'collection-details', label: 'Collection Details' },
        { path: 'ncr-details', label: 'NCR Details' },
        { path: 'collection-days-details', label: 'Collection Days Details' },
        { path: 'worked-details', label: 'Worked Details' },
        { path: 'claim-ar-age-details', label: 'Claim AR Age Details' },
        { path: 'clean-claim-details', label: 'Clean Claim Details' },
      ],
    },
    {
      label: 'Productivity',
      path: 'productivity',
      children: [
        { label: 'Summary', index: true },
        { label: 'By Office', path: 'by-office' },
        {
          label: 'By User',
          path: 'by-user',
          children: [
            { label: 'Monthly', path: ':userId/monthly' },
            { label: 'Daily', path: ':userId/daily' },
          ],
        },
      ],
    },
    {
      label: 'Denial',
      path: 'denial',
      children: [
        { label: 'Summary', index: true },
        {
          label: 'By Office',
          path: 'by-office',
          children: [
            { label: 'Open Denials', path: ':facilityId/open-denials' },
          ],
        },
      ],
    },
    ...(client === 'MB2' ? [{ label: 'Office', path: 'office' }] : []),
  ];

  const chips = [
    ...useFilterChips('Office', 'facilities', { phi: true }),
    ...useFilterChips('Month', 'arMonth'),
    ...useFilterChips('Ortho Claims', 'ortho'),
    ...useFilterChips('DoS Month', 'dosMonths'),
    ...useFilterChips('Claim Status', 'claimStatuses'),
    ...useFilterChips('ANSI Denial Reason', 'adjudicationReasonCategories'),
    ...useFilterChips('Age Bucket', 'arAges'),
    ...useFilterChips('Month Worked', 'workedMonth'),
    ...useFilterChips('User', 'users', { phi: true }),
  ];

  return (
    <CardContent
      className='flex-scrollable'
      sx={{
        px: 0,
        // Because PanelCard is display: flex
        flexGrow: 1,
      }}
    >
      <SidebarNav component={NavLinkWithQuery} entries={entries} />
      <Box flexGrow={1} />
      {chips.length > 0 && (
        <SidebarFilterSection onReset={resetCubeFilters}>
          {chips.map((chip) =>
            chip.phi ? (
              <PHI key={chip.label}>
                <MultiSelectChip {...chip} />
              </PHI>
            ) : (
              <MultiSelectChip key={chip.label} {...chip} />
            ),
          )}
        </SidebarFilterSection>
      )}
      <LastUpdated textAlign='end' px={1.5} />
    </CardContent>
  );
}

type MultiSelectKey = {
  [K in keyof GlobalCubeFilterState]: GlobalCubeFilterState[K] extends unknown[]
    ? K
    : never;
}[keyof GlobalCubeFilterState];

type SingleSelectKey = {
  [K in keyof GlobalCubeFilterState]: Exclude<K, MultiSelectKey>;
}[keyof GlobalCubeFilterState];

function useFilterChips<K extends MultiSelectKey | SingleSelectKey>(
  label: string,
  key: K,
  options?: { phi?: boolean },
) {
  const { phi } = options || { phi: false };
  const { updateCubeFilters, ...filters } = useGlobalFilters();
  const activeFilters = useActiveCubeFilters();

  if (!activeFilters.includes(key)) return [];
  const values = filters[key];
  if (!Array.isArray(values)) {
    const defaultValue = DEFAULT_CUBE_FILTER_STATE[key];
    if (!Array.isArray(defaultValue) && defaultValue !== values) {
      return [
        {
          label: `${label}: ${values?.label}`,
          phi,
          onDelete: () =>
            // XXX Typescript isn't able to understand that since `key` was used
            // to get `defaultValue` the two will make up a valid combination
            // when calling `updateCubeFilters`
            // @ts-expect-error 2345
            updateCubeFilters({
              type: key,
              value: defaultValue,
            }),
        },
      ];
    }
    return [];
  }
  return values.map((item) => ({
    label: `${label}: ${item.label}`,
    phi,
    onDelete: () =>
      updateCubeFilters({
        type: key,
        // TXXX ypescript 4.x isn't smart enough to know that every option
        // has a 'filter' function. Typescript 5.x is but that's incompatible
        // with react-scripts (but since it doesn't support correlated union
        // there would still be a spurious error)
        // @ts-expect-error 2349
        value: values.filter((option) => option.id !== item.id),
      }),
  }));
}
