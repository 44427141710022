import {
  DeeplyReadonly,
  Query,
  QueryRecordType,
  ResultSet,
} from '@cubejs-client/core';
import { FetchCubeQuery } from '../hooks/useLoader';

export type CubeDataSlice<
  TQuery extends DeeplyReadonly<Query> = Query,
  TData = unknown,
> = {
  query: TQuery;
  transformResult: (result: ResultSet<QueryRecordType<TQuery>>) => TData;
};

type TupleSlicesData<T extends readonly unknown[]> = T extends readonly [
  { transformResult: (...args: any) => infer TData },
  ...infer Tail,
]
  ? [TData, ...TupleSlicesData<[...Tail]>]
  : [];

type ArraySlicesData<T extends readonly unknown[]> = T extends {
  transformResult: (...args: any) => infer TData;
}[]
  ? TData[]
  : [];

type SlicesData<T extends readonly unknown[]> = IsTuple<T> extends true
  ? TupleSlicesData<T>
  : ArraySlicesData<T>;

export default async function fetchCubeDataSlices<
  T extends DeeplyReadonlyWithFunctions<CubeDataSlice[]>,
>(fetchCubeQuery: FetchCubeQuery, slices: T): Promise<SlicesData<T>> {
  return Promise.all(
    slices.map((slice) =>
      fetchCubeQuery(slice.query).then(slice.transformResult),
    ),
  ) as Promise<SlicesData<T>>;
}
