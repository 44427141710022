import { Outlet } from 'react-router-dom';
import {
  ChartMetricSwitcher,
  SelectedFacilityName,
} from '../../../../components';
import { RouteObject } from '../../../types';
import DenialSummary from '../../DenialSummary';
import OpenDenialsTable from './OpenDenialsTable';

export const children: RouteObject[] = [
  {
    index: true,
    element: <DenialSummary />,
    handle: {
      mainActions: <ChartMetricSwitcher />,
    },
  },
  {
    path: 'open-denials',
    element: <OpenDenialsTable />,
    handle: {
      title: [<SelectedFacilityName key='facility' />],
      titleRoot: [['Open Denials']],
      crumb: 'Open Denials',
      activeCubeFilters: [
        'dosMonths',
        'adjudicationReasonCategories',
        'arAges',
      ],
    },
  },
];

export default function OfficeDenialReport() {
  return <Outlet />;
}
