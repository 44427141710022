import { Query } from '@cubejs-client/core';
import { useOutletContext } from 'react-router-dom';

export interface ARReportOutletContext {
  baseQuery: Query;
  pageHeight: number;
}

export function useARReportOutletContext() {
  return useOutletContext<ARReportOutletContext>();
}
