import { Outlet } from 'react-router-dom';
import { ChartMetricSwitcher, SelectedUserName } from '../../../components';
import { useGlobalFilters } from '../../../hooks';
import variables from '../../../styles/variables.module.scss';
import { redirectWithQuery } from '../../../utils';
import { RouteObject } from '../../types';
import ProductivitySummary from '../ProductivitySummary';
import ProductivityByUserTable from './ProductivityByUserTable';

export const childRoutes: RouteObject[] = [
  {
    index: true,
    element: <ProductivityByUserTable />,
    handle: {
      skipBreadcrumbs: true,
    },
  },
  {
    path: ':userId',
    element: <ProductivitySummary />,
    handle: {
      title: [<SelectedUserName key='username' />],
    },
    children: [
      {
        index: true,
        loader: ({ request }) => redirectWithQuery(request, 'monthly'),
      },
      {
        path: ':workedGranularityId',
        handle: ({ params }) => ({
          activeCubeFilters:
            params.workedGranularityId === 'monthly'
              ? ['facilities', 'workedMonth']
              : ['facilities'],
          title: [<WorkedGranularity key='workedGranularity' />],
          crumb: <SelectedNameAndGranularity key='nameAndGranularity' />,
          mainActions: (
            <ChartMetricSwitcher countColor={variables.paletteWorkDark} />
          ),
        }),
      },
    ],
  },
];

export default function ProductivityByUser() {
  return <Outlet />;
}

function SelectedNameAndGranularity() {
  return (
    <>
      <SelectedUserName /> - <WorkedGranularity />
    </>
  );
}

function WorkedGranularity() {
  const { workedGranularity } = useGlobalFilters();
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{workedGranularity.label}</>;
}
