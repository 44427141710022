import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getMatchingId, produceSearchParams } from '../utils';

export default function useChartMetric() {
  const [searchParams, setSearchParams] = useSearchParams();
  const chartMetric =
    getMatchingId(searchParams, 'chartMetric', [
      { id: 'dollar' },
      { id: 'count' },
    ]) ?? 'dollar';
  const setChartMetric = useCallback(
    (newMetric: ChartMetric) =>
      setSearchParams(produceSearchParams({ chartMetric: newMetric })),
    [setSearchParams],
  );
  return { chartMetric, setChartMetric };
}
