import DataCard from './DataCard';
import LoadDataErrorMessage from './LoadDataErrorMessage';

type LoadDataErrorCardProps = { height?: number };
function LoadDataErrorCard(props: LoadDataErrorCardProps) {
  const { height = '100%' } = props;
  return (
    <DataCard
      className='chart-no-data'
      sx={{ height, display: 'grid', placeItems: 'center' }}
    >
      <LoadDataErrorMessage />
    </DataCard>
  );
}

export default LoadDataErrorCard;
