import { DeeplyReadonly, Query } from '@cubejs-client/core';
import { endOfMonth, formatISO, startOfMonth } from 'date-fns';
import { useMemo } from 'react';
import { KPIRow } from '../components';
import { useArMonthRange, useCubeFilter, useCubeQuery } from '../hooks';
import { useReportOutletContext } from '../routes/hooks';

export default function OfficeKPIRow() {
  const { baseQuery } = useReportOutletContext();
  const { start, end } = useArMonthRange();
  const userClaimBaseQuery = useCubeFilter('UserClaim');
  const [activeRangeStartISO, activeRangeEndISO] = useMemo(
    () => [
      formatISO(startOfMonth(start), { representation: 'date' }),
      formatISO(endOfMonth(end), { representation: 'date' }),
    ],
    [start, end],
  );

  const query = {
    ...baseQuery,
    measures: ['ClaimARSnapshot.currentAmountExpectedSum'],
    timeDimensions: [
      {
        dimension: 'ClaimARSnapshot.date',
        dateRange: [
          formatISO(startOfMonth(start), { representation: 'date' }),
          formatISO(endOfMonth(end), { representation: 'date' }),
        ],
        granularity: 'month',
      },
    ],
  } as const satisfies DeeplyReadonly<Query>;

  const closedAR = {
    ...userClaimBaseQuery,
    measures: ['UserClaim.totalAmountExpectedSum'],
    timeDimensions: [
      {
        dimension: 'UserClaim.closedDate',
        dateRange: [activeRangeStartISO, activeRangeEndISO],
        granularity: 'month',
      },
    ],
  } as const satisfies DeeplyReadonly<Query>;

  const paid = {
    ...userClaimBaseQuery,
    measures: ['UserClaim.amountPaidSum'],
    timeDimensions: [
      {
        dimension: 'UserClaim.adjustedPostedDate',
        dateRange: [activeRangeStartISO, activeRangeEndISO],
        granularity: 'month',
      },
    ],
  } as const satisfies DeeplyReadonly<Query>;

  const arAgeAverage = {
    ...query,
    measures: ['ClaimARSnapshot.arAgeAverage'],
  } as const satisfies DeeplyReadonly<Query>;

  const { resultSet: outstandingARResults, error: outstandingARError } =
    useCubeQuery(query);
  const { resultSet: closedARResults, error: closedARError } =
    useCubeQuery(closedAR);
  const { resultSet: paidResults, error: paidError } = useCubeQuery(paid);
  const { resultSet: arAgeAverageResults, error: arAgeAverageError } =
    useCubeQuery(arAgeAverage);

  const kpis: KPIPoint[] = [
    {
      name: 'Outstanding AR',
      type: 'currency',
      value: outstandingARResults?.totalRow()[query.measures[0]],
      error: Boolean(outstandingARError),
    },
    {
      name: 'Closed',
      type: 'currency',
      value: closedARResults?.totalRow()[closedAR.measures[0]],
      error: Boolean(closedARError),
    },
    {
      name: 'Paid',
      type: 'currency',
      value: paidResults?.totalRow()[paid.measures[0]],
      error: Boolean(paidError),
    },
    {
      name: 'Average Open AR Age',
      type: 'number',
      value: arAgeAverageResults?.totalRow()[arAgeAverage.measures[0]],
      error: Boolean(arAgeAverageError),
    },
  ];

  return <KPIRow kpis={kpis.map((kpi) => ({ main: kpi }))} />;
}
