import { useMemoizedObject } from '@insidedesk/tuxedo';
import { useTheme } from '@mui/material';
import Highcharts from 'highcharts';
import { HighchartsReact } from 'highcharts-react-official';
import _ from 'lodash';
import { useMemo } from 'react';
import { CHART_MIN_HEIGHT, CHART_PALETTE } from '../constants';
import DataCard from './DataCard';

export type ChartProps = {
  options: Highcharts.Options;
};
export default function Chart(props: ChartProps) {
  const { options } = props;
  const { spacing } = useTheme();
  const chartOptions: Highcharts.Options = CHART_PALETTE.apply({
    ...options,
    series: options.series && _.cloneDeep(options.series),
  });
  const memoizedChartOptions = useMemoizedObject(chartOptions);
  const containerProps = useMemo(
    () => ({
      style: {
        height: '100%',
        minHeight: `${CHART_MIN_HEIGHT}px`,
        padding: spacing(1.5),
      },
    }),
    [spacing],
  );
  return (
    <DataCard sx={{ height: '100%' }}>
      <HighchartsReact
        containerProps={containerProps}
        highcharts={Highcharts}
        options={memoizedChartOptions}
      />
    </DataCard>
  );
}
