import { Query, QueryRecordType, ResultSet } from '@cubejs-client/core';
import { BlurPHIContext } from '@insidedesk/tuxedo';
import { useContext } from 'react';
import { LoadDataErrorCard, PieChart, QueryRenderer } from '../components';

interface ARAndResponsibilityProps extends ReportOptions {
  colorMap: Record<string, string>;
  labelMap: Record<string, string>;
  responsibilityQuery: Query;
}

export default function ARAndResponsibility(props: ARAndResponsibilityProps) {
  const { colorMap, labelMap, responsibilityQuery } = props;
  const measure =
    responsibilityQuery.measures?.[0] ??
    'ClaimARSnapshot.totalAmountExpectedSum';

  const { blur } = useContext(BlurPHIContext);

  const labelIndices = Object.fromEntries(
    Object.values(labelMap).map((value, index) => [value, index]),
  );
  const transformResult = (result: ResultSet<QueryRecordType<Query>> | null) =>
    result
      ?.chartPivot({
        x: ['WorkflowState.responsibility'],
        y: ['measures'],
      })
      .map((row) => {
        const group = row.xValues[0];
        return {
          name: labelMap[group],
          value: row[measure],
          y: row[measure],
          color: colorMap[group],
        };
      })
      .sort((a, b) => labelIndices[a.name] - labelIndices[b.name]);

  return (
    <QueryRenderer
      query={responsibilityQuery}
      transformResult={transformResult}
      render={(data) => (
        <PieChart
          data={data ?? []}
          title='Responsibility'
          unit='$'
          blur={blur}
        />
      )}
      fallback={<LoadDataErrorCard />}
    />
  );
}
