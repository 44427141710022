import { DeeplyReadonly, Query, ResultSet } from '@cubejs-client/core';
import { Box } from '@mui/material';
import * as d3 from 'd3';
import {
  differenceInBusinessDays,
  endOfMonth,
  formatISO,
  isEqual,
  min,
  startOfMonth,
  sub,
} from 'date-fns';
import React from 'react';
import {
  ColumnLabelMap,
  DataTable,
  DataTableRow,
  DATA_TABLE_KEY,
  LoadDataErrorCard,
  PieChart,
  PieChartProps,
  QueryRenderer,
  RouteGridContainer,
  TrendChart,
} from '../../components';
import {
  arAgeGroupClaimColorMap,
  arAgeGroupLabelMap,
  AR_AGE_BUCKET_OPTIONS,
} from '../../constants';
import { useArMonthRange, useCubeFilter, useFilterOptions } from '../../hooks';
import { tidyRawData } from '../../utils';

export default function WorkedDetails() {
  return (
    <RouteGridContainer
      data-testid='worked-details'
      gridTemplateColumns='1fr 1fr'
    >
      <WorkedL6MTrendChart />
      <Box gridRow='span 2'>
        <WorkedByFacilityTable />
      </Box>
      <WorkedByAgeChart />
    </RouteGridContainer>
  );
}

function WorkedL6MTrendChart() {
  const { start, end } = useArMonthRange();
  const baseQuery = useCubeFilter('ClaimsWorkedByFacility');

  const query = {
    ...baseQuery,
    measures: ['ClaimsWorkedByFacility.claimCount'],
    dimensions: ['ClaimsWorkedByFacility.workedArAgeGroup'],
    timeDimensions: [
      {
        dimension: 'ClaimsWorkedByFacility.workDate',
        dateRange: [
          formatISO(startOfMonth(sub(start, { months: 6 })), {
            representation: 'date',
          }),
          formatISO(endOfMonth(end), { representation: 'date' }),
        ],
        granularity: 'month',
      },
    ],
  } as const satisfies DeeplyReadonly<Query>;

  return (
    <TrendChart<typeof query, 'count'>
      chartMetric='count'
      title={{ count: 'Claims Worked M.O.M' }}
      query={query}
      barConfig={{
        groupOrder: AR_AGE_BUCKET_OPTIONS.map((option) => option.id),
        labelMap: arAgeGroupLabelMap,
        colorMap: arAgeGroupClaimColorMap,
        filterFns: Object.fromEntries(
          AR_AGE_BUCKET_OPTIONS.map((option) => [
            option.id,
            (row) =>
              row['ClaimsWorkedByFacility.workedArAgeGroup'] === option.id,
          ]),
        ),
        measureSpec: { count: 'ClaimsWorkedByFacility.claimCount' },
        excludeEmptyGroups: true,
      }}
    />
  );
}

function WorkedByFacilityTable() {
  const cube = 'ClaimsWorkedByFacility';
  const { facilityOptions } = useFilterOptions();
  const baseQuery = useCubeFilter(cube);
  const { start } = useArMonthRange();

  const thisMonth = startOfMonth(start);
  const lastMonth = sub(startOfMonth(start), { months: 1 });
  const weekdaysMTD = differenceInBusinessDays(
    min([endOfMonth(thisMonth), new Date()]),
    thisMonth,
  );
  const weekdaysThisMonth = differenceInBusinessDays(
    endOfMonth(thisMonth),
    thisMonth,
  );

  const columnLabelMap = {
    facilityName: { label: 'Office', phi: true, defaultSortOrder: 'asc' },
    workedMTD: 'Claims Worked MTD',
    workedLM: 'Claims Worked LM',
    forecastedChange: {
      label: 'Forecasted Change',
      unit: '%',
      showTotal: false,
      maxValue: 200,
    },
  } as const satisfies ColumnLabelMap;

  const query = {
    ...baseQuery,
    measures: [`${cube}.claimCount`],
    dimensions: ['Facility.id'],
    timeDimensions: [
      {
        dimension: `${cube}.workDate`,
        dateRange: [
          formatISO(lastMonth, { representation: 'date' }),
          formatISO(endOfMonth(thisMonth), { representation: 'date' }),
        ],
        granularity: 'month',
      },
    ],
  } as const satisfies DeeplyReadonly<Query>;

  const transformResult = (resultSet: ResultSet | null) => {
    const facilityOptionsById = d3.index(facilityOptions, (option) =>
      String(option.id),
    );
    const data = resultSet ? tidyRawData<typeof query>(resultSet) : [];
    const byFacility = d3.rollup(
      data,
      (rows) => {
        const workedMTD = d3.sum(
          rows.filter((row) => isEqual(row[`${cube}.workDate`], thisMonth)),
          (row) => row[`${cube}.claimCount`],
        );
        const workedLM = d3.sum(
          rows.filter((row) => isEqual(row[`${cube}.workDate`], lastMonth)),
          (row) => row[`${cube}.claimCount`],
        );
        const forecastedWorkedThisMonth =
          (workedMTD / weekdaysMTD) * weekdaysThisMonth;
        const forecastedChange =
          (100 * (forecastedWorkedThisMonth - workedLM)) / workedLM;
        return { workedMTD, workedLM, forecastedChange };
      },
      (row) => row['Facility.id'],
    );
    const tableData = d3.map(
      byFacility.entries(),
      ([facilityId, row]): DataTableRow<keyof typeof columnLabelMap> => ({
        [DATA_TABLE_KEY]: facilityId,
        facilityName: facilityOptionsById.get(facilityId)?.label ?? 'Unknown',
        ...row,
      }),
    );
    return {
      columnLabelMap,
      defaultSortColumn: 'workedMTD' as const,
      data: tableData,
      bordered: true,
    };
  };

  return (
    <QueryRenderer
      query={query}
      transformResult={transformResult}
      render={(tableProps) => <DataTable {...tableProps} />}
      fallback={<LoadDataErrorCard />}
    />
  );
}

function WorkedByAgeChart() {
  const baseQuery = useCubeFilter('ClaimsWorkedByFacility');

  const measure = 'ClaimsWorkedByFacility.claimCount';
  const query: Query = {
    ...baseQuery,
    measures: [measure],
    dimensions: ['ClaimsWorkedByFacility.workedArAgeGroup'],
    segments: [...baseQuery.segments, 'ClaimsWorkedByFacility.l6m'],
  };

  const transformResult = React.useCallback(
    (result: ResultSet | null): Pick<PieChartProps, 'data'> => {
      const data =
        result
          ?.chartPivot()
          .filter((row) => row.x !== 'None')
          // Sort needs to happen before grouping if detailed === false
          .sort((a, b) => {
            if (a.x.endsWith('+')) {
              return 1;
            }
            if (b.x.endsWith('+')) {
              return -1;
            }
            const aVal = parseInt(a.x, 10);
            const bVal = parseInt(b.x, 10);
            return aVal - bVal;
          })
          .map((row) => ({
            name: arAgeGroupLabelMap[row.x],
            color: arAgeGroupClaimColorMap[row.x],
            y: row[measure],
            sliced: row.x.endsWith('+'),
          })) ?? [];

      return { data };
    },
    [],
  );

  return (
    <QueryRenderer
      query={query}
      transformResult={transformResult}
      render={(chartProps) => (
        <PieChart {...chartProps} title='Claims Worked by Age Bucket L6M' />
      )}
      fallback={<LoadDataErrorCard />}
    />
  );
}
