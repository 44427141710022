import { DeeplyReadonly, Query } from '@cubejs-client/core';
import * as d3 from 'd3';
import { ClaimsWorkedCube } from '../../hooks/useClaimsWorkedCube';
import { TidyQueryRecord } from '../../utils';

export type ClaimsWorkedQuery<TCube extends ClaimsWorkedCube> =
  DeeplyReadonly<Query> & {
    readonly measures: readonly [
      `${TCube}.adjustedAmountExpectedSum`,
      `${TCube}.amountPaidSum`,
      `${TCube}.claimClosedCount`,
      `${TCube}.claimCount`,
      `${TCube}.daysToClose`,
      `${TCube}.deletedAdjustedAmountExpectedSum`,
      ...string[],
    ];
    readonly dimensions: readonly [`${TCube}.closed`, ...string[]];
  };

/**
 * Calculate Claims worked data.
 *
 * Ensure the measures in the query used are ordered the same as
 * ClaimWorkedQuery or you may run into type errors.
 */
export function claimsWorkedData<
  TCube extends ClaimsWorkedCube,
  TQuery extends ClaimsWorkedQuery<TCube>,
>(data: TidyQueryRecord<TQuery>[], cube: TCube) {
  const claimsWorked = d3.sum(data, (row) => row[`${cube}.claimCount`]);
  const claimsWorkedOpen = d3.sum(
    data.filter((row) => row[`${cube}.closed`] === `false`),
    (row) => row[`${cube}.claimCount`],
  );
  const closeRateWorked =
    (100 *
      d3.sum(
        data.filter((row) => row[`${cube}.closed`] === `true`),
        (row) => row[`${cube}.claimCount`],
      )) /
    d3.sum(data, (row) => row[`${cube}.claimCount`]);
  const workToClose =
    d3.sum(data, (row) => row[`${cube}.daysToClose`]) /
    d3.sum(data, (row) => row[`${cube}.claimClosedCount`]);
  const amountPaidSum = d3.sum(data, (row) => row[`${cube}.amountPaidSum`]);
  const adjustedAmountExpectedSum = d3.sum(
    data,
    (row) => row[`${cube}.adjustedAmountExpectedSum`],
  );
  const deletedAdjustedAmountExpectedSum = d3.sum(
    data,
    (row) => row[`${cube}.deletedAdjustedAmountExpectedSum`],
  );
  const claimYieldWorked = amountPaidSum / adjustedAmountExpectedSum;
  const estimatedPosted = claimYieldWorked * deletedAdjustedAmountExpectedSum;
  const dollarsPostedWorked = amountPaidSum + estimatedPosted;
  return {
    claimsWorked,
    claimsWorkedOpen,
    closeRateWorked,
    workToClose,
    dollarsPostedWorked,
    claimYieldWorked: claimYieldWorked * 100,
  };
}
