import { BreadcrumbTitle, isNotNullish } from '@insidedesk/tuxedo';
import { PersonalVideoOutlined } from '@mui/icons-material';
import { ReactNode } from 'react';
import { To, useLocation } from 'react-router-dom';
import { useMatches, useTitleFragments } from '../hooks';
import { RouteHandle } from '../routes/types';
import { randomKeys } from '../utils';

export default function MainTitle() {
  const titleText = useTitleText();
  const matches = useMatches();
  const handle = matches
    .map((match) => match.handle)
    .filter(isNotNullish)
    /**
     * XXX: Indexing an array can return undefined if it's empty, but turning on
     * noUncheckedIndexedAccess causes a bajillion compiler errors at the
     * moment. Typecasting here to keep this file typesafe until we can turn
     * that on.
     */
    .slice(-1)[0] as RouteHandle | undefined;
  const actions = handle?.titleActions;

  const { search } = useLocation();

  let breadcrumbs: { to: To; crumb: ReactNode }[] = [];
  if (handle && !handle.skipBreadcrumbs) {
    breadcrumbs = matches
      .slice(1)
      .map((match) => {
        const { pathname } = match;
        const { crumb } = (match.handle as RouteHandle | undefined) ?? {};

        if (!crumb) return null;

        return {
          to: { pathname, search },
          crumb,
        };
      })
      .filter(isNotNullish);
  }

  return (
    <BreadcrumbTitle
      text={randomKeys(titleText)}
      breadcrumbs={breadcrumbs.length > 1 ? breadcrumbs : undefined}
      icon={<PersonalVideoOutlined />}
      backTo={breadcrumbs[breadcrumbs.length - 2]?.to}
      actions={actions}
    />
  );
}

function useTitleText() {
  return useTitleFragments('IQ')
    .map((fragment, index, arr) =>
      index === arr.length - 1 ? fragment : [...fragment, '', ' - '],
    )
    .flat();
}
