import { Query } from '@cubejs-client/core';
import {
  add,
  differenceInBusinessDays,
  endOfMonth,
  formatISO,
  startOfMonth,
  sub,
} from 'date-fns';

import { KPIRow, KPIRowProps } from '../components';
import { useArMonthRange, useCubeFilter, useCubeQuery } from '../hooks';
import { formatNumber } from '../utils';

export default function ARKPIRow(props: { baseQuery: Query }) {
  const { baseQuery } = props;
  const metric = 'amount';

  const { start, end } = useArMonthRange();

  const userClaimFilters = useCubeFilter('UserClaim');
  const rollingCollectionFilters = useCubeFilter('UserClaim', {
    useFiltersInsteadOfSegments: true,
  });
  const claimsWorkedFilters = useCubeFilter('ClaimsWorkedByFacility');

  const commonMeasures = [
    metric === 'amount'
      ? 'ClaimARSnapshot.currentAmountExpectedSum'
      : 'ClaimARSnapshot.count',
  ];
  const currentAR: Query = {
    ...baseQuery,
    measures: commonMeasures,
    timeDimensions: [
      {
        dimension: 'ClaimARSnapshot.date',
        dateRange: [
          formatISO(startOfMonth(start), { representation: 'date' }),
          formatISO(endOfMonth(end), { representation: 'date' }),
        ],
        granularity: 'month',
      },
    ],
  };
  const lastMonthAR: Query = {
    ...baseQuery,
    measures: commonMeasures,
    timeDimensions: [
      {
        dimension: 'ClaimARSnapshot.date',
        dateRange: [
          formatISO(startOfMonth(sub(start, { months: 1 })), {
            representation: 'date',
          }),
          formatISO(endOfMonth(sub(end, { months: 1 })), {
            representation: 'date',
          }),
        ],
        granularity: 'month',
      },
    ],
  };

  const collectedMTDQuery: Query = {
    ...userClaimFilters,
    measures: ['UserClaim.amountPaidSum'],
    timeDimensions: [
      {
        dimension: 'UserClaim.adjustedPostedDate',
        dateRange: [
          formatISO(startOfMonth(start), { representation: 'date' }),
          formatISO(endOfMonth(end), { representation: 'date' }),
        ],
      },
    ],
  };
  const rollingCollectionQuery: Query = {
    ...rollingCollectionFilters,
    measures: [
      'UserClaim.ninetyDayCollectionSum',
      'UserClaim.thirtyDayCollectionSum',
    ],
    timeDimensions: [
      {
        dimension: 'UserClaim.adjustedPostedDate',
        dateRange: formatISO(end, { representation: 'date' }),
        granularity: 'day',
      },
    ],
  };
  const collectedLMQuery: Query = {
    ...userClaimFilters,
    measures: ['UserClaim.amountPaidSum'],
    timeDimensions: [
      {
        dimension: 'UserClaim.adjustedPostedDate',
        dateRange: [
          formatISO(sub(start, { months: 1 }), { representation: 'date' }),
          formatISO(endOfMonth(sub(end, { months: 1 })), {
            representation: 'date',
          }),
        ],
      },
    ],
  };

  const collectedSixMonthsQuery: Query = {
    measures: ['UserClaim.amountPaidSum'],
    timeDimensions: [
      {
        dimension: 'UserClaim.adjustedPostedDate',
        dateRange: [
          formatISO(startOfMonth(sub(start, { months: 7 })), {
            representation: 'date',
          }),
          formatISO(endOfMonth(end), { representation: 'date' }),
        ],
        granularity: 'month',
      },
    ],
    filters: userClaimFilters.filters,
    segments: userClaimFilters.segments,
  };
  const producedSixMonthsQuery: Query = {
    measures: ['UserClaim.totalAmountExpectedSum'],
    timeDimensions: [
      {
        dimension: 'UserClaim.serviceDateStart',
        dateRange: [
          formatISO(startOfMonth(sub(start, { months: 7 })), {
            representation: 'date',
          }),
          formatISO(endOfMonth(end), { representation: 'date' }),
        ],
        granularity: 'month',
      },
    ],
    filters: userClaimFilters.filters,
    segments: userClaimFilters.segments,
  };

  const claimYieldQuery: Query = {
    ...userClaimFilters,
    measures: [
      'UserClaim.amountPaidSum',
      'UserClaim.paidAdjustedAmountExpectedSum',
    ],
    timeDimensions: [
      {
        dimension: 'UserClaim.adjustedPostedDate',
        dateRange: [
          formatISO(startOfMonth(sub(start, { months: 7 })), {
            representation: 'date',
          }),
          formatISO(endOfMonth(end), { representation: 'date' }),
        ],
        granularity: 'month',
      },
    ],
  };

  const collected90DaysQuery: Query = {
    measures: ['UserClaim.amountPaidSum'],
    timeDimensions: [
      {
        dimension: 'UserClaim.adjustedPostedDate',
        dateRange: [
          formatISO(sub(end, { days: 89 }), { representation: 'date' }),
          formatISO(end, { representation: 'date' }),
        ],
      },
    ],
    filters: userClaimFilters.filters,
    segments: userClaimFilters.segments,
  };

  const arAgeAverageQuery: Query = {
    ...baseQuery,
    measures: ['ClaimARSnapshot.arAgeSum', 'ClaimARSnapshot.countLessThanYear'],
  };

  const closedAgeAverageQuery: Query = {
    measures: ['UserClaim.arAgeSum', 'UserClaim.countLessThanYear'],
    timeDimensions: [
      {
        dimension: 'UserClaim.closedDate',
        dateRange: [
          formatISO(startOfMonth(start), { representation: 'date' }),
          formatISO(endOfMonth(end), { representation: 'date' }),
        ],
        granularity: 'month',
      },
    ],
    filters: userClaimFilters.filters,
    segments: userClaimFilters.segments,
  };

  const workedQuery: Query = {
    measures: ['ClaimsWorkedByFacility.claimCount'],
    timeDimensions: [
      {
        dimension: 'ClaimsWorkedByFacility.workDate',
        dateRange: [
          formatISO(startOfMonth(start), { representation: 'date' }),
          formatISO(endOfMonth(end), { representation: 'date' }),
        ],
      },
    ],
    filters: claimsWorkedFilters.filters,
    segments: claimsWorkedFilters.segments,
  };

  const workedLastMonthQuery: Query = {
    measures: ['ClaimsWorkedByFacility.claimCount'],
    timeDimensions: [
      {
        dimension: 'ClaimsWorkedByFacility.workDate',
        dateRange: [
          formatISO(startOfMonth(sub(start, { months: 1 })), {
            representation: 'date',
          }),
          formatISO(endOfMonth(sub(end, { months: 1 })), {
            representation: 'date',
          }),
        ],
      },
    ],
    filters: claimsWorkedFilters.filters,
    segments: claimsWorkedFilters.segments,
  };

  const { resultSet: arResultSet, error: arError } = useCubeQuery(currentAR);

  const { resultSet: lastMonthARResultSet, error: lastMonthARError } =
    useCubeQuery(lastMonthAR);

  const { resultSet: collectedMTDResult, error: collectedMTDError } =
    useCubeQuery(collectedMTDQuery);

  const { resultSet: rollingCollectionResult, error: rollingCollectionError } =
    useCubeQuery(rollingCollectionQuery);

  const { resultSet: collectedLMResult, error: collectedLMError } =
    useCubeQuery(collectedLMQuery);

  const { resultSet: claimNCRResultSet, error: claimNCRError } = useCubeQuery([
    collectedSixMonthsQuery,
    producedSixMonthsQuery,
  ]);

  const { resultSet: claimYieldResultSet, error: claimYieldError } =
    useCubeQuery(claimYieldQuery);

  const { resultSet: collected90DaysResultSet, error: collected90DaysError } =
    useCubeQuery(collected90DaysQuery);

  const { resultSet: arAgeAverageResultSet, error: arAgeAverageError } =
    useCubeQuery(arAgeAverageQuery);

  const { resultSet: closedAgeAverageResultSet } = useCubeQuery(
    closedAgeAverageQuery,
  );

  const { resultSet: workedResultSet, error: workedError } =
    useCubeQuery(workedQuery);

  const { resultSet: workedLastMonthResultSet, error: workedLastMonthError } =
    useCubeQuery(workedLastMonthQuery);

  const claimAR = arResultSet?.totalRow()[currentAR.measures?.[0] ?? ''];
  const claimARLastMonth =
    lastMonthARResultSet?.totalRow()[lastMonthAR.measures?.[0] ?? ''];
  const claimARDiff = (100.0 * (claimAR - claimARLastMonth)) / claimARLastMonth;
  let claimARHighlight: Highlight | undefined;
  if (claimARDiff <= -3) {
    claimARHighlight = 'good';
  } else if (-3 < claimARDiff && claimARDiff <= 3) {
    claimARHighlight = 'neutral';
  } else if (3 < claimARDiff && claimARDiff <= 8) {
    claimARHighlight = 'warning';
  } else if (claimARDiff > 8) {
    claimARHighlight = 'bad';
  }

  const collectedMTD =
    collectedMTDResult?.totalRow()[collectedMTDQuery.measures![0]];
  const collectedLast90 =
    rollingCollectionResult?.totalRow()[rollingCollectionQuery.measures![0]];
  const workDays90 = differenceInBusinessDays(
    add(end, { days: 1 }),
    sub(end, { days: 89 }),
  );
  const dailyCollection90 = collectedLast90 / workDays90;

  const collectedLast30 =
    rollingCollectionResult?.totalRow()[rollingCollectionQuery.measures![1]];
  const workDays30 = differenceInBusinessDays(
    add(end, { days: 1 }),
    sub(end, { days: 29 }),
  );
  const dailyCollection30 = collectedLast30 / workDays30;

  const avgDailyCollection = (dailyCollection90 + dailyCollection30) / 2;
  const workDaysRemaining = differenceInBusinessDays(endOfMonth(end), end);
  const collectionForecast =
    collectedMTD + avgDailyCollection * workDaysRemaining;
  const collectedLM =
    collectedLMResult?.totalRow()[collectedLMQuery.measures![0]];
  const collectedDiff =
    (100.0 * (collectionForecast - collectedLM)) / collectedLM;
  let collectedHighlight: Highlight | undefined;
  if (collectedDiff < -20) {
    collectedHighlight = 'bad';
  } else if (-20 <= collectedDiff && collectedDiff < -10) {
    collectedHighlight = 'warning';
  } else if (-10 <= collectedDiff && collectedDiff < 10) {
    collectedHighlight = 'neutral';
  } else if (collectedDiff > 10) {
    collectedHighlight = 'good';
  }

  const collectedSixMonths =
    claimNCRResultSet
      ?.chartPivot()
      .slice(1)
      .reduce(
        (total, current) =>
          total + current[collectedSixMonthsQuery.measures?.[0] ?? ''] ?? 0,
        0,
      ) ?? 0;
  const producedSixMonths =
    claimNCRResultSet
      ?.chartPivot()
      .slice(1)
      .reduce(
        (total, current) =>
          total + current[producedSixMonthsQuery.measures?.[0] ?? ''],
        0,
      ) ?? 0;
  const claimNCR = (100.0 * collectedSixMonths) / producedSixMonths;
  const collectedL6M =
    claimNCRResultSet
      ?.chartPivot()
      .slice(0, -1)
      .reduce(
        (total, current) =>
          total + current[collectedSixMonthsQuery.measures?.[0] ?? ''] ?? 0,
        0,
      ) ?? 0;
  const producedL6M =
    claimNCRResultSet
      ?.chartPivot()
      .slice(0, -1)
      .reduce(
        (total, current) =>
          total + current[producedSixMonthsQuery.measures?.[0] ?? ''],
        0,
      ) ?? 0;
  const claimNCRL6M = (100.0 * collectedL6M) / producedL6M;
  const claimNCRDiff = (100.0 * (claimNCR - claimNCRL6M)) / claimNCRL6M;
  let claimNCRHighlight: Highlight | undefined;
  if (claimNCRDiff < -8) {
    claimNCRHighlight = 'bad';
  } else if (-8 < claimNCRDiff && claimNCRDiff <= -3) {
    claimNCRHighlight = 'warning';
  } else if (-3 < claimNCRDiff && claimNCRDiff <= 3) {
    claimNCRHighlight = 'neutral';
  } else if (claimNCRDiff >= 3) {
    claimNCRHighlight = 'good';
  }

  const claimPaid =
    claimYieldResultSet
      ?.chartPivot()
      .slice(1)
      .reduce(
        (total, current) =>
          total + current[claimYieldQuery.measures?.[0] ?? ''],
        0,
      ) ?? 0;
  const claimExpected =
    claimYieldResultSet
      ?.chartPivot()
      .slice(1)
      .reduce(
        (total, current) =>
          total + current[claimYieldQuery.measures?.[1] ?? ''],
        0,
      ) ?? 0;
  const claimYield = (100.0 * claimPaid) / claimExpected;
  const claimPaidLM =
    claimYieldResultSet
      ?.chartPivot()
      .slice(0, -1)
      .reduce(
        (total, current) =>
          total + current[claimYieldQuery.measures?.[0] ?? ''],
        0,
      ) ?? 0;
  const claimExpectedLM =
    claimYieldResultSet
      ?.chartPivot()
      .slice(0, -1)
      .reduce(
        (total, current) =>
          total + current[claimYieldQuery.measures?.[1] ?? ''],
        0,
      ) ?? 0;
  const claimYieldLM = (100.0 * claimPaidLM) / claimExpectedLM;
  const claimYieldDiff = (100.0 * (claimYield - claimYieldLM)) / claimYieldLM;
  let claimYieldHighlight: Highlight | undefined;
  if (claimYieldDiff < -8) {
    claimYieldHighlight = 'bad';
  } else if (-8 < claimYieldDiff && claimYieldDiff <= -3) {
    claimYieldHighlight = 'warning';
  } else if (-3 < claimYieldDiff && claimYieldDiff <= 3) {
    claimYieldHighlight = 'neutral';
  } else if (claimYieldDiff >= 3) {
    claimYieldHighlight = 'good';
  }

  const collected90Days =
    collected90DaysResultSet?.totalRow()[
      collected90DaysQuery.measures?.[0] ?? ''
    ];
  const workDays = differenceInBusinessDays(end, sub(end, { days: 90 }));
  const collectionPerDay = collected90Days / workDays;
  const collectionDays = claimAR / collectionPerDay;

  const arAgeSum =
    arAgeAverageResultSet?.totalRow()[arAgeAverageQuery.measures?.[0] ?? ''];
  const arAgeCount =
    arAgeAverageResultSet?.totalRow()[arAgeAverageQuery.measures?.[1] ?? ''];
  const closedAgeSum =
    closedAgeAverageResultSet?.totalRow()[
      closedAgeAverageQuery.measures?.[0] ?? ''
    ];
  const closedAgeCount =
    closedAgeAverageResultSet?.totalRow()[
      closedAgeAverageQuery.measures?.[1] ?? ''
    ];
  const arAgeAverage =
    (arAgeSum + closedAgeSum) / (arAgeCount + closedAgeCount);

  const worked = workedResultSet?.totalRow()[workedQuery.measures?.[0] ?? ''];
  const workedLastMonth =
    workedLastMonthResultSet?.totalRow()[
      workedLastMonthQuery.measures?.[0] ?? ''
    ];
  const weekdaysInMonth = differenceInBusinessDays(
    endOfMonth(end),
    startOfMonth(start),
  );
  const weekdaysMTD = differenceInBusinessDays(end, startOfMonth(start));
  const forecastedWorkedThisMonth = (worked / weekdaysMTD) * weekdaysInMonth;
  const forecastedChange =
    (100.0 * (forecastedWorkedThisMonth - workedLastMonth)) / workedLastMonth;
  let forecastHighlight: Highlight | undefined;
  if (forecastedChange < -20) {
    forecastHighlight = 'bad';
  } else if (-20 <= forecastedChange && forecastedChange < -10) {
    forecastHighlight = 'warning';
  } else if (-10 <= forecastedChange && forecastedChange < 10) {
    forecastHighlight = 'neutral';
  } else if (forecastedChange > 10) {
    forecastHighlight = 'good';
  }

  const kpis: KPIRowProps['kpis'] = [
    {
      main: {
        name: 'Claim AR',
        value: claimAR,
        error: !!arError,
        type: 'currency',
      },
      secondary: {
        name: '% Change vs LM',
        value: claimARDiff,
        error: !!arError || !!lastMonthARError,
        type: 'percent',
        highlight: claimARHighlight,
        status: claimARDiff > 0 ? 'up' : 'down',
      },
      navigation: { location: 'ar-details', tooltip: 'AR Details' },
    },
    {
      main: {
        name: 'Collected $ MTD',
        value: collectedMTD,
        error: !!collectedMTDError,
        type: 'currency',
      },
      secondary: {
        name: 'Forecasted Change',
        value: collectedDiff,
        error:
          !!collectedMTDError || !!collectedLMError || !!rollingCollectionError,
        type: 'percent',
        highlight: collectedHighlight,
        status: collectedDiff > 0 ? 'up' : 'down',
      },
      navigation: {
        location: 'collection-details',
        tooltip: 'Collection Details',
      },
    },
    [
      {
        main: {
          name: 'Claim NCR Last 6 Months',
          value: claimNCR,
          error: !!claimNCRError,
          type: 'percent',
        },
        secondary: {
          name: '% Change vs LM',
          value: claimNCRDiff,
          error: !!claimNCRError,
          type: 'percent',
          highlight: claimNCRHighlight,
          status: claimNCRDiff > 0 ? 'up' : 'down',
        },
        navigation: { location: 'ncr-details', tooltip: 'NCR Details' },
      },
      {
        main: {
          name: 'Claim Yield Last 6 Months',
          value: claimYield,
          error: !!claimYieldError,
          type: 'percent',
        },
        secondary: {
          name: '% Change vs LM',
          value: claimYieldDiff,
          error: !!claimYieldError,
          type: 'percent',
          highlight: claimYieldHighlight,
          status: claimYieldDiff > 0 ? 'up' : 'down',
        },
        navigation: { location: 'ncr-details', tooltip: 'NCR Details' },
      },
    ],
    {
      main: {
        name: 'Collection Days',
        value: collectionDays,
        error: !!collected90DaysError,
      },
      secondary: {
        name: 'Claim AR Age',
        value: arAgeAverage,
        error: !!arAgeAverageError,
      },
      navigation: {
        location: 'collection-days-details',
        tooltip: 'Collection Days details',
      },
    },
    {
      main: {
        name: 'Worked MTD',
        value: worked,
        error: !!workedError,
        formatter: (value) => {
          if (value < 10_000) {
            return value.toString();
          }
          return formatNumber(value, { decimalPlaces: 1 });
        },
      },
      secondary: {
        name: 'Forecasted Change',
        value: forecastedChange,
        error: !!workedError || !!workedLastMonthError,
        type: 'percent',
        highlight: forecastHighlight,
        status: forecastedChange > 0 ? 'up' : 'down',
      },
      navigation: { location: 'worked-details', tooltip: 'Worked Details' },
    },
  ];

  return <KPIRow kpis={kpis} />;
}
