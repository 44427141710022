import * as d3 from 'd3';
import { ComposedChart, LoadDataErrorCard, useTrendChart } from '../components';
import { claimResolvedLabelMap } from '../constants';
import {
  useChartMetric,
  useClaimsWorkedCube,
  useCubeFilter,
  useLoader,
} from '../hooks';
import { fetchCubeDataSlices } from '../utils';

export default function WorkEventsTrendChart() {
  const { chartMetric } = useChartMetric();
  const dollarChart = useDollarChart();
  const countChart = useCountChart();

  const result = useLoader({
    async loader({ fetchCubeQuery }) {
      const [dollarProps, countProps] = await fetchCubeDataSlices(
        fetchCubeQuery,
        [dollarChart, countChart],
      );
      return chartMetric === 'dollar' ? dollarProps : countProps;
    },
    default() {
      return chartMetric === 'dollar'
        ? dollarChart.transformResult(null)
        : countChart.transformResult(null);
    },
    loaderKey: [dollarChart.query, countChart.query],
    loaderSoftKey: [chartMetric],
  });

  if (result.error) return <LoadDataErrorCard />;
  return <ComposedChart {...result.data} />;
}

function useDollarChart() {
  const cube = useClaimsWorkedCube();
  const segment = 'Facility.activeOnly';
  const dimension = `${cube}.closed`;
  const measure = `${cube}.adjustedAmountExpectedSum`;

  let baseQuery = useCubeFilter(cube, {
    useTimeDimensionsInsteadOfSegments: true,
    hasGranularity: true,
  });

  baseQuery = {
    ...baseQuery,
    segments: [...baseQuery.segments, segment],
  };
  const query = {
    ...baseQuery,
    measures: [measure],
    dimensions: [dimension],
  } as const satisfies QueryWithTimeDimension;

  return useTrendChart<typeof query, 'dollar'>({
    title: { dollar: 'Close Rate: Worked by $ Expected' },
    chartMetric: 'dollar',
    query,
    trendLine: {
      label: '% Close Rate',
      transformData: (rows) => {
        const closed = rows.filter((row) => row[dimension] === 'true');
        const closedRate =
          (100 * d3.sum(closed, (row) => row[measure])) /
          d3.sum(rows, (row) => row[measure]);
        return closedRate;
      },
    },
    barConfig: {
      groupOrder: WORK_EVENTS_BAR_GROUP_ORDER,
      filterFns: Object.fromEntries(
        WORK_EVENTS_BAR_GROUP_ORDER.map((group) => [
          group,
          (row) => row[dimension] === group,
        ]),
      ),
      measureSpec: { dollar: measure },
      labelMap: claimResolvedLabelMap,
    },
  });
}

function useCountChart() {
  const cube = 'WorkDoneByUser';
  const segment = `${cube}.workedOnly`;
  const dimension = `${cube}.closed`;
  const measure = `${cube}.claimCount`;

  let baseQuery = useCubeFilter(cube, {
    useTimeDimensionsInsteadOfSegments: true,
    hasGranularity: true,
  });

  baseQuery = {
    ...baseQuery,
    segments: [...baseQuery.segments, segment],
  };
  const query = {
    ...baseQuery,
    measures: [measure],
    dimensions: [dimension],
  } as const satisfies QueryWithTimeDimension;

  return useTrendChart<typeof query, 'count'>({
    title: { count: 'Close Rate: Worked by # Work Events' },
    chartMetric: 'count',
    query,
    trendLine: {
      label: '% Close Rate',
      color: '#a6cee3',
      transformData: (rows) => {
        const closed = rows.filter((row) => row[dimension] === 'true');
        const closedRate =
          (100 * d3.sum(closed, (row) => row[measure])) /
          d3.sum(rows, (row) => row[measure]);
        return closedRate;
      },
    },
    barConfig: {
      groupOrder: WORK_EVENTS_BAR_GROUP_ORDER,
      filterFns: Object.fromEntries(
        WORK_EVENTS_BAR_GROUP_ORDER.map((group) => [
          group,
          (row) => row[dimension] === group,
        ]),
      ),
      measureSpec: { count: measure },
      unitSpec: { count: 'work' },
      labelMap: claimResolvedLabelMap,
    },
  });
}

const WORK_EVENTS_BAR_GROUP_ORDER = ['true', 'false'];
