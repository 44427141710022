import { ComposedChart, LoadDataErrorCard, QueryRenderer } from '..';
import { UseTrendChartOptions } from './types';
import useTrendChart from './useTrendChart';

export default function TrendChart<
  TQuery extends QueryWithTimeDimension,
  TChartMetric extends ChartMetric,
>(props: UseTrendChartOptions<TQuery, TChartMetric>) {
  const { query, transformResult, queryKey } = useTrendChart(props);

  return (
    <QueryRenderer
      query={query}
      queryKey={queryKey}
      transformResult={transformResult}
      render={(chartProps) => <ComposedChart {...chartProps} />}
      fallback={<LoadDataErrorCard />}
    />
  );
}
