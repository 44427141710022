import { ChartPivotRow, QueryRecordType, ResultSet } from '@cubejs-client/core';
import { capitalizeWord } from '@insidedesk/tuxedo';
import * as d3 from 'd3';
import { parseISO } from 'date-fns';
import { SeriesColumnOptions } from 'highcharts';
import { useCallback } from 'react';
import invariant from 'tiny-invariant';
import {
  ComposedChart,
  ComposedChartProps,
  LoadDataErrorCard,
  QueryRenderer,
} from '../components';
import { useCubeFilter } from '../hooks';
import { periodically } from '../utils';

interface TouchEventsRow extends ChartPivotRow {
  x: string;
  xValues: ['VIEW_ONLY' | 'CLAIM_WORKED', string];
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'WorkDoneByUser.claimCount': number;
}

export default function TouchEventsTrendChart() {
  const filters = useCubeFilter('WorkDoneByUser', {
    useTimeDimensionsInsteadOfSegments: true,
    hasGranularity: true,
  });

  const query = {
    measures: ['WorkDoneByUser.claimCount'],
    dimensions: ['WorkDoneByUser.workTypeGroup'],
    ...filters,
  };

  invariant(
    query.timeDimensions[0] !== undefined,
    'Must use at least one timeDimension in TouchEventsTrendChart query',
  );
  invariant(
    query.timeDimensions[0].dimension !== undefined,
    'Mis-formed timeDimension in TouchEventsTrendChart query',
  );
  invariant(
    query.timeDimensions[0].granularity !== undefined,
    'Mis-formed timeDimension in TouchEventsTrendChart query',
  );
  const timeGranularity = query.timeDimensions[0].granularity;

  const transformResult = useCallback(
    (
      result: ResultSet<QueryRecordType<typeof query>> | null,
    ): Partial<ComposedChartProps> => {
      const title = `Touch Events ${capitalizeWord(
        periodically(timeGranularity),
      )}`;

      if (result === null) {
        return { title, timeGranularity };
      }

      const data = result.chartPivot({
        x: ['WorkDoneByUser.workTypeGroup', 'WorkDoneByUser.workDate'],
        y: ['measures'],
      }) as TouchEventsRow[];

      const grouped = d3.group(data, (value) => value.xValues[0]);

      const formatData = (value: TouchEventsRow) => ({
        x: parseISO(value.xValues[1]).getTime(),
        y: value['WorkDoneByUser.claimCount'],
      });
      const viewOnlyData = grouped.get('VIEW_ONLY')?.map(formatData) ?? [];

      const workedEvents = grouped.get('CLAIM_WORKED')?.map(formatData) ?? [];
      return {
        title,
        bars: [
          {
            data: workedEvents,
            displayUnit: 'work',
            name: '# Worked Events',
            type: 'column',
          },
          {
            data: viewOnlyData,
            displayUnit: 'work',
            name: '# View Only Events',
            type: 'column',
          },
        ] satisfies ChartDataSeries<SeriesColumnOptions>[],
        xAxis: { type: 'datetime' },
        timeGranularity,
      };
    },
    [timeGranularity],
  );

  return (
    <QueryRenderer
      query={query}
      transformResult={transformResult}
      render={(chartProps) => (
        <ComposedChart
          {...chartProps}
          stack='normal'
          leftAxis={{ reversedStacks: false }}
        />
      )}
      fallback={<LoadDataErrorCard />}
    />
  );
}
