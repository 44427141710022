import { ChartPivotRow, Query, ResultSet } from '@cubejs-client/core';
import { Box } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { endOfMonth, formatISO, parseISO, startOfMonth, sub } from 'date-fns';
import { PointOptionsObject } from 'highcharts';
import { clone } from 'ramda';
import React from 'react';
import {
  ComposedChart,
  LoadDataErrorCard,
  NavigationWrapper,
  QueryRenderer,
  RouteGridContainer,
} from '../../components';
import { arAgeGroupDollarColorMap, arAgeGroupLabelMap } from '../../constants';
import {
  useArMonthRange,
  useCubeFilter,
  useFacilityFilterOptionsQueryOpts,
} from '../../hooks';
import {
  ARAndRCMStatus,
  ARByAge,
  CleanClaims,
  DataByFacility,
} from '../../reports';
import { useARReportOutletContext } from './hooks';

export default function ARReportIndex() {
  const { baseQuery } = useARReportOutletContext();
  const { data: facilities } = useQuery(useFacilityFilterOptionsQueryOpts());
  const { start, end } = useArMonthRange();

  const userClaimFilters = useCubeFilter('UserClaim');

  const rcmStatusQuery: Query = {
    ...baseQuery,
    measures: ['ClaimARSnapshot.currentAmountExpectedSum'],
    dimensions: ['ClaimARSnapshot.rcmStatus'],
  };

  const arByFacilityQuery: Query = React.useMemo(
    () => ({
      ...baseQuery,
      measures: ['ClaimARSnapshot.currentAmountExpectedSum'],
      dimensions: ['ClaimARSnapshot.facilityID', 'ClaimARSnapshot.arAgeGroup'],
    }),
    [baseQuery],
  );

  const startDate = React.useMemo(
    () =>
      formatISO(startOfMonth(sub(start, { months: 6 })), {
        representation: 'date',
      }),
    [start],
  );
  const endDate = React.useMemo(
    () => formatISO(endOfMonth(end), { representation: 'date' }),
    [end],
  );
  const collectionMOM: Query = {
    ...userClaimFilters,
    measures: ['UserClaim.amountPaidSum'],
    timeDimensions: [
      {
        dimension: 'UserClaim.adjustedPostedDate',
        dateRange: [startDate, endDate],
        granularity: 'month',
      },
    ],
  };
  const productionMOM: Query = {
    ...userClaimFilters,
    measures: ['UserClaim.totalAmountExpectedSum'],
    timeDimensions: [
      {
        dimension: 'UserClaim.serviceDateStart',
        dateRange: [startDate, endDate],
        granularity: 'month',
      },
    ],
  };

  const transformCollectionVSProduction = React.useCallback(
    (resultSet: ResultSet<number> | null) => {
      const collectionRateData: Partial<Highcharts.Point>[] = [];
      const collectionData: Partial<Highcharts.Point>[] = [];
      const productionData: Partial<Highcharts.Point>[] = [];
      resultSet?.chartPivot().forEach((row: ChartPivotRow) => {
        const xValue = parseISO(row.x).getTime();
        const collection: number = row[collectionMOM.measures?.[0] ?? ''];
        const production: number = row[productionMOM.measures?.[0] ?? ''];
        collectionData.push({
          x: xValue,
          y: collection,
        });
        productionData.push({
          x: xValue,
          y: production,
        });
        collectionRateData.push({
          x: xValue,
          y: (collection / production) * 100.0,
        });
      });
      return {
        collection: collectionData,
        production: productionData,
        ncr: collectionRateData,
      };
    },
    [collectionMOM.measures, productionMOM.measures],
  );

  return (
    <RouteGridContainer
      data-testid='ar-report'
      gridTemplateColumns='repeat(6, 1fr)'
    >
      <Box gridColumn='span 2'>
        <NavigationWrapper
          location='claim-ar-age-details'
          tooltip='Claim AR Age Details'
          tooltipPlacement='top'
        >
          <ARByAge query={baseQuery} />
        </NavigationWrapper>
      </Box>
      <Box gridColumn='span 2'>
        <ARAndRCMStatus rcmStatusQuery={rcmStatusQuery} />
      </Box>
      <Box gridColumn='span 2'>
        <NavigationWrapper
          location='clean-claim-details'
          tooltip='Clean Claim Details'
          tooltipPlacement='top'
        >
          <CleanClaims query={baseQuery} />
        </NavigationWrapper>
      </Box>
      <Box gridColumn='span 3'>
        <DataByFacility
          barSize={40}
          grouped
          labelMap={arAgeGroupLabelMap}
          colorMap={arAgeGroupDollarColorMap}
          facilities={facilities ?? []}
          title='Claim AR by Facility and AR Age Bucket'
          transformData={(data) => {
            const newData = clone(data).sort((a, b) => {
              if (a?.name?.endsWith('+')) {
                return 1;
              }
              if (b?.name?.endsWith('+')) {
                return -1;
              }
              const aVal = parseInt(a?.name?.match(/\d+/)?.[0] ?? '', 10);
              const bVal = parseInt(b?.name?.match(/\d+/)?.[0] ?? '', 10);
              const diff = aVal - bVal;
              if (diff === 0) {
                if (a.name?.startsWith('<')) {
                  return -1;
                }
                if (b.name?.startsWith('<')) {
                  return 1;
                }
              }

              return diff;
            });

            const labels = Object.values(arAgeGroupLabelMap);
            const oldestGroup = newData.find(
              (group) => group.name === labels[labels.length - 1],
            );
            const secondOldestGroup = newData.find(
              (group) => group.name === labels[labels.length - 2],
            );
            const secondOldestLabel = labels[labels.length - 2];
            const secondOldestNewLabel = `${secondOldestLabel.split('-')[0]}+`;

            if (oldestGroup && secondOldestGroup) {
              secondOldestGroup.name = secondOldestNewLabel;
              secondOldestGroup.data = secondOldestGroup?.data?.map((point) => {
                const newPoint = clone(point) as PointOptionsObject;

                if (newPoint.name === undefined) {
                  return newPoint;
                }

                const olderData = oldestGroup?.data?.find((oldnewPoint) => {
                  const oldestGroupPoint = oldnewPoint as PointOptionsObject;
                  return oldestGroupPoint?.name === newPoint.name;
                });

                if (
                  olderData !== null &&
                  typeof olderData === 'object' &&
                  'y' in olderData
                ) {
                  newPoint.y = (newPoint?.y ?? 0) + (olderData?.y ?? 0);
                }
                return newPoint;
              });
              newData.pop();
            } else if (oldestGroup) {
              oldestGroup.name = secondOldestNewLabel;
            } else if (secondOldestGroup) {
              secondOldestGroup.name = secondOldestNewLabel;
            }

            return newData;
          }}
          query={arByFacilityQuery}
        />
      </Box>
      <Box gridColumn='span 3'>
        <QueryRenderer
          query={[collectionMOM, productionMOM]}
          transformResult={transformCollectionVSProduction}
          render={(data) => (
            <ComposedChart
              title='Collection vs Production M.O.M.'
              bars={[
                {
                  type: 'column',
                  name: 'Collection',
                  data: data.collection,
                  displayUnit: '$',
                },
                {
                  type: 'column',
                  name: 'Production',
                  data: data.production,
                  displayUnit: '$',
                },
              ]}
              lines={[
                {
                  type: 'spline',
                  name: 'NCR',
                  data: data.ncr,
                  displayUnit: '%',
                  yAxis: '1',
                },
              ]}
              timeGranularity='month'
              xAxis={{
                type: 'datetime',
              }}
            />
          )}
          fallback={<LoadDataErrorCard />}
        />
      </Box>
    </RouteGridContainer>
  );
}
