import { useFlags } from '@insidedesk/tuxedo';
import { Box } from '@mui/material';
import DataCard from './DataCard';
import KPI from './KPI';

export default function DetailedKPI(
  props: DetailedKPIPoint & { useDataCardWrapper?: boolean },
) {
  const { main, secondary, tertiary, useDataCardWrapper = true } = props;
  const Wrapper = useDataCardWrapper ? DataCard : Box;
  const flags = useFlags();

  let mainSize: KPISize;
  let otherSize: KPISize;
  if (flags.horizontalKPIs) {
    mainSize = secondary || tertiary ? 'medium' : 'large';
    otherSize = secondary || tertiary ? 'small' : 'medium';
  } else {
    mainSize = 'large';
    otherSize = 'medium';
  }

  return (
    <Wrapper
      sx={[
        {
          container: 'kpi / inline-size',
          height: flags.horizontalKPIs ? '100%' : undefined,
        },
        !useDataCardWrapper && { width: '100%' },
      ]}
    >
      <Box
        sx={[
          {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            gap: 1,
            padding: 1.5,
          },
          Boolean(flags.horizontalKPIs) && {
            alignItems: 'center',
            flexDirection: 'row',
            height: '100%',
            justifyContent: 'space-around',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            '@container (width < 12rem)': {
              flexDirection: 'column',
            },
          },
        ]}
      >
        <KPI main size={mainSize} {...main} />
        {secondary ? <KPI size={otherSize} {...secondary} /> : null}
        {tertiary ? <KPI size={otherSize} {...tertiary} /> : null}
      </Box>
    </Wrapper>
  );
}
