import { Auth0Provider, withAuthenticationRequired } from '@auth0/auth0-react';
import {
  CenterInPage,
  defaultTheme,
  ErrorBoundary,
  LoadingSpinner,
} from '@insidedesk/tuxedo';
import { createTheme, ThemeProvider as MUIThemeProvider } from '@mui/material';
import Highcharts from 'highcharts';
import { enableMapSet } from 'immer';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import NewRelicErrorElement from './components/NewRelicErrorElement';
import { AUTH0_CONFIG } from './config';
import { BASE_CHART_OPTIONS } from './constants';
import ProviderContainer from './ProviderContainer';
import reportWebVitals from './reportWebVitals';
import { routes } from './routes';
import './styles/custom.scss';

enableMapSet();

Highcharts.setOptions(BASE_CHART_OPTIONS);

// eslint-disable-next-line import/prefer-default-export
export const customTheme = createTheme(defaultTheme);

const Router = withAuthenticationRequired(
  () => (
    <ProviderContainer>
      <RouterProvider router={createBrowserRouter(routes)} />
    </ProviderContainer>
  ),
  {
    onRedirecting: () => (
      <CenterInPage>
        <LoadingSpinner />
      </CenterInPage>
    ),
  },
);

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <MUIThemeProvider theme={customTheme}>
      <ErrorBoundary fallbackComponent={NewRelicErrorElement}>
        <Auth0Provider
          {...AUTH0_CONFIG}
          redirectUri={window.location.origin}
          useRefreshTokens
        >
          <Router />
        </Auth0Provider>
      </ErrorBoundary>
    </MUIThemeProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
