import { BlurPHIContext } from '@insidedesk/tuxedo';
import React from 'react';
import {
  HorizontalBarChart,
  HorizontalBarChartProps,
  LoadDataErrorCard,
} from '../components';
import { useCubeQuery, useGlobalFilters } from '../hooks';

interface DataByFacilityProps
  extends Omit<ReportOptions, 'title'>,
    Omit<HorizontalBarChartProps, 'data'> {
  facilities: Facility[];
  labelMap: Record<string, string>;
  colorMap: Record<string, string>;
  transformData?: (
    data: ChartDataSeries<Highcharts.SeriesBarOptions>[],
  ) => ChartDataSeries<Highcharts.SeriesBarOptions>[];
}

function DataByFacility(props: DataByFacilityProps) {
  const {
    facilities,
    grouped,
    height,
    labelMap,
    colorMap,
    query,
    transformData,
  } = props;

  const [data, setData] = React.useState<
    ChartDataSeries<Highcharts.SeriesBarOptions>[]
  >([]);

  const { facilities: filteredFacilities } = useGlobalFilters();
  const { blur } = React.useContext(BlurPHIContext);

  const { resultSet, error } = useCubeQuery(query);

  React.useEffect(() => {
    if (resultSet) {
      const groupedResults: ChartDataSeries<Highcharts.SeriesBarOptions>[] = [];
      resultSet
        .chartPivot({
          x: [query.dimensions![0]],
          y: [query.dimensions![1], 'measures'],
        })
        .sort((a, b) => {
          const aTotal = Object.values(a)
            .filter((value) => typeof value === 'number')
            .reduce((t, value) => t + value, 0);
          const bTotal = Object.values(b)
            .filter((value) => typeof value === 'number')
            .reduce((t, value) => t + value, 0);
          return bTotal - aTotal;
        })
        .forEach((row, index) => {
          const facility = facilities.find(
            ({ id }) => id.toString() === row.xValues[0],
          )?.name;
          if (facility === undefined) {
            return;
          }
          Object.entries(row)
            .filter(([, value]) => typeof value === 'number')
            .forEach(([xValue, value]) => {
              const groupName = xValue.split(',')[0];
              let group: string;
              let color: string;
              if (grouped) {
                group = labelMap[groupName];
                color = colorMap[groupName];
              } else {
                group = labelMap[Object.keys(labelMap)[0]];
                color = colorMap[Object.keys(labelMap)[0]];
              }
              let groupResult = groupedResults.find(
                ({ name }) => name === group,
              );

              if (!groupResult) {
                let tooltipHeaderStyle = '';
                if (blur) {
                  tooltipHeaderStyle = 'style="filter: blur(0.5rem)"';
                }
                groupResult = {
                  data: [],
                  displayUnit: '$',
                  name: group,
                  color,
                  type: 'bar',
                  tooltip: {
                    headerFormat: `<tspan ${tooltipHeaderStyle}>{point.key}</tspan><br />`,
                  },
                };
                groupedResults.push(groupResult);
              }

              const facilityPoint = {
                name: facility,
                y: value,
                x: index,
              };
              groupResult.data?.push(facilityPoint);
            });
        });

      groupedResults.forEach((series) => {
        const missingFacilities = facilities
          .filter((facility) => {
            if (filteredFacilities.length > 0) {
              return filteredFacilities.find(
                (filteredFacility) => filteredFacility.id === facility.id,
              );
            }
            return true;
          })
          .filter(
            (facility) =>
              !series.data?.find((point) => {
                const pointObj = point as Highcharts.PointOptionsObject;
                return pointObj.name === facility.name;
              }),
          );
        missingFacilities.forEach((facility) => {
          series.data?.push({ name: facility.name });
        });
      });
      if (transformData) {
        setData(transformData(groupedResults));
      } else {
        setData(groupedResults);
      }
    }
  }, [
    resultSet,
    facilities,
    grouped,
    labelMap,
    query.measures,
    filteredFacilities,
    transformData,
    query.dimensions,
    blur,
  ]);

  if (error) {
    return <LoadDataErrorCard height={height} />;
  }
  return (
    <HorizontalBarChart
      data={data}
      xAxis={{
        labels: {
          style: {
            filter: blur ? 'blur(0.5rem)' : undefined,
          },
        },
      }}
      legend
      {...props}
    />
  );
}
export default DataByFacility;
