import { Point } from 'highcharts';
import formatNumber from './formatNumber';
import formatThousands from './formatThousands';
import formatUnits from './formatUnits';

// XXX: Hopefully this can be expanded to convert Series for any chart type.
// Mainly just need to figure out the typing I think, otherwise should be fine.
/**
 * Convert a ComposedChartData object to a Highcharts.SeriesOptions object.
 * @param config
 * @returns
 */
export default function convertSeries<T extends HighchartsSeriesOptions>(
  config: ChartDataSeries<T>,
): ChartDataSeries<T> {
  const { displayUnit, blurDataLabels } = config;

  // @ts-expect-error 2322
  return {
    ...config,
    dataLabels: {
      // Always set `format`, even when no displayUnit is defined. Otherwise
      // highcharts might not remove a previous `format` from the chart.
      format: formatUnits('{point.y:.3f}', displayUnit),
      style: { filter: blurDataLabels ? 'blur(0.5rem)' : 'none' },
      ...config.dataLabels,
    },
    tooltip: {
      pointFormatter: tooltipPointFormatter,
      ...config.tooltip,
    },
  };
}

export function tooltipPointFormatter(this: Point) {
  const { displayUnit, blurDataLabels } = this.series
    .options as ChartDataSeries<HighchartsSeriesOptions>;

  const name = this.series.name || this.name;
  const y = formatThousands(this.y ?? 0);
  const yWithUnit = displayUnit ? formatUnits(y, displayUnit) : y;
  const percentage = formatUnits(
    formatNumber(this.percentage, { decimalPlaces: 1 }),
    '%',
  );
  const blurStyle = blurDataLabels ? 'filter:blur(0.5rem)' : '';

  if (this.series.options.type === 'pie') {
    return `<b style="${blurStyle}">${name}</b>: ${yWithUnit} (${percentage})`;
  }
  return `<span style="color:${this.color};${blurStyle}">\u25CF</span> ${name}: <b>${yWithUnit}</b><br/>`;
}
