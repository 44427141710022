/**
 * Insert the units into a string.
 *
 * @param value
 * @param unit
 * @returns
 */
export default function formatUnits(value: string, unit?: DisplayUnit): string {
  const hiddenUnits = ['claim', 'day', 'work'];
  if (unit === undefined || hiddenUnits.includes(unit)) return value;
  const unitPosition = unit.includes('%') ? 'suffix' : 'prefix';
  const sign = value.startsWith('-') ? '-' : '';

  return unitPosition === 'prefix'
    ? `${sign}${unit}${value.replace(/^-/, '')}`
    : `${value}${unit}`;
}
