import { MB2Logo, PHI, useUserProfile } from '@insidedesk/tuxedo';
import { Box } from '@mui/material';

export default function OfficeLogo() {
  const client = useUserProfile().client.name;
  return (
    /* Doing this in possibly the most insane way because there's no way
     * we can get the infrastructure built to do this properly in time
     * for christmas...
     */
    <PHI>
      {client === 'MB2' && (
        <Box component='img' src={MB2Logo} alt='MB2 Logo' height='44px' />
      )}
    </PHI>
  );
}
