import Highcharts from 'highcharts';
import React from 'react';
import { convertSeries } from '../utils';
import Chart from './Chart';

export interface HorizontalBarChartProps {
  /** Show each bar as a percent of the total for each category */
  asPercent?: boolean;
  barSize: number;
  data: Highcharts.SeriesBarOptions[];
  grouped?: boolean;
  legend?: boolean;
  title: string;
  xAxis?: Highcharts.XAxisOptions;
}
function HorizontalBarChart(props: HorizontalBarChartProps) {
  const {
    asPercent = false,
    barSize,
    data,
    grouped = false,
    legend = false,
    title,
    xAxis = {},
  } = props;

  const chartHeight = React.useMemo(
    () => (Object.values(data)?.[0]?.data?.length ?? 0) * barSize,
    [barSize, data],
  );

  const stacking = React.useMemo(() => {
    if (grouped) {
      if (asPercent) {
        return 'percent';
      }
      return 'normal';
    }
    return undefined;
  }, [asPercent, grouped]);

  const options: Highcharts.Options = {
    chart: {
      type: 'bar',
      /* When the chart is made scrollable the margins aren't calculated into
       * the height correctly so need to adjust.
       */
      marginRight: 20,
      scrollablePlotArea: {
        minHeight: chartHeight,
        opacity: 1,
      },
    },
    legend: { enabled: legend, verticalAlign: 'top' },
    plotOptions: {
      bar: {
        dataLabels: {
          enabled: true,
        },
        maxPointWidth: barSize,
        pointPadding: 0,
        stacking,
        tooltip: {
          followPointer: true,
        },
      },
    },
    series: data.map(convertSeries),
    title: {
      text: title,
    },
    tooltip: {
      shared: true,
    },
    xAxis: {
      type: 'category',
      tickLength: 0,
      ...xAxis,
      title: {
        text: null,
        ...xAxis.title,
      },
      labels: {
        step: 1,
        ...xAxis.labels,
      },
    },
    yAxis: {
      visible: false,
      min: 0,
      reversedStacks: false,
      title: {
        text: '',
      },
    },
  };

  return <Chart options={options} />;
}

export default HorizontalBarChart;
