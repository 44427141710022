/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { Query, ResultSet } from '@cubejs-client/core';
import { useQuery } from '@tanstack/react-query';
import { endOfMonth, formatISO, parseISO, startOfMonth, sub } from 'date-fns';
import {
  ComposedChart,
  DataTable,
  DataTableRow,
  DATA_TABLE_KEY,
  LoadDataErrorCard,
  QueriesRenderer,
  RouteGridContainer,
} from '../../components';
import {
  useArMonthRange,
  useCubeFilter,
  useCubeQuery,
  useFacilityFilterOptionsQueryOpts,
} from '../../hooks';

const tableColumnMap = {
  name: { label: 'Office', phi: true, defaultSortOrder: 'asc' },
  ncr: { label: 'NCR L6M', unit: '%', showTotal: false },
  collected: { label: 'Collected $ L6M', unit: '$' },
  yield: { label: 'Claim Yield L6M', unit: '%', showTotal: false },
} as const;

function NCRDetails() {
  const { data: facilities } = useQuery(useFacilityFilterOptionsQueryOpts());
  const { start, end } = useArMonthRange();

  const userClaimFilters = useCubeFilter('UserClaim');

  const collectedQuery: Query = {
    measures: ['UserClaim.amountPaidSum'],
    timeDimensions: [
      {
        dimension: 'UserClaim.adjustedPostedDate',
        dateRange: [
          formatISO(startOfMonth(sub(start, { months: 6 })), {
            representation: 'date',
          }),
          formatISO(endOfMonth(end), { representation: 'date' }),
        ],
        granularity: 'month',
      },
    ],
    filters: userClaimFilters.filters,
    segments: userClaimFilters.segments,
  };
  const producedQuery: Query = {
    measures: ['UserClaim.totalAmountExpectedSum'],
    timeDimensions: [
      {
        dimension: 'UserClaim.serviceDateStart',
        dateRange: [
          formatISO(startOfMonth(sub(start, { months: 6 })), {
            representation: 'date',
          }),
          formatISO(endOfMonth(end), { representation: 'date' }),
        ],
        granularity: 'month',
      },
    ],
    filters: userClaimFilters.filters,
    segments: userClaimFilters.segments,
  };

  const claimYieldQuery: Query = {
    ...userClaimFilters,
    measures: [
      'UserClaim.amountPaidSum',
      'UserClaim.paidAdjustedAmountExpectedSum',
    ],
    timeDimensions: [
      {
        dimension: 'UserClaim.adjustedPostedDate',
        dateRange: [
          formatISO(startOfMonth(sub(start, { months: 6 })), {
            representation: 'date',
          }),
          formatISO(endOfMonth(end), { representation: 'date' }),
        ],
        granularity: 'month',
      },
    ],
  };

  const collectedByFacility: Query = {
    ...collectedQuery,
    dimensions: ['UserClaim.facilityID'],
    timeDimensions: [
      {
        ...collectedQuery.timeDimensions![0],
        granularity: undefined,
      },
    ],
  };

  const producedByFacility: Query = {
    ...producedQuery,
    dimensions: ['UserClaim.facilityID'],
    timeDimensions: [
      {
        ...producedQuery.timeDimensions![0],
        granularity: undefined,
      },
    ],
  };

  const yieldByFacility: Query = {
    ...claimYieldQuery,
    dimensions: ['UserClaim.facilityID'],
    timeDimensions: [
      {
        ...claimYieldQuery.timeDimensions![0],
        granularity: undefined,
      },
    ],
  };

  const { resultSet: claimNCRResultSet, error: claimNCRError } = useCubeQuery([
    collectedQuery,
    producedQuery,
  ]);

  const { resultSet: claimYieldResultSet, error: claimYieldError } =
    useCubeQuery(claimYieldQuery);

  const collected =
    claimNCRResultSet?.chartPivot().map((row) => ({
      x: parseISO(row.x).getTime(),
      y: row[collectedQuery.measures?.[0] ?? ''],
    })) ?? [];
  const produced =
    claimNCRResultSet?.chartPivot().map((row) => ({
      x: parseISO(row.x).getTime(),
      y: row[producedQuery.measures?.[0] ?? ''],
    })) ?? [];
  const claimNCR = collected.map((row, index) => ({
    x: row.x,
    y: (100.0 * row.y) / produced[index].y,
  }));

  const claimYield =
    claimYieldResultSet?.chartPivot().map((row) => {
      const claimPaid = row[claimYieldQuery.measures?.[0] ?? ''];
      const claimExpected = row[claimYieldQuery.measures?.[1] ?? ''];
      return {
        x: parseISO(row.x).getTime(),
        y: (100.0 * claimPaid) / claimExpected,
      };
    }) ?? [];

  const transformTableData = (
    resultSets: [ResultSet, ResultSet, ResultSet] | null,
  ) => {
    const data: DataTableRow<keyof typeof tableColumnMap>[] = [];
    const [
      collectedByFacilityResultSet,
      producedByFacilityResultSet,
      yieldByFacilityResultSet,
    ] = resultSets ?? [];
    if (
      collectedByFacilityResultSet &&
      producedByFacilityResultSet &&
      yieldByFacilityResultSet
    ) {
      const pivotConfig = {
        x: ['UserClaim.facilityID'],
        y: ['measures'],
      };
      const yieldRows = yieldByFacilityResultSet.chartPivot(pivotConfig);
      const producedRows = producedByFacilityResultSet.chartPivot(pivotConfig);
      collectedByFacilityResultSet.chartPivot(pivotConfig).forEach((row) => {
        const facility = facilities?.find(
          (f) => f.id.toString() === row.xValues[0],
        );
        if (facility === undefined) {
          return;
        }

        const facilityCollected = row[collectedByFacility.measures![0]];
        const facilityProducedRow = producedRows.find(
          (producedRow) => producedRow.xValues[0] === facility.id.toString(),
        );
        const facilityProduced =
          facilityProducedRow?.[producedByFacility.measures![0]];

        const ncr = (100.0 * facilityCollected) / facilityProduced;

        const facilityYieldRow = yieldRows.find(
          (yieldRow) => yieldRow.xValues[0] === facility.id.toString(),
        );
        const facilityPaid = facilityYieldRow?.[yieldByFacility.measures![0]];
        const facilityExpected =
          facilityYieldRow?.[yieldByFacility.measures![1]];
        const facilityYield = (100.0 * facilityPaid) / facilityExpected;

        const facilityRow = {
          [DATA_TABLE_KEY]: String(facility.id),
          name: facility.name,
          ncr,
          collected: facilityCollected,
          yield: facilityYield,
        };
        data.push(facilityRow);
      });
    }
    return data;
  };

  return (
    <RouteGridContainer data-testid='ncr-details' gridTemplateColumns='1fr 1fr'>
      {!claimNCRError && !claimYieldError ? (
        <ComposedChart
          bars={[
            {
              data: collected,
              dataLabels: { enabled: false },
              displayUnit: '$',
              name: 'Collection',
              type: 'column',
            },
          ]}
          lines={[
            {
              data: claimNCR,
              dataLabels: { enabled: true },
              displayUnit: '%',
              name: 'NCR',
              type: 'spline',
              yAxis: '1',
            },
            {
              data: claimYield,
              dataLabels: { enabled: true },
              displayUnit: '%',
              name: 'Claim Yield',
              type: 'spline',
              yAxis: '1',
            },
          ]}
          timeGranularity='month'
          title='Claim Collection Amount and Net Collection Rates M.O.M.'
          xAxis={{ type: 'datetime', title: { text: 'Posting/Close date' } }}
        />
      ) : (
        <LoadDataErrorCard />
      )}
      <QueriesRenderer
        queries={
          [collectedByFacility, producedByFacility, yieldByFacility] as const
        }
        transformResult={transformTableData}
        render={(tableData) => (
          <DataTable
            columnLabelMap={tableColumnMap}
            defaultSortColumn='ncr'
            data={tableData}
            bordered
          />
        )}
        fallback={<LoadDataErrorCard />}
      />
    </RouteGridContainer>
  );
}
export default NCRDetails;
