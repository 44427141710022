import { Box } from '@mui/material';
import { RouteGridContainer } from '../../components';
import { useCubeFilter } from '../../hooks';
import { DenialsTrendChart } from '../../reports';
import DenialsBreakdownChart from '../../reports/DenialsBreakdownChart';

export default function DenialSummary() {
  const baseQuery = useCubeFilter('Claim');

  return (
    <RouteGridContainer
      data-testid='denial-report'
      gridTemplateColumns='2fr 1fr 1fr'
    >
      <Box gridRow='span 2'>
        <DenialsTrendChart />
      </Box>
      <DenialsBreakdownChart
        baseQuery={baseQuery}
        dimensionSpec={{
          metric: 'Claim.arAgeGroup',
          label: 'Age Group',
        }}
      />
      <DenialsBreakdownChart
        baseQuery={baseQuery}
        dimensionSpec={{
          metric: 'AdjudicatedClaims.denialStatus',
          label: 'Denial Status',
        }}
      />
      <Box gridColumn='span 2'>
        <DenialsBreakdownChart
          baseQuery={baseQuery}
          dimensionSpec={{
            metric: 'Claim.adjudicationReasonCategory',
            label: 'ANSI Category',
          }}
          sortByValue
        />
      </Box>
    </RouteGridContainer>
  );
}
