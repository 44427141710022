import { Query } from '@cubejs-client/core';
import { useOutletContext } from 'react-router-dom';

export interface ReportOutletContext {
  baseQuery: Partial<Query>;
}

export function useReportOutletContext() {
  return useOutletContext<ReportOutletContext>();
}
