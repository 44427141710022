import { DeeplyReadonly, Query } from '@cubejs-client/core';
import * as d3 from 'd3';
import { differenceInDays, min } from 'date-fns';
import { KPIRow, KPIRowProps } from '../components';
import {
  useClaimsWorkedCube,
  useCubeFilter,
  useCubeQuery,
  useGlobalFilters,
} from '../hooks';
import { claimsWorkedData } from '../routes/productivity/utils';
import { tidyRawData } from '../utils';

export default function ProductivityKPIRow() {
  const {
    claimsWorked,
    claimsWorkedOpen,
    dollarsPostedWorked,
    closeRateWorked,
    workToClose,
  } = useClaimsWorkedKPIs();
  const { workEventsPerDay } = useWorkDoneByUserKPIs();

  const kpis: KPIRowProps['kpis'] = [
    { main: claimsWorked },
    { main: claimsWorkedOpen },
    { main: dollarsPostedWorked },
    { main: closeRateWorked },
    { main: workToClose },
    { main: workEventsPerDay },
  ];

  return <KPIRow kpis={kpis} />;
}

function useClaimsWorkedKPIs() {
  const cube = useClaimsWorkedCube();
  const query = {
    ...useCubeFilter(cube),
    measures: [
      `${cube}.adjustedAmountExpectedSum`,
      `${cube}.amountPaidSum`,
      `${cube}.claimClosedCount`,
      `${cube}.claimCount`,
      `${cube}.daysToClose`,
      `${cube}.deletedAdjustedAmountExpectedSum`,
    ],
    dimensions: [`${cube}.closed`],
  } as const satisfies DeeplyReadonly<Query>;

  const { resultSet, error } = useCubeQuery(query);
  const data = resultSet !== null ? tidyRawData<typeof query>(resultSet) : [];

  const orNaN = (value: number) => (data.length > 0 ? value : NaN);

  const {
    claimsWorked,
    claimsWorkedOpen,
    dollarsPostedWorked,
    closeRateWorked,
  } = claimsWorkedData(data, cube);
  const workToCloseDays =
    d3.sum(data, (row) => row[`${cube}.daysToClose`]) /
    d3.sum(data, (row) => row[`${cube}.claimClosedCount`]);

  return {
    claimsWorked: {
      name: '# Claims Worked',
      type: 'number',
      value: orNaN(claimsWorked),
      error: Boolean(error),
    },
    claimsWorkedOpen: {
      name: '# Claims Worked (Open)',
      type: 'number',
      value: orNaN(claimsWorkedOpen),
      error: Boolean(error),
    },
    dollarsPostedWorked: {
      name: '$ Posted: Worked',
      type: 'currency',
      value: orNaN(dollarsPostedWorked),
      error: Boolean(error),
    },
    closeRateWorked: {
      name: 'Close Rate: Worked',
      type: 'percent',
      value: orNaN(closeRateWorked),
      error: Boolean(error),
    },
    workToClose: {
      name: 'Work to Close Days',
      type: 'number',
      value: orNaN(workToCloseDays),
      error: Boolean(error),
    },
  } satisfies Record<string, KPIPoint>;
}

function useWorkDoneByUserKPIs() {
  const baseQuery = useCubeFilter('WorkDoneByUser', {
    useTimeDimensionsInsteadOfSegments: true,
  });
  const query = {
    ...baseQuery,
    measures: ['WorkDoneByUser.claimCount'],
    segments: [...baseQuery.segments, 'WorkDoneByUser.workedOnly'],
  } as const satisfies DeeplyReadonly<Query>;

  const { resultSet, error } = useCubeQuery(query);
  const data = resultSet !== null ? tidyRawData<typeof query>(resultSet) : [];

  const { startDate, endDate } = useGlobalFilters().workedMonth.range;
  const daysInRange = differenceInDays(min([endDate, new Date()]), startDate);
  const workEventsPerDay =
    d3.sum(data, (row) => row['WorkDoneByUser.claimCount']) / daysInRange;

  return {
    workEventsPerDay: {
      name: 'Work Events / Day',
      type: 'number',
      value: data.length > 0 ? workEventsPerDay : NaN,
      error: Boolean(error),
    },
  } satisfies Record<string, KPIPoint>;
}
