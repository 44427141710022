import { ChartPivotRow } from '@cubejs-client/core';
import { endOfMonth, formatISO, startOfMonth } from 'date-fns';
import React from 'react';
import { LoadDataErrorCard, PieChart } from '../components';
import { useArMonthRange, useCubeFilter, useCubeQuery } from '../hooks';
import variables from '../styles/variables.module.scss';

interface CleanClaimsProps extends ReportOptions {}

function CleanClaims(props: CleanClaimsProps) {
  const { query: baseQuery } = props;
  const cleanSnapshotMeasure = 'ClaimARSnapshot.totalAmountExpectedSum';
  const cleanClosedMeasure = 'UserClaim.totalAmountExpectedSum';

  const [data, setData] = React.useState<Highcharts.Point[]>([]);

  const { start, end } = useArMonthRange();

  const { resultSet: cleanSnapshotResult, error: snapshotError } = useCubeQuery(
    {
      ...baseQuery,
      measures: [cleanSnapshotMeasure],
      dimensions: ['ClaimARSnapshot.clean'],
    },
  );

  const userClaimFilters = useCubeFilter('UserClaim');
  const { resultSet: cleanClosedResult, error: closedError } = useCubeQuery({
    measures: [cleanClosedMeasure],
    dimensions: ['UserClaim.closedClean'],
    timeDimensions: [
      {
        dimension: 'UserClaim.closedDate',
        dateRange: [
          formatISO(startOfMonth(start), { representation: 'date' }),
          formatISO(endOfMonth(end), { representation: 'date' }),
        ],
      },
    ],
    filters: userClaimFilters.filters,
    segments: userClaimFilters.segments,
  });

  React.useEffect(() => {
    if (cleanSnapshotResult && cleanClosedResult) {
      const results: Partial<Highcharts.Point>[] = cleanSnapshotResult
        .chartPivot({ x: ['ClaimARSnapshot.clean'], y: ['measures'] })
        .map((row) => ({
          name: row.x.includes('true') ? 'Clean' : 'Dirty',
          y: row[cleanSnapshotMeasure],
          sliced: !row.x.includes('true'),
          dataLabels: {
            format: '{point.name}: {point.percentage:.0f}%',
          },
          color: row.x.includes('true')
            ? variables.progressionDollar1
            : variables.progressionDollar3,
        }))
        .sort((a, b) => (a.name === 'Clean' ? -1 : 1));
      cleanClosedResult.chartPivot().forEach((row: ChartPivotRow) => {
        const result =
          results.find(
            (value) => (value.name === 'Clean').toString() === row.x,
          ) ?? {};
        result.y += row[cleanClosedMeasure];
      });
      setData(results as Highcharts.Point[]);
    }
  }, [cleanClosedResult, cleanSnapshotResult]);

  if (snapshotError || closedError) {
    return <LoadDataErrorCard />;
  }
  return (
    <PieChart
      title='Clean Claim Rate'
      data={data as Highcharts.Point[]}
      unit='$'
    />
  );
}
export default CleanClaims;
