import { ChartPivotRow, Query, ResultSet } from '@cubejs-client/core';
import { Box } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import {
  endOfMonth,
  formatISO,
  isEqual,
  parseISO,
  startOfMonth,
  sub,
} from 'date-fns';
import React from 'react';
import {
  ComposedChart,
  DataTable,
  DataTableRow,
  DATA_TABLE_KEY,
  LoadDataErrorCard,
  QueryRenderer,
  RouteGridContainer,
} from '../../components';
import {
  useArMonthRange,
  useCubeFilter,
  useCubeQuery,
  useFacilityFilterOptionsQueryOpts,
} from '../../hooks';
import { ARByAge } from '../../reports';
import { useARReportOutletContext } from './hooks';

const lastMonthColumn = 'lastMonth';
const thisMonthColumn = 'thisMonth';

const tableColumnMap = {
  name: { label: 'Office', phi: true, defaultSortOrder: 'asc' },
  [thisMonthColumn]: { label: 'Claim AR', unit: '$' },
  [lastMonthColumn]: { label: 'Claim AR LM', unit: '$' },
} as const;

function ARDetails() {
  const { baseQuery } = useARReportOutletContext();
  const { data: facilities } = useQuery(useFacilityFilterOptionsQueryOpts());

  const { start, end } = useArMonthRange();
  const startDate = formatISO(startOfMonth(sub(start, { months: 6 })), {
    representation: 'date',
  });
  const endDate = formatISO(endOfMonth(end), { representation: 'date' });

  const userClaimFilters = useCubeFilter('UserClaim');

  const currentARMOM: Query = {
    ...baseQuery,
    measures: ['ClaimARSnapshot.currentAmountExpectedSum'],
    timeDimensions: [
      {
        dimension: 'ClaimARSnapshot.date',
        dateRange: [startDate, endDate],
        granularity: 'month',
      },
    ],
  };

  const productionMOM: Query = {
    ...userClaimFilters,
    measures: ['UserClaim.totalAmountExpectedSum'],
    timeDimensions: [
      {
        dimension: 'UserClaim.serviceDateStart',
        dateRange: [startDate, endDate],
        granularity: 'month',
      },
    ],
  };

  const arByFacilityQuery: Query = React.useMemo(
    () => ({
      ...baseQuery,
      measures: ['ClaimARSnapshot.currentAmountExpectedSum'],
      dimensions: ['ClaimARSnapshot.facilityID'],
      order: [['ClaimARSnapshot.currentAmountExpectedSum', 'desc']],
    }),
    [baseQuery],
  );

  const arByFacilityLastMonthQuery: Query = React.useMemo(
    () => ({
      ...baseQuery,
      measures: ['ClaimARSnapshot.currentAmountExpectedSum'],
      dimensions: ['ClaimARSnapshot.facilityID'],
      timeDimensions: [
        {
          dimension: 'ClaimARSnapshot.date',
          dateRange: [
            formatISO(startOfMonth(sub(start, { months: 1 })), {
              representation: 'date',
            }),
            formatISO(endOfMonth(sub(end, { months: 1 })), {
              representation: 'date',
            }),
          ],
          granularity: 'month',
        },
      ],
      order: [['ClaimARSnapshot.currentAmountExpectedSum', 'desc']],
    }),
    [baseQuery, end, start],
  );

  const { resultSet: currentARMOMResult, error: arMOMError } =
    useCubeQuery(currentARMOM);

  const { resultSet: productionMOMResult, error: productionMOMError } =
    useCubeQuery(productionMOM);

  const [arMOMData, productionMOMData] = React.useMemo(() => {
    const arData: Partial<Highcharts.Point>[] = [];
    const productionData: Partial<Highcharts.Point>[] = [];
    if (currentARMOMResult && productionMOMResult) {
      const arResults = currentARMOMResult.chartPivot();
      productionMOMResult?.chartPivot().forEach((row: ChartPivotRow) => {
        const date = parseISO(row.x);
        const arResult = arResults.find((result) =>
          isEqual(date, parseISO(result.x)),
        );
        const production: number = row[productionMOM.measures?.[0] ?? ''];
        arData.push({
          x: date.getTime(),
          y: arResult?.['ClaimARSnapshot.currentAmountExpectedSum'] ?? 0,
        });
        productionData.push({
          x: date.getTime(),
          y: production,
        });
      });
    }
    return [arData, productionData];
  }, [currentARMOMResult, productionMOM.measures, productionMOMResult]);

  const transformARByFacility = React.useCallback(
    (result: ResultSet<number> | null) => {
      const data: DataTableRow<keyof typeof tableColumnMap>[] = [];
      result?.decompose().forEach((set: ResultSet, index: number) => {
        set
          ?.chartPivot({
            x: [arByFacilityQuery.dimensions?.[0] ?? ''],
            y: ['measures'],
          })
          .forEach((row: ChartPivotRow) => {
            const facility = facilities?.find(
              (facility_) => facility_.id.toString() === row.xValues[0],
            );
            if (facility === undefined) {
              return;
            }
            const facilityName = facility.name;
            let facilityRow = data.find((value) => value.name === facilityName);
            if (facilityRow === undefined) {
              facilityRow = {
                name: facilityName,
                [DATA_TABLE_KEY]: String(facility.id),
                [thisMonthColumn]: null,
                [lastMonthColumn]: null,
              };
              data.push(facilityRow);
            }
            if (index === 0) {
              facilityRow[thisMonthColumn] =
                row[arByFacilityQuery.measures?.[0] ?? ''];
            } else {
              facilityRow[lastMonthColumn] =
                row[arByFacilityLastMonthQuery.measures?.[0] ?? ''];
            }
          });
      });
      return { data };
    },
    [
      arByFacilityLastMonthQuery.measures,
      arByFacilityQuery.dimensions,
      arByFacilityQuery.measures,
      facilities,
    ],
  );

  return (
    <RouteGridContainer data-testid='ar-details' gridTemplateColumns='1fr 1fr'>
      {!arMOMError && !productionMOMError ? (
        <ComposedChart
          title='Claim AR vs Production M.O.M'
          bars={[
            {
              type: 'column',
              name: 'Claim AR',
              data: arMOMData,
              displayUnit: '$',
            },
            {
              type: 'column',
              name: 'Production',
              data: productionMOMData,
              displayUnit: '$',
            },
          ]}
          timeGranularity='month'
          xAxis={{
            type: 'datetime',
            tickInterval: 0,
          }}
        />
      ) : (
        <LoadDataErrorCard />
      )}
      <Box gridRow='span 2'>
        <QueryRenderer
          query={[arByFacilityQuery, arByFacilityLastMonthQuery]}
          transformResult={transformARByFacility}
          render={(data) => (
            <DataTable
              columnLabelMap={tableColumnMap}
              data={data.data}
              defaultSortColumn={thisMonthColumn}
              bordered
            />
          )}
          fallback={<LoadDataErrorCard />}
        />
      </Box>
      <ARByAge detailed query={baseQuery} />
    </RouteGridContainer>
  );
}

export default ARDetails;
