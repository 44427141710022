import {
  Box,
  IconButton,
  LinearProgress,
  TableCell,
  TableRow,
} from '@mui/material';
import { memo, ReactNode, useEffect, useMemo, useState } from 'react';
import { DataTableCollapse, DataTableExpand } from '../../assets';
import LoadDataErrorMessage from '../LoadDataErrorMessage';
import CellValue from './CellValue';
import { DATA_TABLE_KEY, rowId } from './symbols';
import {
  ColumnLabelMap,
  DataTableExpandRow,
  DataTableRow,
  DataTableValue,
  ExpandRowFilterValue,
  GetExpandedData,
  SortState,
} from './types';
import { formatDescription, sortData } from './utils';

export default function DataTableBodyRow<
  TColumn extends string,
  RemapColumn extends TColumn,
>(
  props: {
    columnLabelMap: ColumnLabelMap<TColumn>;
  } & (
    | {
        data: DataTableRow<TColumn>;
        getExpandedData?: never;
        remapExpandedColumn?: never;
        sort?: never;
      }
    | {
        data: DataTableExpandRow<TColumn>;
        getExpandedData: GetExpandedData<TColumn, RemapColumn>;
        remapExpandedColumn: { [key: string]: RemapColumn };
        sort: SortState<TColumn>;
      }
  ),
) {
  const { columnLabelMap, data, getExpandedData, remapExpandedColumn, sort } =
    props;
  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <MemoStatelessRow
        columnLabelMap={columnLabelMap}
        data={data}
        expanded={expanded}
        toggleExpanded={() => setExpanded((prev) => !prev)}
      />
      {getExpandedData && expanded && (
        <ExpandedRows
          columnLabelMap={columnLabelMap}
          filterValue={data[rowId]}
          getExpandedData={getExpandedData}
          remapExpandedColumn={remapExpandedColumn}
          sort={sort}
        />
      )}
    </>
  );
}

function ExpandedRows<
  TColumn extends string,
  RemapColumn extends TColumn,
>(props: {
  columnLabelMap: ColumnLabelMap<TColumn>;
  filterValue: ExpandRowFilterValue;
  getExpandedData: GetExpandedData<TColumn, RemapColumn>;
  remapExpandedColumn: { [key: string]: RemapColumn };
  sort: SortState<TColumn>;
}) {
  const {
    columnLabelMap,
    filterValue,
    getExpandedData,
    remapExpandedColumn,
    sort,
  } = props;
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<DataTableRow<TColumn>[]>([]);
  const [error, setError] = useState<Error>();

  const [dataColumn, tableColumn] = Object.entries(remapExpandedColumn)[0];

  const sortedData = useMemo(() => sortData(data, sort), [data, sort]);

  useEffect(() => {
    setLoading(true);
    getExpandedData(filterValue)
      .then((values) => {
        setData(
          values.map((row) => {
            const remappedRow = Object.fromEntries(
              Object.entries(row).filter(
                (entry): entry is [TColumn, DataTableValue] =>
                  entry[0] !== dataColumn,
              ),
            );
            return {
              ...remappedRow,
              [tableColumn]: row[dataColumn],
              // symbols are not included in Object.entries so must manually include
              [DATA_TABLE_KEY]: row[DATA_TABLE_KEY],
            };
          }),
        );
        setError(undefined);
      })
      .catch((e) => {
        setError(e);
      })
      .finally(() => setLoading(false));
  }, [dataColumn, filterValue, getExpandedData, tableColumn]);

  if (error) {
    return (
      <tr>
        <td colSpan={Object.values(columnLabelMap).length}>
          <Box sx={{ display: 'grid', placeItems: 'center', height: 250 }}>
            <LoadDataErrorMessage />
          </Box>
        </td>
      </tr>
    );
  }

  return (
    <>
      {loading && (
        <tr>
          <td colSpan={Object.values(columnLabelMap).length}>
            <LinearProgress />
          </td>
        </tr>
      )}
      {sortedData.map((row) => (
        <MemoStatelessRow
          /** XXX: Although these rows are siblings to the parent in the HTML,
           * react recognizes that they are children so no concerns about
           * duplicate keys.
           */
          key={row[DATA_TABLE_KEY]}
          columnLabelMap={columnLabelMap}
          data={row}
          isExpandedRow
        />
      ))}
    </>
  );
}

function ExpandAdornment(props: { expanded: boolean; onClick: () => void }) {
  const { expanded, onClick } = props;
  if (expanded) {
    return (
      <IconButton aria-label='Collapse row' onClick={onClick}>
        <DataTableCollapse />
      </IconButton>
    );
  }
  return (
    <IconButton aria-label='Expand row' onClick={onClick}>
      <DataTableExpand />
    </IconButton>
  );
}

function StatelessRow<TColumn extends string>(props: {
  columnLabelMap: ColumnLabelMap<TColumn>;
  isExpandedRow?: boolean;
  expanded?: boolean;
  toggleExpanded?: () => void;
  data: DataTableRow<TColumn> | DataTableExpandRow<TColumn>;
}) {
  const {
    columnLabelMap,
    data,
    isExpandedRow = false,
    expanded,
    toggleExpanded,
  } = props;

  return (
    <TableRow>
      {Object.entries(columnLabelMap).map(([column, description], index) => {
        const { alignment, adornment } = formatDescription(description);
        const align = alignment?.body ?? (index === 0 ? 'left' : 'center');

        let displayAdornment: ReactNode;
        if (
          !isExpandedRow &&
          adornment === 'expand' &&
          expanded !== undefined &&
          toggleExpanded
        ) {
          displayAdornment = (
            <ExpandAdornment expanded={expanded} onClick={toggleExpanded} />
          );
        }

        return (
          <TableCell
            key={column}
            align={align}
            className={isExpandedRow ? 'DataTable-cell--expanded' : undefined}
          >
            {displayAdornment && (
              <span className='DataTable-adornment'>{displayAdornment}</span>
            )}
            <CellValue value={data[column]} description={description} />
          </TableCell>
        );
      })}
    </TableRow>
  );
}

const MemoStatelessRow = memo(
  StatelessRow,
  (prevProps, nextProps) =>
    prevProps.expanded === nextProps.expanded &&
    prevProps.data === nextProps.data,
);
