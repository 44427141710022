import { Permission, useHasPermission } from '@insidedesk/tuxedo';
import { PropsWithChildren, ReactNode } from 'react';

type PermissionGuardProps = PropsWithChildren<{
  permission: Permission;
  fallback: ReactNode;
}>;
export default function PermissionGuard(props: PermissionGuardProps) {
  const { permission, children, fallback } = props;
  const { hasPermission } = useHasPermission();

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{hasPermission(permission) ? children : fallback}</>;
}
