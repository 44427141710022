import * as d3 from 'd3';
import { TrendChart } from '../components';
import { claimResolvedLabelMap } from '../constants';
import { useChartMetric, useCubeFilter } from '../hooks';

export default function DenialsTrendChart() {
  const { chartMetric } = useChartMetric();
  const baseQuery = useCubeFilter('Claim', { hasGranularity: true });
  const query = {
    ...baseQuery,
    filters: [
      ...baseQuery.filters,
      {
        member: 'AdjudicatedClaims.denialStatus',
        operator: 'notEquals',
        values: ['unknown'],
      },
    ],
    dimensions: ['Claim.resolved', 'AdjudicatedClaims.denialStatus'],
    measures: [
      'Claim.count',
      'AdjudicatedClaims.totalExpected',
      'AdjudicatedClaims.deniedBenefits',
    ],
  } as const satisfies QueryWithTimeDimension;

  // XXX: open vs closed
  const barGroupOrder = ['false', 'true'];
  const titleSuffix = ' By Date of Service';
  return (
    <TrendChart<typeof query, typeof chartMetric>
      title={{
        count: `# Denied Claims ${titleSuffix}`,
        dollar: `$ Denied Benefits ${titleSuffix}`,
      }}
      chartMetric={chartMetric}
      query={query}
      trendLine={{
        label: '% Denial Rate',
        transformData: (rows) => {
          const denied = rows.filter((row) =>
            ['full', 'partial'].includes(row['AdjudicatedClaims.denialStatus']),
          );
          const denialRate =
            (100 *
              d3.sum(
                denied,
                (row) => row['AdjudicatedClaims.deniedBenefits'],
              )) /
            d3.sum(rows, (row) => row['AdjudicatedClaims.totalExpected']);
          return denialRate;
        },
      }}
      barConfig={{
        groupOrder: barGroupOrder,
        filterFns: Object.fromEntries(
          barGroupOrder.map((group) => [
            group,
            (row) =>
              ['full', 'partial'].includes(
                row['AdjudicatedClaims.denialStatus'],
              ) && row['Claim.resolved'] === group,
          ]),
        ),
        measureSpec: {
          count: 'Claim.count',
          dollar: 'AdjudicatedClaims.deniedBenefits',
        },
        labelMap: claimResolvedLabelMap,
      }}
    />
  );
}
